const Types = [
  { label: "Any", value: "Any" },
  { label: "MVA", value: "Mva" },
  { label: "Animal", value: "animal" },
  { label: "Medical Malpractice", value: "Medical Malpractice" },
  { label: "Premises Liability", value: "Premises Liability" },
  { label: "Product Liability", value: "Product Liability" },
  { label: "Personal Injury", value: "Personal Injury" },
  {
    label: "Wrongful Death",
    value: "Wrongful Death",
  },
];

const sortOptions = [
  // oldest and newset
  { label: "Newest", value: "Newest" },
  { label: "Oldest", value: "Oldest" },
];
const nuclearVerdicts = [
  { label: "Include", value: "Include" },
  // Excludes
  { label: "Exclude", value: "Exclude" },
];

const CountyOrientation = [
  { label: "Any", value: "Any" },
  { label: "Neutral", value: "Neutral" },
  { label: "Plaintiff", value: "Plaintiff" },
  { label: "Defense", value: "Defense" },
];

const Resolution = [
  { label: "Any", value: "Any" },
  { label: "Verdict", value: "Verdict" },
  { label: "Settlement", value: "Settlement" },
];

const Winner = [
  { label: "Any", value: "Any" },
  { label: "Plaintiff", value: "Plaintiff" },
  { label: "Defense", value: "Defense" },
];
const states = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "District of Columbia", value: "DC" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

// export const Counties = {
//   "New York": [
//     { label: "Albany", value: "Albany" },
//     { label: "Allegany", value: "Allegany" },
//     { label: "Bronx", value: "Bronx" },
//     { label: "Broome", value: "Broome" },
//     { label: "Cattaraugus", value: "Cattaraugus" },
//     { label: "Cayuga", value: "Cayuga" },
//     { label: "Chautauqua", value: "Chautauqua" },
//     { label: "Chemung", value: "Chemung" },
//     { label: "Chenango", value: "Chenango" },
//     { label: "Clinton", value: "Clinton" },
//     { label: "Columbia", value: "Columbia" },
//     { label: "Cortland", value: "Cortland" },
//     { label: "Delaware", value: "Delaware" },
//     { label: "Dutchess", value: "Dutchess" },
//     { label: "Erie", value: "Erie" },
//     { label: "Essex", value: "Essex" },
//     { label: "Franklin", value: "Franklin" },
//     { label: "Fulton", value: "Fulton" },
//     { label: "Genesee", value: "Genesee" },
//     { label: "Greene", value: "Greene" },
//     { label: "Hamilton", value: "Hamilton" },
//     { label: "Herkimer", value: "Herkimer" },
//     { label: "Jefferson", value: "Jefferson" },
//     { label: "Kings", value: "Kings" },
//     { label: "Lewis", value: "Lewis" },
//     { label: "Livingston", value: "Livingston" },
//     { label: "Madison", value: "Madison" },
//     { label: "Monroe", value: "Monroe" },
//     { label: "Montgomery", value: "Montgomery" },
//     { label: "Nassau", value: "Nassau" },
//     { label: "New York", value: "New York" },
//     { label: "Niagara", value: "Niagara" },
//     { label: "Oneida", value: "Oneida" },
//     { label: "Onondaga", value: "Onondaga" },
//     { label: "Ontario", value: "Ontario" },
//     { label: "Orange", value: "Orange" },
//     { label: "Orleans", value: "Orleans" },
//     { label: "Oswego", value: "Oswego" },
//     { label: "Otsego", value: "Otsego" },
//     { label: "Putnam", value: "Putnam" },
//     { label: "Queens", value: "Queens" },
//     { label: "Rensselaer", value: "Rensselaer" },
//     { label: "Richmond", value: "Richmond" },
//     { label: "Rockland", value: "Rockland" },
//     { label: "St. Lawrence", value: "St. Lawrence" },
//     { label: "Saratoga", value: "Saratoga" },
//     { label: "Schenectady", value: "Schenectady" },
//     { label: "Schoharie", value: "Schoharie" },
//     { label: "Schuyler", value: "Schuyler" },
//     { label: "Seneca", value: "Seneca" },
//     { label: "Steuben", value: "Steuben" },
//     { label: "Suffolk", value: "Suffolk" },
//     { label: "Sullivan", value: "Sullivan" },
//     { label: "Tioga", value: "Tioga" },
//     { label: "Tompkins", value: "Tompkins" },
//     { label: "Ulster", value: "Ulster" },
//     { label: "Warren", value: "Warren" },
//     { label: "Washington", value: "Washington" },
//     { label: "Wayne", value: "Wayne" },
//     { label: "Westchester", value: "Westchester" },
//     { label: "Wyoming", value: "Wyoming" },
//     { label: "Yates", value: "Yates" },
//   ],
//   California: [
//     { label: "Alameda", value: "Alameda" },
//     { label: "Alpine", value: "Alpine" },
//     { label: "Amador", value: "Amador" },
//     { label: "Butte", value: "Butte" },
//     { label: "Calaveras", value: "Calaveras" },
//     { label: "Colusa", value: "Colusa" },
//     { label: "Contra Costa", value: "Contra Costa" },
//     { label: "Del Norte", value: "Del Norte" },
//     { label: "El Dorado", value: "El Dorado" },
//     { label: "Fresno", value: "Fresno" },
//     { label: "Glenn", value: "Glenn" },
//     { label: "Humboldt", value: "Humboldt" },
//     { label: "Imperial", value: "Imperial" },
//     { label: "Inyo", value: "Inyo" },
//     { label: "Kern", value: "Kern" },
//     { label: "Kings", value: "Kings" },
//     { label: "Lake", value: "Lake" },
//     { label: "Lassen", value: "Lassen" },
//     { label: "Los Angeles", value: "Los Angeles" },
//     { label: "Madera", value: "Madera" },
//     { label: "Marin", value: "Marin" },
//     { label: "Mariposa", value: "Mariposa" },
//     { label: "Mendocino", value: "Mendocino" },
//     { label: "Merced", value: "Merced" },
//     { label: "Modoc", value: "Modoc" },
//     { label: "Mono", value: "Mono" },
//     { label: "Monterey", value: "Monterey" },
//     { label: "Napa", value: "Napa" },
//     { label: "Nevada", value: "Nevada" },
//     { label: "Orange", value: "Orange" },
//     { label: "Placer", value: "Placer" },
//     { label: "Plumas", value: "Plumas" },
//     { label: "Riverside", value: "Riverside" },
//     { label: "Sacramento", value: "Sacramento" },
//     { label: "San Benito", value: "San Benito" },
//     { label: "San Bernardino", value: "San Bernardino" },
//     { label: "San Diego", value: "San Diego" },
//     { label: "San Francisco", value: "San Francisco" },
//     { label: "San Joaquin", value: "San Joaquin" },
//     { label: "San Luis Obispo", value: "San Luis Obispo" },
//     { label: "San Mateo", value: "San Mateo" },
//     { label: "Santa Barbara", value: "Santa Barbara" },
//     { label: "Santa Clara", value: "Santa Clara" },
//     { label: "Santa Cruz", value: "Santa Cruz" },
//     { label: "Shasta", value: "Shasta" },
//     { label: "Sierra", value: "Sierra" },
//     { label: "Siskiyou", value: "Siskiyou" },
//     { label: "Solano", value: "Solano" },
//     { label: "Sonoma", value: "Sonoma" },
//     { label: "Stanislaus", value: "Stanislaus" },
//     { label: "Sutter", value: "Sutter" },
//     { label: "Tehama", value: "Tehama" },
//     { label: "Trinity", value: "Trinity" },
//     { label: "Tulare", value: "Tulare" },
//     { label: "Tuolumne", value: "Tuolumne" },
//     { label: "Ventura", value: "Ventura" },
//     { label: "Yolo", value: "Yolo" },
//     { label: "Yuba", value: "Yuba" },
//   ],
// };
const stateCountyData = {
  AL: {
    name: "AL",
    counties: [
      {
        name: "Alabama ",
        orientation: "Orientation",
        painRate: 159.73,
        indexation: 0.7986489198,
      },
      {
        name: "Autauga",
        orientation: "Defense ",
        painRate: 187.67,
        indexation: 0.9383720152,
      },
      {
        name: "Baldwin",
        orientation: "Neutral",
        painRate: 191.84,
        indexation: 0.9591866765,
      },
      {
        name: "Barbour",
        orientation: "Plaintiff ",
        painRate: 110.1,
        indexation: 0.5504782289,
      },
      {
        name: "Bibb",
        orientation: "Neutral",
        painRate: 145.3,
        indexation: 0.7264932112,
      },
      {
        name: "Blount",
        orientation: "Neutral",
        painRate: 162,
        indexation: 0.8100193967,
      },
      {
        name: "Bullock",
        orientation: "Plaintiff ",
        painRate: 95.77,
        indexation: 0.4788709785,
      },
      {
        name: "Butler",
        orientation: "Defense ",
        painRate: 111.97,
        indexation: 0.5598555281,
      },
      {
        name: "Calhoun",
        orientation: "Neutral",
        painRate: 141.19,
        indexation: 0.7059327135,
      },
      {
        name: "Chambers",
        orientation: "Neutral",
        painRate: 121.9,
        indexation: 0.6094976925,
      },
      {
        name: "Cherokee",
        orientation: "Neutral",
        painRate: 140.76,
        indexation: 0.7038057655,
      },
      {
        name: "Chilton",
        orientation: "Defense ",
        painRate: 165.4,
        indexation: 0.8270082269,
      },
      {
        name: "Choctaw",
        orientation: "Plaintiff ",
        painRate: 118.94,
        indexation: 0.5947160725,
      },
      {
        name: "Clarke",
        orientation: "Plaintiff ",
        painRate: 120.14,
        indexation: 0.6007089827,
      },
      {
        name: "Clay",
        orientation: "Defense ",
        painRate: 132.7,
        indexation: 0.6634873921,
      },
      {
        name: "Cleburne",
        orientation: "Neutral",
        painRate: 131.76,
        indexation: 0.658792054,
      },
      {
        name: "Coffee",
        orientation: "Plaintiff ",
        painRate: 167.02,
        indexation: 0.835114708,
      },
      {
        name: "Colbert",
        orientation: "Neutral",
        painRate: 146.06,
        indexation: 0.7302922881,
      },
      {
        name: "Conecuh",
        orientation: "Plaintiff ",
        painRate: 96.6,
        indexation: 0.4829911043,
      },
      {
        name: "Coosa",
        orientation: "Defense ",
        painRate: 132.39,
        indexation: 0.6619624105,
      },
      {
        name: "Covington",
        orientation: "Defense ",
        painRate: 125.63,
        indexation: 0.6281586516,
      },
      {
        name: "Crenshaw",
        orientation: "Neutral",
        painRate: 131.53,
        indexation: 0.6576282523,
      },
      {
        name: "Cullman",
        orientation: "Defense ",
        painRate: 165.27,
        indexation: 0.8263527523,
      },
      {
        name: "Dale",
        orientation: "Defense ",
        painRate: 135.29,
        indexation: 0.6764363588,
      },
      {
        name: "Dallas",
        orientation: "Plaintiff ",
        painRate: 94.33,
        indexation: 0.4716473814,
      },
      {
        name: "De Kalb",
        orientation: "Neutral",
        painRate: 133.87,
        indexation: 0.669346532,
      },
      {
        name: "Elmore",
        orientation: "Neutral",
        painRate: 191.19,
        indexation: 0.9559628119,
      },
      {
        name: "Escambia",
        orientation: "Neutral",
        painRate: 127.86,
        indexation: 0.639315096,
      },
      {
        name: "Etowah",
        orientation: "Neutral",
        painRate: 148.45,
        indexation: 0.7422513544,
      },
      {
        name: "Fayette",
        orientation: "Neutral",
        painRate: 131.64,
        indexation: 0.6582168417,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 132.73,
        indexation: 0.6636479165,
      },
      {
        name: "Geneva",
        orientation: "Neutral",
        painRate: 138.01,
        indexation: 0.690054177,
      },
      {
        name: "Greene",
        orientation: "Plaintiff ",
        painRate: 94.84,
        indexation: 0.4742023945,
      },
      {
        name: "Hale",
        orientation: "Plaintiff ",
        painRate: 119.6,
        indexation: 0.5979800682,
      },
      {
        name: "Henry",
        orientation: "Defense ",
        painRate: 151.7,
        indexation: 0.7585044479,
      },
      {
        name: "Houston",
        orientation: "Defense ",
        painRate: 152.08,
        indexation: 0.7603772323,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 139.03,
        indexation: 0.695137449,
      },
      {
        name: "Jefferson",
        orientation: "Plaintiff ",
        painRate: 165.86,
        indexation: 0.8293224533,
      },
      {
        name: "Lamar",
        orientation: "Neutral",
        painRate: 137.84,
        indexation: 0.689224801,
      },
      {
        name: "Lauderdale",
        orientation: "Defense ",
        painRate: 157.91,
        indexation: 0.7895525383,
      },
      {
        name: "Lawrence",
        orientation: "Defense ",
        painRate: 148.53,
        indexation: 0.7426392883,
      },
      {
        name: "Lee",
        orientation: "Defense ",
        painRate: 159.55,
        indexation: 0.7977660357,
      },
      {
        name: "Limestone",
        orientation: "Neutral",
        painRate: 222.48,
        indexation: 1.112420574,
      },
      {
        name: "Lowndes",
        orientation: "Plaintiff ",
        painRate: 113.08,
        indexation: 0.5654203732,
      },
      {
        name: "Macon",
        orientation: "Plaintiff ",
        painRate: 97.92,
        indexation: 0.4896127349,
      },
      {
        name: "Madison",
        orientation: "Defense ",
        painRate: 215.17,
        indexation: 1.075861146,
      },
      {
        name: "Marengo",
        orientation: "Plaintiff ",
        painRate: 121.96,
        indexation: 0.6098187412,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 127.73,
        indexation: 0.6386462444,
      },
      {
        name: "Marshall",
        orientation: "Neutral",
        painRate: 142.83,
        indexation: 0.714172965,
      },
      {
        name: "Mobile",
        orientation: "Plaintiff ",
        painRate: 145.31,
        indexation: 0.7265734733,
      },
      {
        name: "Monroe",
        orientation: "Neutral",
        painRate: 127.08,
        indexation: 0.6353822487,
      },
      {
        name: "Montgomery",
        orientation: "Plaintiff ",
        painRate: 152.13,
        indexation: 0.7606447729,
      },
      {
        name: "Morgan",
        orientation: "Neutral",
        painRate: 166.57,
        indexation: 0.8328539897,
      },
      {
        name: "Perry",
        orientation: "Plaintiff ",
        painRate: 89.85,
        indexation: 0.4492676075,
      },
      {
        name: "Pickens",
        orientation: "Neutral",
        painRate: 121.57,
        indexation: 0.6078523176,
      },
      {
        name: "Pike",
        orientation: "Neutral",
        painRate: 120.78,
        indexation: 0.6038927162,
      },
      {
        name: "Randolph",
        orientation: "Neutral",
        painRate: 132.25,
        indexation: 0.6612668049,
      },
      {
        name: "Russell",
        orientation: "Plaintiff ",
        painRate: 120.59,
        indexation: 0.602956324,
      },
      {
        name: "St. Clair",
        orientation: "Neutral",
        painRate: 188.24,
        indexation: 0.941221323,
      },
      {
        name: "Shelby",
        orientation: "Defense ",
        painRate: 262.89,
        indexation: 1.31446726,
      },
      {
        name: "Sumter",
        orientation: "Plaintiff ",
        painRate: 94.05,
        indexation: 0.4702294161,
      },
      {
        name: "Talladega",
        orientation: "Defense ",
        painRate: 147.64,
        indexation: 0.7382248679,
      },
      {
        name: "Tallapoosa",
        orientation: "Neutral",
        painRate: 147.87,
        indexation: 0.7393485386,
      },
      {
        name: "Tuscaloosa",
        orientation: "Neutral",
        painRate: 162.87,
        indexation: 0.8143268009,
      },
      {
        name: "Walker",
        orientation: "Neutral",
        painRate: 136.62,
        indexation: 0.6830981205,
      },
      {
        name: "Washington",
        orientation: "Plaintiff ",
        painRate: 143.9,
        indexation: 0.7194836466,
      },
      {
        name: "Wilcox",
        orientation: "Plaintiff ",
        painRate: 90.11,
        indexation: 0.4505651796,
      },
      {
        name: "Winston",
        orientation: "Neutral",
        painRate: 136.84,
        indexation: 0.6842217912,
      },
    ],
  },
  AK: {
    name: "AK",
    counties: [
      {
        name: "Alaska",
        orientation: "Orientation",
        painRate: 235.63,
        indexation: 1.17814193,
      },
      {
        name: "Aleutians East",
        orientation: "Plaintiff ",
        painRate: 229.69,
        indexation: 1.14844492,
      },
      {
        name: "Aleutians West",
        orientation: "Plaintiff ",
        painRate: 300.23,
        indexation: 1.501143736,
      },
      {
        name: "Anchorage",
        orientation: "Neutral",
        painRate: 265.3,
        indexation: 1.326493211,
      },
      {
        name: "Bethel",
        orientation: "Plaintiff ",
        painRate: 157.88,
        indexation: 0.7893920139,
      },
      {
        name: "Bristol Bay",
        orientation: "Plaintiff ",
        painRate: 257.86,
        indexation: 1.289305063,
      },
      {
        name: "Chugach Census Area",
        painRate: 244.41,
        indexation: 1.222072102,
      },
      {
        name: "Copper River Census Area",
        painRate: 170.79,
        indexation: 0.8539495686,
      },
      {
        name: "Denali",
        orientation: "Defense",
        painRate: 243.19,
        indexation: 1.215932045,
      },
      {
        name: "Dillingham",
        orientation: "Plaintiff ",
        painRate: 186.52,
        indexation: 0.9325931376,
      },
      {
        name: "Fairbanks North Star",
        orientation: "Defense",
        painRate: 225.18,
        indexation: 1.125891245,
      },
      {
        name: "Haines",
        orientation: "Neutral",
        painRate: 188.91,
        indexation: 0.9445522039,
      },
      {
        name: "Hoonah-Angoon Census Area",
        painRate: 171.71,
        indexation: 0.8585512675,
      },
      {
        name: "Juneau",
        orientation: "Neutral",
        painRate: 273.15,
        indexation: 1.365754799,
      },
      {
        name: "Kenai Peninsula",
        orientation: "Defense",
        painRate: 204.01,
        indexation: 1.02005217,
      },
      {
        name: "Ketchikan Gateway",
        orientation: "Neutral",
        painRate: 221.12,
        indexation: 1.105584911,
      },
      {
        name: "Kodiak Island",
        orientation: "Neutral",
        painRate: 222.65,
        indexation: 1.113263327,
      },
      {
        name: "Kusilvak Census Area",
        painRate: 111.9,
        indexation: 0.5594809712,
      },
      {
        name: "Lake And Peninsula",
        orientation: "Plaintiff ",
        painRate: 168.65,
        indexation: 0.8432613203,
      },
      {
        name: "Matanuska-Susitna Borough",
        orientation: "Defense",
        painRate: 230.92,
        indexation: 1.154611732,
      },
      {
        name: "Nome",
        orientation: "Plaintiff ",
        painRate: 172.51,
        indexation: 0.8625509999,
      },
      {
        name: "North Slope",
        orientation: "Plaintiff ",
        painRate: 256.29,
        indexation: 1.281452746,
      },
      {
        name: "North Slope Borough",
        orientation: "Plaintiff ",
        painRate: 256.29,
        indexation: 1.281452746,
      },
      {
        name: "Northwest Arctic",
        orientation: "Plaintiff ",
        painRate: 185.23,
        indexation: 0.9261454083,
      },
      {
        name: "Petersburg Borough",
        painRate: 205.05,
        indexation: 1.025229082,
      },
      {
        name: "Prince of Wales-Outer Ketchikan",
        orientation: "Neutral",
        painRate: 156.19,
        indexation: 0.780964484,
      },
      {
        name: "Sitka",
        orientation: "Neutral",
        painRate: 241.71,
        indexation: 1.208547923,
      },
      {
        name: "Skagway",
        orientation: "Neutral",
        painRate: 196.92,
        indexation: 0.9845896596,
      },
      {
        name: "Southeast Fairbanks",
        orientation: "Neutral",
        painRate: 199.94,
        indexation: 0.9996923283,
      },
      {
        name: "Wrangell",
        orientation: "Neutral",
        painRate: 181.29,
        indexation: 0.9064410407,
      },
      {
        name: "Yakutat",
        orientation: "Neutral",
        painRate: 210.16,
        indexation: 1.050792589,
      },
      {
        name: "Yukon-Koyukuk Census Area",
        painRate: 127.88,
        indexation: 0.6393953582,
      },
      {
        name: "Valdez-Cordova",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Wade Hampton",
        orientation: "Plaintiff ",
        painRate: "#N/A",
        indexation: "#N/A",
      },
    ],
  },
  AZ: {
    name: "AZ",
    counties: [
      {
        name: "Apache",
        orientation: "Defense",
        painRate: 108.71,
        indexation: 0.5435355495,
      },
      {
        name: "Cochise",
        orientation: "Defense",
        painRate: 147.83,
        indexation: 0.7391345061,
      },
      {
        name: "Coconino",
        orientation: "Neutral",
        painRate: 171.27,
        indexation: 0.8563440573,
      },
      {
        name: "Gila",
        orientation: "Defense",
        painRate: 138.93,
        indexation: 0.6946424988,
      },
      {
        name: "Graham",
        orientation: "Defense",
        painRate: 170.68,
        indexation: 0.8534144873,
      },
      {
        name: "Greenlee",
        orientation: "Defense",
        painRate: 204.24,
        indexation: 1.021175841,
      },
      {
        name: "La Paz",
        orientation: "Defense",
        painRate: 144.27,
        indexation: 0.721356431,
      },
      {
        name: "Maricopa",
        orientation: "Neutral",
        painRate: 223.85,
        indexation: 1.119229483,
      },
      {
        name: "Mohave",
        orientation: "Neutral",
        painRate: 146.72,
        indexation: 0.733623169,
      },
      {
        name: "Navajo",
        orientation: "Defense",
        painRate: 133.63,
        indexation: 0.6681559762,
      },
      {
        name: "Pima",
        orientation: "Plaintiff",
        painRate: 171.24,
        indexation: 0.856223664,
      },
      {
        name: "Pinal",
        orientation: "Neutral",
        painRate: 203.95,
        indexation: 1.019757876,
      },
      {
        name: "Santa Cruz",
        orientation: "Defense",
        painRate: 143.05,
        indexation: 0.7152297505,
      },
      {
        name: "Yavapai",
        orientation: "Neutral",
        painRate: 171.73,
        indexation: 0.8586315297,
      },
      {
        name: "Yuma",
        orientation: "Neutral",
        painRate: 145.03,
        indexation: 0.725155508,
      },
    ],
  },
  AR: {
    name: "AR",
    counties: [
      {
        name: "Arkansas",
        orientation: "Neutral",
        painRate: 139.51,
        indexation: 0.6975453147,
      },
      {
        name: "Ashley",
        orientation: "Neutral",
        painRate: 126.42,
        indexation: 0.6321048759,
      },
      {
        name: "Baxter",
        orientation: "Defense",
        painRate: 129.95,
        indexation: 0.6497625577,
      },
      {
        name: "Benton",
        orientation: "Defense",
        painRate: 231.09,
        indexation: 1.155427731,
      },
      {
        name: "Boone",
        orientation: "Defense",
        painRate: 141.86,
        indexation: 0.7093171025,
      },
      {
        name: "Bradley",
        orientation: "Neutral",
        painRate: 120.35,
        indexation: 0.6017256371,
      },
      {
        name: "Calhoun",
        orientation: "Defense",
        painRate: 140.55,
        indexation: 0.702762357,
      },
      {
        name: "Carroll",
        orientation: "Defense",
        painRate: 134.93,
        indexation: 0.6746438365,
      },
      {
        name: "Chicot",
        orientation: "Plaintiff",
        painRate: 102.25,
        indexation: 0.5112300181,
      },
      {
        name: "Clark",
        orientation: "Defense",
        painRate: 135.16,
        indexation: 0.6757942613,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 125.36,
        indexation: 0.6267808173,
      },
      {
        name: "Cleburne",
        orientation: "Defense",
        painRate: 140.89,
        indexation: 0.7044612401,
      },
      {
        name: "Cleveland",
        orientation: "Defense",
        painRate: 148.57,
        indexation: 0.7428666979,
      },
      {
        name: "Columbia",
        orientation: "Defense",
        painRate: 126.49,
        indexation: 0.6324393017,
      },
      {
        name: "Conway",
        orientation: "Neutral",
        painRate: 140.2,
        indexation: 0.7010233429,
      },
      {
        name: "Craighead",
        orientation: "Neutral",
        painRate: 162.08,
        indexation: 0.8103805766,
      },
      {
        name: "Crawford",
        orientation: "Defense",
        painRate: 146.45,
        indexation: 0.7322720888,
      },
      {
        name: "Crittenden",
        orientation: "Plaintiff",
        painRate: 132.82,
        indexation: 0.6640893586,
      },
      {
        name: "Cross",
        orientation: "Neutral",
        painRate: 129.22,
        indexation: 0.646097251,
      },
      {
        name: "Dallas",
        orientation: "Defense",
        painRate: 118.99,
        indexation: 0.5949568591,
      },
      {
        name: "Desha",
        orientation: "Plaintiff",
        painRate: 110.36,
        indexation: 0.551815932,
      },
      {
        name: "Drew",
        orientation: "Neutral",
        painRate: 130.12,
        indexation: 0.6505919336,
      },
      {
        name: "Faulkner",
        orientation: "Neutral",
        painRate: 169.7,
        indexation: 0.8484917397,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 129.08,
        indexation: 0.6454150224,
      },
      {
        name: "Fulton",
        orientation: "Defense",
        painRate: 113.94,
        indexation: 0.5697010233,
      },
      {
        name: "Garland",
        orientation: "Defense",
        painRate: 142.44,
        indexation: 0.7121797873,
      },
      {
        name: "Grant",
        orientation: "Defense",
        painRate: 169.92,
        indexation: 0.8496020333,
      },
      {
        name: "Greene",
        orientation: "Defense",
        painRate: 145.74,
        indexation: 0.7287137984,
      },
      {
        name: "Hempstead",
        orientation: "Neutral",
        painRate: 124.2,
        indexation: 0.6210019397,
      },
      {
        name: "Hot Spring",
        orientation: "Defense",
        painRate: 135.52,
        indexation: 0.6776001605,
      },
      {
        name: "Howard",
        orientation: "Defense",
        painRate: 124.27,
        indexation: 0.6213497425,
      },
      {
        name: "Independence",
        orientation: "Neutral",
        painRate: 154.13,
        indexation: 0.7706641696,
      },
      {
        name: "Izard",
        orientation: "Defense",
        painRate: 117.5,
        indexation: 0.5875192295,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 116.59,
        indexation: 0.5829576617,
      },
      {
        name: "Jefferson",
        orientation: "Plaintiff",
        painRate: 122.79,
        indexation: 0.6139254899,
      },
      {
        name: "Johnson",
        orientation: "Neutral",
        painRate: 124.83,
        indexation: 0.6241722962,
      },
      {
        name: "Lafayette",
        orientation: "Defense",
        painRate: 113.54,
        indexation: 0.5676810916,
      },
      {
        name: "Lawrence",
        orientation: "Neutral",
        painRate: 126.58,
        indexation: 0.6328941208,
      },
      {
        name: "Lee",
        orientation: "Plaintiff",
        painRate: 94.21,
        indexation: 0.471045415,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 129.25,
        indexation: 0.6462577754,
      },
      {
        name: "Little River",
        orientation: "Defense",
        painRate: 130.78,
        indexation: 0.6539094375,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 129.78,
        indexation: 0.6489198047,
      },
      {
        name: "Lonoke",
        orientation: "Neutral",
        painRate: 177,
        indexation: 0.8850244131,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 137.07,
        indexation: 0.6853454618,
      },
      {
        name: "Marion",
        orientation: "Defense",
        painRate: 131.93,
        indexation: 0.6596615611,
      },
      {
        name: "Miller",
        orientation: "Neutral",
        painRate: 130.16,
        indexation: 0.6508193432,
      },
      {
        name: "Mississippi",
        orientation: "Plaintiff",
        painRate: 120.84,
        indexation: 0.604213765,
      },
      {
        name: "Monroe",
        orientation: "Neutral",
        painRate: 88.53,
        indexation: 0.4426459769,
      },
      {
        name: "Montgomery",
        orientation: "Defense",
        painRate: 123.25,
        indexation: 0.6162664705,
      },
      {
        name: "Nevada",
        orientation: "Defense",
        painRate: 119.45,
        indexation: 0.5972710855,
      },
      {
        name: "Newton",
        orientation: "Defense",
        painRate: 128.41,
        indexation: 0.6420440104,
      },
      {
        name: "Ouachita",
        orientation: "Neutral",
        painRate: 131.83,
        indexation: 0.6591532339,
      },
      {
        name: "Perry",
        orientation: "Defense",
        painRate: 151.54,
        indexation: 0.7576750719,
      },
      {
        name: "Phillips",
        orientation: "Plaintiff",
        painRate: 96.58,
        indexation: 0.482884088,
      },
      {
        name: "Pike",
        orientation: "Defense",
        painRate: 110.62,
        indexation: 0.5531135041,
      },
      {
        name: "Poinsett",
        orientation: "Neutral",
        painRate: 126.74,
        indexation: 0.6336967427,
      },
      {
        name: "Polk",
        orientation: "Neutral",
        painRate: 121.17,
        indexation: 0.6058323858,
      },
      {
        name: "Pope",
        orientation: "Neutral",
        painRate: 154.86,
        indexation: 0.7742759682,
      },
      {
        name: "Prairie",
        orientation: "Neutral",
        painRate: 136.49,
        indexation: 0.682456023,
      },
      {
        name: "Pulaski",
        orientation: "Neutral",
        painRate: 152.65,
        indexation: 0.7632532941,
      },
      {
        name: "Randolph",
        orientation: "Defense",
        painRate: 122.1,
        indexation: 0.6105009698,
      },
      {
        name: "St. Francis",
        orientation: "Plaintiff",
        painRate: 101.83,
        indexation: 0.5091565782,
      },
      {
        name: "Saline",
        orientation: "Neutral",
        painRate: 188.16,
        indexation: 0.940806635,
      },
      {
        name: "Scott",
        orientation: "Defense",
        painRate: 113.97,
        indexation: 0.5698481707,
      },
      {
        name: "Searcy",
        orientation: "Defense",
        painRate: 101.85,
        indexation: 0.5092635944,
      },
      {
        name: "Sebastian",
        orientation: "Defense",
        painRate: 140.94,
        indexation: 0.7046886496,
      },
      {
        name: "Sevier",
        orientation: "Defense",
        painRate: 132.12,
        indexation: 0.6606113303,
      },
      {
        name: "Sharp",
        orientation: "Defense",
        painRate: 122.37,
        indexation: 0.6118654271,
      },
      {
        name: "Stone",
        orientation: "Defense",
        painRate: 109.86,
        indexation: 0.5493010501,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 134.46,
        indexation: 0.672302856,
      },
      {
        name: "Van Buren",
        orientation: "Defense",
        painRate: 126.15,
        indexation: 0.6307270417,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 166.14,
        indexation: 0.8307136646,
      },
      {
        name: "White",
        orientation: "Defense",
        painRate: 152.14,
        indexation: 0.7606982811,
      },
      {
        name: "Woodruff",
        orientation: "Neutral",
        painRate: 110.29,
        indexation: 0.5514681292,
      },
      {
        name: "Yell",
        orientation: "Neutral",
        painRate: 134.51,
        indexation: 0.6725302655,
      },
    ],
  },
  CA: {
    name: "CA",
    counties: [
      {
        name: "California ",
        orientation: "Orientation",
        painRate: 244.85,
        indexation: 1.224225804,
      },
      {
        name: "Alameda",
        orientation: "Plaintiff",
        painRate: 324.23,
        indexation: 1.621162464,
      },
      {
        name: "Alpine",
        orientation: "Defense",
        painRate: 191.68,
        indexation: 0.9584108086,
      },
      {
        name: "Amador",
        orientation: "Neutral",
        painRate: 198.93,
        indexation: 0.9946625644,
      },
      {
        name: "Butte",
        orientation: "Neutral",
        painRate: 169.5,
        indexation: 0.8474750853,
      },
      {
        name: "Calaveras",
        orientation: "Neutral",
        painRate: 195.77,
        indexation: 0.97886429,
      },
      {
        name: "Colusa",
        orientation: "Defense",
        painRate: 195.21,
        indexation: 0.9760283593,
      },
      {
        name: "Contra Costa",
        orientation: "Defense",
        painRate: 320.16,
        indexation: 1.600789245,
      },
      {
        name: "Del Norte",
        orientation: "Neutral",
        painRate: 153.29,
        indexation: 0.7664637817,
      },
      {
        name: "El Dorado",
        orientation: "Defense",
        painRate: 280.54,
        indexation: 1.40270216,
      },
      {
        name: "Fresno",
        orientation: "Neutral",
        painRate: 183.78,
        indexation: 0.9189084342,
      },
      {
        name: "Glenn",
        orientation: "Neutral",
        painRate: 169.21,
        indexation: 0.8460571199,
      },
      {
        name: "Humboldt",
        orientation: "Defense",
        painRate: 154.26,
        indexation: 0.7713196442,
      },
      {
        name: "Imperial",
        orientation: "Neutral",
        painRate: 148.55,
        indexation: 0.7427329276,
      },
      {
        name: "Inyo",
        orientation: "Neutral",
        painRate: 172.11,
        indexation: 0.8605711993,
      },
      {
        name: "Kern",
        orientation: "Neutral",
        painRate: 176.53,
        indexation: 0.8826299244,
      },
      {
        name: "Kings",
        orientation: "Neutral",
        painRate: 170.38,
        indexation: 0.8518895057,
      },
      {
        name: "Lake",
        orientation: "Neutral",
        painRate: 138.71,
        indexation: 0.6935723363,
      },
      {
        name: "Lassen",
        orientation: "Defense",
        painRate: 161.35,
        indexation: 0.8067420239,
      },
      {
        name: "Los Angeles",
        orientation: "Plaintiff",
        painRate: 220.6,
        indexation: 1.103003144,
      },
      {
        name: "Madera",
        orientation: "Neutral",
        painRate: 190.68,
        indexation: 0.9534211758,
      },
      {
        name: "Marin",
        orientation: "Plaintiff",
        painRate: 363.75,
        indexation: 1.818741221,
      },
      {
        name: "Mariposa",
        orientation: "Defense",
        painRate: 177.95,
        indexation: 0.8897465053,
      },
      {
        name: "Mendocino",
        orientation: "Defense",
        painRate: 170.21,
        indexation: 0.8510601298,
      },
      {
        name: "Merced",
        orientation: "Defense",
        painRate: 174.58,
        indexation: 0.8728914454,
      },
      {
        name: "Modoc",
        orientation: "Neutral",
        painRate: 136.89,
        indexation: 0.6844492007,
      },
      {
        name: "Mono",
        orientation: "Neutral",
        painRate: 211.64,
        indexation: 1.058190088,
      },
      {
        name: "Monterey",
        orientation: "Defense",
        painRate: 244.67,
        indexation: 1.223329543,
      },
      {
        name: "Napa",
        orientation: "Defense",
        painRate: 263.74,
        indexation: 1.318707779,
      },
      {
        name: "Nevada",
        orientation: "Neutral",
        painRate: 214.22,
        indexation: 1.071085546,
      },
      {
        name: "Orange",
        orientation: "Defense",
        painRate: 283.72,
        indexation: 1.418594074,
      },
      {
        name: "Placer",
        orientation: "Defense",
        painRate: 283.9,
        indexation: 1.419490335,
      },
      {
        name: "Plumas",
        orientation: "Neutral",
        painRate: 178.52,
        indexation: 0.892582436,
      },
      {
        name: "Riverside",
        orientation: "Plaintiff",
        painRate: 231.02,
        indexation: 1.155106682,
      },
      {
        name: "Sacramento",
        orientation: "Neutral",
        painRate: 224.69,
        indexation: 1.123470002,
      },
      {
        name: "San Benito",
        orientation: "Defense",
        painRate: 285.09,
        indexation: 1.425456491,
      },
      {
        name: "San Bernardino",
        orientation: "Neutral",
        painRate: 210.77,
        indexation: 1.053829175,
      },
      {
        name: "San Diego",
        orientation: "Neutral",
        painRate: 263.17,
        indexation: 1.315831717,
      },
      {
        name: "San Francisco",
        orientation: "Plaintiff",
        painRate: 362.16,
        indexation: 1.810795265,
      },
      {
        name: "San Joaquin",
        orientation: "Defense",
        painRate: 226.71,
        indexation: 1.133542907,
      },
      {
        name: "San Luis Obispo",
        orientation: "Defense",
        painRate: 236.98,
        indexation: 1.184910708,
      },
      {
        name: "San Mateo",
        orientation: "Defense",
        painRate: 384.71,
        indexation: 1.923550264,
      },
      {
        name: "Santa Barbara",
        orientation: "Defense",
        painRate: 239,
        indexation: 1.195023744,
      },
      {
        name: "Santa Clara",
        orientation: "Defense",
        painRate: 402.65,
        indexation: 2.013270015,
      },
      {
        name: "Santa Cruz",
        orientation: "Plaintiff",
        painRate: 265.55,
        indexation: 1.327750652,
      },
      {
        name: "Shasta",
        orientation: "Neutral",
        painRate: 177.41,
        indexation: 0.8870577219,
      },
      {
        name: "Sierra",
        orientation: "Neutral",
        painRate: 171.06,
        indexation: 0.8553140258,
      },
      {
        name: "Siskiyou",
        orientation: "Neutral",
        painRate: 138.03,
        indexation: 0.6901611932,
      },
      {
        name: "Solano",
        orientation: "Defense",
        painRate: 248.04,
        indexation: 1.24019798,
      },
      {
        name: "Sonoma",
        orientation: "Defense",
        painRate: 257.67,
        indexation: 1.288368671,
      },
      {
        name: "Stanislaus",
        orientation: "Defense",
        painRate: 201.32,
        indexation: 1.006608254,
      },
      {
        name: "Sutter",
        orientation: "Defense",
        painRate: 176.77,
        indexation: 0.8838338573,
      },
      {
        name: "Tehama",
        orientation: "Neutral",
        painRate: 157.58,
        indexation: 0.7879071634,
      },
      {
        name: "Trinity",
        orientation: "Neutral",
        painRate: 132.39,
        indexation: 0.6619624105,
      },
      {
        name: "Tulare",
        orientation: "Neutral",
        painRate: 172.06,
        indexation: 0.8603170356,
      },
      {
        name: "Tuolumne",
        orientation: "Neutral",
        painRate: 187.57,
        indexation: 0.937836934,
      },
      {
        name: "Ventura",
        orientation: "Defense",
        painRate: 272.84,
        indexation: 1.364203063,
      },
      {
        name: "Yolo",
        orientation: "Defense",
        painRate: 220.34,
        indexation: 1.101718949,
      },
      {
        name: "Yuba",
        orientation: "Neutral",
        painRate: 170.23,
        indexation: 0.8511270149,
      },
    ],
  },
  CO: {
    name: "CO",
    counties: [
      {
        name: "Colorado ",
        orientation: "Orientation",
        painRate: 238.37,
        indexation: 1.191840011,
      },
      {
        name: "Adams",
        orientation: "Neutral",
        painRate: 240.38,
        indexation: 1.201912916,
      },
      {
        name: "Alamosa",
        orientation: "Neutral",
        painRate: 144.32,
        indexation: 0.7215838405,
      },
      {
        name: "Arapahoe",
        orientation: "Defense",
        painRate: 250.22,
        indexation: 1.251086884,
      },
      {
        name: "Archuleta",
        orientation: "Defense",
        painRate: 176.64,
        indexation: 0.8832185138,
      },
      {
        name: "Baca",
        orientation: "Defense",
        painRate: 126.14,
        indexation: 0.6306869106,
      },
      {
        name: "Bent",
        orientation: "Defense",
        painRate: 114.93,
        indexation: 0.574650525,
      },
      {
        name: "Boulder",
        orientation: "Plaintiff",
        painRate: 258.4,
        indexation: 1.292007224,
      },
      {
        name: "Broomfield",
        orientation: "Defense",
        painRate: 306.99,
        indexation: 1.534960872,
      },
      {
        name: "Chaffee",
        orientation: "Defense",
        painRate: 201.06,
        indexation: 1.005297305,
      },
      {
        name: "Cheyenne",
        orientation: "Defense",
        painRate: 168.26,
        indexation: 0.8412948967,
      },
      {
        name: "Clear Creek",
        orientation: "Defense",
        painRate: 241.72,
        indexation: 1.208614808,
      },
      {
        name: "Conejos",
        orientation: "Defense",
        painRate: 131.47,
        indexation: 0.6573607117,
      },
      {
        name: "Costilla",
        orientation: "Defense",
        painRate: 98.91,
        indexation: 0.4945488596,
      },
      {
        name: "Crowley",
        orientation: "Defense",
        painRate: 133.2,
        indexation: 0.66597552,
      },
      {
        name: "Custer",
        orientation: "Defense",
        painRate: 181.3,
        indexation: 0.9065079259,
      },
      {
        name: "Delta",
        orientation: "Defense",
        painRate: 144.23,
        indexation: 0.7211557755,
      },
      {
        name: "Denver",
        orientation: "Plaintiff",
        painRate: 234.42,
        indexation: 1.172082135,
      },
      {
        name: "Dolores",
        orientation: "Defense",
        painRate: 151.68,
        indexation: 0.7584108086,
      },
      {
        name: "Douglas",
        orientation: "Neutral",
        painRate: 376.61,
        indexation: 1.883057989,
      },
      {
        name: "Eagle",
        orientation: "Defense",
        painRate: 283.09,
        indexation: 1.415463849,
      },
      {
        name: "Elbert",
        orientation: "Defense",
        painRate: 322.1,
        indexation: 1.610514347,
      },
      {
        name: "El Paso",
        orientation: "Defense",
        painRate: 220.05,
        indexation: 1.100234098,
      },
      {
        name: "Fremont",
        orientation: "Defense",
        painRate: 153.23,
        indexation: 0.766169487,
      },
      {
        name: "Garfield",
        orientation: "Defense",
        painRate: 231.6,
        indexation: 1.157996121,
      },
      {
        name: "Gilpin",
        orientation: "Defense",
        painRate: 240.26,
        indexation: 1.201324326,
      },
      {
        name: "Grand",
        orientation: "Defense",
        painRate: 235.59,
        indexation: 1.177968029,
      },
      {
        name: "Gunnison",
        orientation: "Defense",
        painRate: 237.72,
        indexation: 1.188616146,
      },
      {
        name: "Hinsdale",
        orientation: "Defense",
        painRate: 189.08,
        indexation: 0.9454217109,
      },
      {
        name: "Huerfano",
        orientation: "Neutral",
        painRate: 128.33,
        indexation: 0.6416694535,
      },
      {
        name: "Jackson",
        orientation: "Defense",
        painRate: 167.05,
        indexation: 0.8352484784,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 274,
        indexation: 1.369981941,
      },
      {
        name: "Kiowa",
        orientation: "Defense",
        painRate: 148.68,
        indexation: 0.7434017791,
      },
      {
        name: "Kit Carson",
        orientation: "Defense",
        painRate: 151.75,
        indexation: 0.7587452344,
      },
      {
        name: "Lake",
        orientation: "Defense",
        painRate: 190.83,
        indexation: 0.9541301585,
      },
      {
        name: "La Plata",
        orientation: "Defense",
        painRate: 212.31,
        indexation: 1.061574477,
      },
      {
        name: "Larimer",
        orientation: "Neutral",
        painRate: 236.34,
        indexation: 1.181700221,
      },
      {
        name: "Las Animas",
        orientation: "Defense",
        painRate: 131.35,
        indexation: 0.6567721223,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 145.39,
        indexation: 0.7269747843,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 157.78,
        indexation: 0.7889238178,
      },
      {
        name: "Mesa",
        orientation: "Defense",
        painRate: 186.15,
        indexation: 0.9307471072,
      },
      {
        name: "Mineral",
        orientation: "Defense",
        painRate: 186.73,
        indexation: 0.9336365461,
      },
      {
        name: "Moffat",
        orientation: "Defense",
        painRate: 184.84,
        indexation: 0.9241923617,
      },
      {
        name: "Montezuma",
        orientation: "Neutral",
        painRate: 156.1,
        indexation: 0.7804829108,
      },
      {
        name: "Montrose",
        orientation: "Defense",
        painRate: 175.87,
        indexation: 0.8793525517,
      },
      {
        name: "Morgan",
        orientation: "Defense",
        painRate: 175.5,
        indexation: 0.8775198983,
      },
      {
        name: "Otero",
        orientation: "Defense",
        painRate: 124.69,
        indexation: 0.6234633135,
      },
      {
        name: "Ouray",
        orientation: "Defense",
        painRate: 243.03,
        indexation: 1.215129423,
      },
      {
        name: "Park",
        orientation: "Defense",
        painRate: 235.49,
        indexation: 1.177446325,
      },
      {
        name: "Phillips",
        orientation: "Defense",
        painRate: 183.46,
        indexation: 0.9172764364,
      },
      {
        name: "Pitkin",
        orientation: "Plaintiff",
        painRate: 297.75,
        indexation: 1.488743228,
      },
      {
        name: "Prowers",
        orientation: "Defense",
        painRate: 130.65,
        indexation: 0.6532272089,
      },
      {
        name: "Pueblo",
        orientation: "Plaintiff",
        painRate: 156.01,
        indexation: 0.7800682229,
      },
      {
        name: "Rio Blanco",
        orientation: "Defense",
        painRate: 191.64,
        indexation: 0.9581967761,
      },
      {
        name: "Rio Grande",
        orientation: "Defense",
        painRate: 152.17,
        indexation: 0.7608454284,
      },
      {
        name: "Routt",
        orientation: "Defense",
        painRate: 279.33,
        indexation: 1.396655742,
      },
      {
        name: "Saguache",
        orientation: "Defense",
        painRate: 120.52,
        indexation: 0.6026085212,
      },
      {
        name: "San Juan",
        orientation: "Defense",
        painRate: 167.83,
        indexation: 0.8391679486,
      },
      {
        name: "San Miguel",
        orientation: "Defense",
        painRate: 252.52,
        indexation: 1.262617885,
      },
      {
        name: "Sedgwick",
        orientation: "Defense",
        painRate: 125.69,
        indexation: 0.6284529463,
      },
      {
        name: "Summit",
        orientation: "Defense",
        painRate: 259.47,
        indexation: 1.297358036,
      },
      {
        name: "Teller",
        orientation: "Defense",
        painRate: 217.95,
        indexation: 1.089773259,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 164.52,
        indexation: 0.8225804294,
      },
      {
        name: "Weld",
        orientation: "Neutral",
        painRate: 242.85,
        indexation: 1.214233162,
      },
      {
        name: "Yuma",
        orientation: "Defense",
        painRate: 150.02,
        indexation: 0.750117049,
      },
    ],
  },
  CT: {
    name: "CT",
    counties: [
      {
        name: "Connecticut ",
        orientation: "Orientation",
        painRate: 235.92,
        indexation: 1.179613404,
      },
      {
        name: "Bridgeport (Fairfield Judicial District)",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Danbury",
        orientation: "Defense",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Hartford",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Litchfield Judicial District (Torrington)",
        orientation: "Defense",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Middlesex",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "New Haven",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "New London",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Norwalk (Stamford/ Norwalk Judicial District)",
        orientation: "Neutral ",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Tolland",
        orientation: "Defense",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Windham",
        orientation: "Defense",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "New Britain Judicial District",
        orientation: "Neutral ",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Waterbury Judicial District",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Norwich ( New London Judicial District)",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Meriden (Middlesex Judicial District ",
        orientation: "Neutral ",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Ansonia/Milford Judicial District",
        orientation: "Neutral ",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Capitol Planning Region",
        painRate: 226.21,
        indexation: 1.131041402,
      },
      {
        name: "Greater Bridgeport Planning Region",
        painRate: 216.82,
        indexation: 1.084101398,
      },
      {
        name: "Lower Connecticut River Valley Planning Region",
        painRate: 266.36,
        indexation: 1.331777139,
      },
      {
        name: "Naugatuck Valley Planning Region",
        painRate: 218.8,
        indexation: 1.094013778,
      },
      {
        name: "Northeastern Connecticut Planning Region",
        painRate: 218.61,
        indexation: 1.093050632,
      },
      {
        name: "Northwest Hills Planning Region",
        painRate: 233.31,
        indexation: 1.166557421,
      },
      {
        name: "South Central Connecticut Planning Region",
        painRate: 226.38,
        indexation: 1.131897532,
      },
      {
        name: "Southeastern Connecticut Planning Region",
        painRate: 214.46,
        indexation: 1.072289479,
      },
      {
        name: "Western Connecticut Planning Region",
        painRate: 326.61,
        indexation: 1.633068022,
      },
    ],
  },
  DC: {
    name: "DC",
    counties: [
      {
        name: "District of Columbia ",
        orientation: "Orientation",
        painRate: 267.27,
        indexation: 1.336325329,
      },
      {
        name: "DC",
        orientation: "Neutral",
        painRate: 267.27,
        indexation: 1.336325329,
      },
    ],
  },
  DE: {
    name: "DE",
    counties: [
      {
        name: "Delaware ",
        orientation: "Orientation",
        painRate: 219.2,
        indexation: 1.096020333,
      },
      {
        name: "Kent",
        orientation: "Plaintiff",
        painRate: 192.27,
        indexation: 0.9613537556,
      },
      {
        name: "New Castle",
        orientation: "Defense",
        painRate: 228.55,
        indexation: 1.142759682,
      },
      {
        name: "Sussex",
        orientation: "Neutral",
        painRate: 217.21,
        indexation: 1.086054445,
      },
    ],
  },
  FL: {
    name: "FL",
    counties: [
      {
        name: "Florida ",
        orientation: "Orientation",
        painRate: 185.37,
        indexation: 0.926854391,
      },
      {
        name: "Alachua",
        orientation: "Neutral",
        painRate: 157.27,
        indexation: 0.7863420507,
      },
      {
        name: "Baker",
        orientation: "Neutral",
        painRate: 180.26,
        indexation: 0.9012775065,
      },
      {
        name: "Bay",
        orientation: "Defense",
        painRate: 175.3,
        indexation: 0.8764764899,
      },
      {
        name: "Bradford",
        orientation: "Defense",
        painRate: 154.27,
        indexation: 0.7713731523,
      },
      {
        name: "Brevard",
        orientation: "Plaintiff",
        painRate: 200.39,
        indexation: 1.001926293,
      },
      {
        name: "Broward",
        orientation: "Plaintiff",
        painRate: 189.51,
        indexation: 0.947548659,
      },
      {
        name: "Calhoun",
        orientation: "Defense",
        painRate: 125.12,
        indexation: 0.6255902615,
      },
      {
        name: "Charlotte",
        orientation: "Defense",
        painRate: 174.01,
        indexation: 0.8700421376,
      },
      {
        name: "Citrus",
        orientation: "Neutral",
        painRate: 140.55,
        indexation: 0.70274898,
      },
      {
        name: "Clay",
        orientation: "Neutral",
        painRate: 225.71,
        indexation: 1.128539897,
      },
      {
        name: "Collier",
        orientation: "Neutral",
        painRate: 218.9,
        indexation: 1.094522106,
      },
      {
        name: "Columbia",
        orientation: "Neutral",
        painRate: 144.63,
        indexation: 0.7231623303,
      },
      {
        name: "Dade",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "DeSoto",
        orientation: "Neutral",
        painRate: 126,
        indexation: 0.630018059,
      },
      {
        name: "Dixie",
        orientation: "Neutral",
        painRate: 108.59,
        indexation: 0.542933583,
      },
      {
        name: "Duval",
        orientation: "Plaintiff",
        painRate: 187.82,
        indexation: 0.939107752,
      },
      {
        name: "Escambia",
        orientation: "Neutral",
        painRate: 164.9,
        indexation: 0.8244799679,
      },
      {
        name: "Flagler",
        orientation: "Defense",
        painRate: 196.2,
        indexation: 0.9810179921,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 139.6,
        indexation: 0.6980135108,
      },
      {
        name: "Gadsden",
        orientation: "Plaintiff",
        painRate: 122.07,
        indexation: 0.6103671995,
      },
      {
        name: "Gilchrist",
        orientation: "Neutral",
        painRate: 155.65,
        indexation: 0.7782489466,
      },
      {
        name: "Glades",
        orientation: "Neutral",
        painRate: 135.27,
        indexation: 0.6763293425,
      },
      {
        name: "Gulf",
        orientation: "Defense",
        painRate: 149.58,
        indexation: 0.7478964618,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 121.69,
        indexation: 0.608440907,
      },
      {
        name: "Hardee",
        orientation: "Defense",
        painRate: 129.95,
        indexation: 0.6497491807,
      },
      {
        name: "Hendry",
        orientation: "Defense",
        painRate: 147.97,
        indexation: 0.7398568658,
      },
      {
        name: "Hernando",
        orientation: "Neutral",
        painRate: 162.08,
        indexation: 0.8104073306,
      },
      {
        name: "Highlands",
        orientation: "Neutral",
        painRate: 139,
        indexation: 0.6949903017,
      },
      {
        name: "Hillsborough",
        orientation: "Neutral",
        painRate: 198.22,
        indexation: 0.991117651,
      },
      {
        name: "Holmes",
        orientation: "Neutral",
        painRate: 134.09,
        indexation: 0.6704300716,
      },
      {
        name: "Indian River",
        orientation: "Neutral",
        painRate: 185.84,
        indexation: 0.9291953715,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 135.44,
        indexation: 0.6772122266,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 152.23,
        indexation: 0.7611531001,
      },
      {
        name: "Lafayette",
        orientation: "Neutral",
        painRate: 135.84,
        indexation: 0.6792187814,
      },
      {
        name: "Lake",
        orientation: "Defense",
        painRate: 180.96,
        indexation: 0.9047822888,
      },
      {
        name: "Lee",
        orientation: "Neutral",
        painRate: 191.58,
        indexation: 0.9578891044,
      },
      {
        name: "Leon",
        orientation: "Neutral",
        painRate: 164.7,
        indexation: 0.8234900676,
      },
      {
        name: "Levy",
        orientation: "Defense",
        painRate: 147.54,
        indexation: 0.7377031637,
      },
      {
        name: "Liberty",
        orientation: "Defense",
        painRate: 132.47,
        indexation: 0.6623503445,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 123.45,
        indexation: 0.6172296167,
      },
      {
        name: "Manatee",
        orientation: "Defense",
        painRate: 193.1,
        indexation: 0.9655006354,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 145.86,
        indexation: 0.7292756337,
      },
      {
        name: "Martin",
        orientation: "Plaintiff",
        painRate: 215.85,
        indexation: 1.079232158,
      },
      {
        name: "Miami-Dade",
        orientation: "Neutral",
        painRate: 179.12,
        indexation: 0.8956190221,
      },
      {
        name: "Monroe",
        orientation: "Plaintiff",
        painRate: 212.59,
        indexation: 1.062925557,
      },
      {
        name: "Nassau",
        orientation: "Plaintiff",
        painRate: 242.95,
        indexation: 1.214741489,
      },
      {
        name: "Okaloosa",
        orientation: "Neutral",
        painRate: 205.1,
        indexation: 1.025523376,
      },
      {
        name: "Okeechobee",
        orientation: "Neutral",
        painRate: 134.24,
        indexation: 0.6712059394,
      },
      {
        name: "Orange",
        orientation: "Plaintiff",
        painRate: 192.97,
        indexation: 0.9648719149,
      },
      {
        name: "Osceola",
        orientation: "Plaintiff",
        painRate: 170.64,
        indexation: 0.8532138319,
      },
      {
        name: "Palm Beach",
        orientation: "Plaintiff",
        painRate: 205.24,
        indexation: 1.026192228,
      },
      {
        name: "Pasco",
        orientation: "Defense",
        painRate: 176.4,
        indexation: 0.8819878269,
      },
      {
        name: "Pinellas",
        orientation: "Neutral",
        painRate: 177.72,
        indexation: 0.8885960805,
      },
      {
        name: "Polk",
        orientation: "Neutral",
        painRate: 165.72,
        indexation: 0.8285867166,
      },
      {
        name: "Putnam",
        orientation: "Neutral",
        painRate: 124.3,
        indexation: 0.6214835128,
      },
      {
        name: "St. Johns",
        orientation: "Neutral",
        painRate: 277.59,
        indexation: 1.387960672,
      },
      {
        name: "St. Lucie",
        orientation: "Neutral",
        painRate: 171.33,
        indexation: 0.8566383519,
      },
      {
        name: "Santa Rosa",
        orientation: "Neutral",
        painRate: 220.82,
        indexation: 1.10410006,
      },
      {
        name: "Sarasota",
        orientation: "Neutral",
        painRate: 209.51,
        indexation: 1.04754197,
      },
      {
        name: "Seminole",
        orientation: "Neutral",
        painRate: 214.82,
        indexation: 1.074122132,
      },
      {
        name: "Sumter",
        orientation: "Defense",
        painRate: 208.28,
        indexation: 1.04141529,
      },
      {
        name: "Suwannee",
        orientation: "Neutral",
        painRate: 137.23,
        indexation: 0.6861748378,
      },
      {
        name: "Taylor",
        orientation: "Neutral",
        painRate: 129.71,
        indexation: 0.6485452478,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 162.53,
        indexation: 0.8126412949,
      },
      {
        name: "Volusia",
        orientation: "Defense",
        painRate: 172.83,
        indexation: 0.8641696208,
      },
      {
        name: "Wakulla",
        orientation: "Defense",
        painRate: 169.84,
        indexation: 0.8492007224,
      },
      {
        name: "Walton",
        orientation: "Defense",
        painRate: 210.86,
        indexation: 1.054297371,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 131.72,
        indexation: 0.6585913986,
      },
    ],
  },
  GA: {
    name: "GA",
    counties: [
      {
        name: "Georgia ",
        orientation: "Orientation",
        painRate: 194.61,
        indexation: 0.9730720353,
      },
      {
        name: "Appling",
        orientation: "Neutral",
        painRate: 137.03,
        indexation: 0.6851314293,
      },
      {
        name: "Atkinson",
        orientation: "Neutral",
        painRate: 123.23,
        indexation: 0.6161460772,
      },
      {
        name: "Bacon",
        orientation: "Neutral",
        painRate: 123.19,
        indexation: 0.6159320447,
      },
      {
        name: "Baker",
        orientation: "Neutral",
        painRate: 134.12,
        indexation: 0.6705772189,
      },
      {
        name: "Baldwin",
        orientation: "Neutral",
        painRate: 133.46,
        indexation: 0.6672864691,
      },
      {
        name: "Banks",
        orientation: "Neutral",
        painRate: 179.06,
        indexation: 0.8952979734,
      },
      {
        name: "Barrow",
        orientation: "Plaintiff",
        painRate: 207.57,
        indexation: 1.037830245,
      },
      {
        name: "Bartow",
        orientation: "Plaintiff",
        painRate: 214.08,
        indexation: 1.07038994,
      },
      {
        name: "Ben Hill",
        orientation: "Neutral",
        painRate: 110.07,
        indexation: 0.5503444586,
      },
      {
        name: "Berrien",
        orientation: "Neutral",
        painRate: 130.36,
        indexation: 0.6517958665,
      },
      {
        name: "Bibb",
        orientation: "Plaintiff",
        painRate: 129.86,
        indexation: 0.6493077386,
      },
      {
        name: "Bleckley",
        orientation: "Neutral",
        painRate: 150.01,
        indexation: 0.7500501639,
      },
      {
        name: "Brantley",
        orientation: "Neutral",
        painRate: 134.76,
        indexation: 0.6738010835,
      },
      {
        name: "Brooks",
        orientation: "Neutral",
        painRate: 115.99,
        indexation: 0.5799344525,
      },
      {
        name: "Bryan",
        orientation: "Neutral",
        painRate: 248.97,
        indexation: 1.244826433,
      },
      {
        name: "Bulloch",
        orientation: "Plaintiff",
        painRate: 132.36,
        indexation: 0.6617751321,
      },
      {
        name: "Burke",
        orientation: "Neutral",
        painRate: 132.25,
        indexation: 0.6612400508,
      },
      {
        name: "Butts",
        orientation: "Neutral",
        painRate: 166.49,
        indexation: 0.8324660558,
      },
      {
        name: "Calhoun",
        orientation: "Neutral",
        painRate: 106.16,
        indexation: 0.5308139924,
      },
      {
        name: "Camden",
        orientation: "Plaintiff",
        painRate: 180.93,
        indexation: 0.9046618955,
      },
      {
        name: "Candler",
        orientation: "Neutral",
        painRate: 119.04,
        indexation: 0.5951842686,
      },
      {
        name: "Carroll",
        orientation: "Plaintiff",
        painRate: 187.08,
        indexation: 0.9353755602,
      },
      {
        name: "Catoosa",
        orientation: "Plaintiff",
        painRate: 179.03,
        indexation: 0.8951642031,
      },
      {
        name: "Charlton",
        orientation: "Neutral",
        painRate: 128.81,
        indexation: 0.6440639422,
      },
      {
        name: "Chatham",
        orientation: "Plaintiff",
        painRate: 171.3,
        indexation: 0.8565179587,
      },
      {
        name: "Chattahoochee",
        orientation: "Plaintiff",
        painRate: 153.85,
        indexation: 0.7692729583,
      },
      {
        name: "Chattooga",
        orientation: "Neutral",
        painRate: 135.68,
        indexation: 0.6784027824,
      },
      {
        name: "Cherokee",
        orientation: "Defense",
        painRate: 267.36,
        indexation: 1.336793526,
      },
      {
        name: "Clarke",
        orientation: "Plaintiff",
        painRate: 133.32,
        indexation: 0.6666042405,
      },
      {
        name: "Clay",
        orientation: "Neutral",
        painRate: 101.56,
        indexation: 0.507805498,
      },
      {
        name: "Clayton",
        orientation: "Plaintiff",
        painRate: 154.17,
        indexation: 0.7708514481,
      },
      {
        name: "Clinch",
        orientation: "Neutral",
        painRate: 121.99,
        indexation: 0.6099257575,
      },
      {
        name: "Cobb",
        orientation: "Plaintiff",
        painRate: 258.97,
        indexation: 1.294869908,
      },
      {
        name: "Coffee",
        orientation: "Neutral",
        painRate: 130.54,
        indexation: 0.6526921276,
      },
      {
        name: "Colquitt",
        orientation: "Neutral",
        painRate: 131.67,
        indexation: 0.658337235,
      },
      {
        name: "Columbia",
        orientation: "Neutral",
        painRate: 252.09,
        indexation: 1.260437429,
      },
      {
        name: "Cook",
        orientation: "Neutral",
        painRate: 118.99,
        indexation: 0.5949568591,
      },
      {
        name: "Coweta",
        orientation: "Plaintiff",
        painRate: 234.41,
        indexation: 1.172068758,
      },
      {
        name: "Crawford",
        orientation: "Neutral",
        painRate: 161.31,
        indexation: 0.8065681225,
      },
      {
        name: "Crisp",
        orientation: "Defense",
        painRate: 117.41,
        indexation: 0.5870376563,
      },
      {
        name: "Dade",
        orientation: "Plaintiff",
        painRate: 159.74,
        indexation: 0.798715805,
      },
      {
        name: "Dawson",
        orientation: "Defense",
        painRate: 226.21,
        indexation: 1.131041402,
      },
      {
        name: "De Kalb",
        orientation: "Plaintiff",
        painRate: 124.29,
        indexation: 0.6214300047,
      },
      {
        name: "Decatur",
        orientation: "Plaintiff",
        painRate: 205.3,
        indexation: 1.0264999,
      },
      {
        name: "Dodge",
        orientation: "Plaintiff",
        painRate: 117.14,
        indexation: 0.5856865761,
      },
      {
        name: "Dooly",
        orientation: "Plaintiff",
        painRate: 123.58,
        indexation: 0.6178984683,
      },
      {
        name: "Dougherty",
        orientation: "Plaintiff",
        painRate: 114.05,
        indexation: 0.5702494816,
      },
      {
        name: "Douglas",
        orientation: "Plaintiff",
        painRate: 212,
        indexation: 1.060022741,
      },
      {
        name: "Early",
        orientation: "Neutral",
        painRate: 116.16,
        indexation: 0.5807905826,
      },
      {
        name: "Echols",
        orientation: "Neutral",
        painRate: 126.27,
        indexation: 0.6313290081,
      },
      {
        name: "Effingham",
        orientation: "Plaintiff",
        painRate: 235.73,
        indexation: 1.178663635,
      },
      {
        name: "Elbert",
        orientation: "Neutral",
        painRate: 131.68,
        indexation: 0.6584174972,
      },
      {
        name: "Emanuel",
        orientation: "Neutral",
        painRate: 125.68,
        indexation: 0.6283860611,
      },
      {
        name: "Evans",
        orientation: "Neutral",
        painRate: 120.83,
        indexation: 0.6041468798,
      },
      {
        name: "Fannin",
        orientation: "Defense",
        painRate: 162.14,
        indexation: 0.8107016253,
      },
      {
        name: "Fayette",
        orientation: "Plaintiff",
        painRate: 292.58,
        indexation: 1.462885426,
      },
      {
        name: "Floyd",
        orientation: "Plaintiff",
        painRate: 151.56,
        indexation: 0.7578222192,
      },
      {
        name: "Forsyth",
        orientation: "Neutral",
        painRate: 350.23,
        indexation: 1.751173835,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 133.61,
        indexation: 0.6680489599,
      },
      {
        name: "Fulton",
        orientation: "Plaintiff",
        painRate: 240.25,
        indexation: 1.201230687,
      },
      {
        name: "Gilmer",
        orientation: "Plaintiff",
        painRate: 182.71,
        indexation: 0.9135709986,
      },
      {
        name: "Glascock",
        orientation: "Neutral",
        painRate: 148.59,
        indexation: 0.7429469601,
      },
      {
        name: "Glynn",
        orientation: "Plaintiff",
        painRate: 169.97,
        indexation: 0.8498695739,
      },
      {
        name: "Gordon",
        orientation: "Plaintiff",
        painRate: 171.08,
        indexation: 0.855407665,
      },
      {
        name: "Grady",
        orientation: "Plaintiff",
        painRate: 129.89,
        indexation: 0.6494281319,
      },
      {
        name: "Greene",
        orientation: "Defense",
        painRate: 199.29,
        indexation: 0.9964283326,
      },
      {
        name: "Gwinnett",
        orientation: "Plaintiff",
        painRate: 224.51,
        indexation: 1.122573741,
      },
      {
        name: "Habersham",
        orientation: "Plaintiff",
        painRate: 167.02,
        indexation: 0.835101331,
      },
      {
        name: "Hall",
        orientation: "Plaintiff",
        painRate: 205.44,
        indexation: 1.027208882,
      },
      {
        name: "Hancock",
        orientation: "Neutral",
        painRate: 103.19,
        indexation: 0.5159521102,
      },
      {
        name: "Haralson",
        orientation: "Plaintiff",
        painRate: 154.94,
        indexation: 0.7746906561,
      },
      {
        name: "Harris",
        orientation: "Plaintiff",
        painRate: 242.49,
        indexation: 1.212440639,
      },
      {
        name: "Hart",
        orientation: "Defense",
        painRate: 150.19,
        indexation: 0.750946425,
      },
      {
        name: "Heard",
        orientation: "Neutral",
        painRate: 151.76,
        indexation: 0.7587987426,
      },
      {
        name: "Henry",
        orientation: "Plaintiff",
        painRate: 220.4,
        indexation: 1.101986489,
      },
      {
        name: "Houston",
        orientation: "Plaintiff",
        painRate: 190.59,
        indexation: 0.9529396027,
      },
      {
        name: "Irwin",
        orientation: "Neutral",
        painRate: 131.69,
        indexation: 0.6584710053,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 219.75,
        indexation: 1.098762625,
      },
      {
        name: "Jasper",
        orientation: "Neutral",
        painRate: 170.47,
        indexation: 0.8523577018,
      },
      {
        name: "Jeff Davis",
        orientation: "Neutral",
        painRate: 123.45,
        indexation: 0.6172563708,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 118.95,
        indexation: 0.5947294495,
      },
      {
        name: "Jenkins",
        orientation: "Neutral",
        painRate: 111.91,
        indexation: 0.5595612334,
      },
      {
        name: "Johnson",
        orientation: "Neutral",
        painRate: 113.98,
        indexation: 0.5699016788,
      },
      {
        name: "Jones",
        orientation: "Plaintiff",
        painRate: 175.28,
        indexation: 0.8763962277,
      },
      {
        name: "Lamar",
        orientation: "Neutral",
        painRate: 164.65,
        indexation: 0.823249281,
      },
      {
        name: "Lanier",
        orientation: "Neutral",
        painRate: 135.57,
        indexation: 0.6778275701,
      },
      {
        name: "Laurens",
        orientation: "Plaintiff",
        painRate: 128.2,
        indexation: 0.641000602,
      },
      {
        name: "Lee",
        orientation: "Defense",
        painRate: 225.43,
        indexation: 1.127148686,
      },
      {
        name: "Liberty",
        orientation: "Plaintiff",
        painRate: 160.01,
        indexation: 0.8000401311,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 186.68,
        indexation: 0.9333957595,
      },
      {
        name: "Long",
        orientation: "Neutral",
        painRate: 190.72,
        indexation: 0.9535817002,
      },
      {
        name: "Lowndes",
        orientation: "Plaintiff",
        painRate: 140.79,
        indexation: 0.7039395358,
      },
      {
        name: "Lumpkin",
        orientation: "Neutral",
        painRate: 191.25,
        indexation: 0.9562704836,
      },
      {
        name: "Macon",
        orientation: "Neutral",
        painRate: 135.36,
        indexation: 0.6767841616,
      },
      {
        name: "Madison",
        orientation: "Neutral",
        painRate: 137.55,
        indexation: 0.6877265735,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 107.25,
        indexation: 0.5362718213,
      },
      {
        name: "McDuffie",
        orientation: "Neutral",
        painRate: 157.28,
        indexation: 0.7864223129,
      },
      {
        name: "McIntosh",
        orientation: "Plaintiff",
        painRate: 126.21,
        indexation: 0.6310480904,
      },
      {
        name: "Meriwether",
        orientation: "Plaintiff",
        painRate: 141.85,
        indexation: 0.7092368403,
      },
      {
        name: "Miller",
        orientation: "Neutral",
        painRate: 120.93,
        indexation: 0.604655207,
      },
      {
        name: "Mitchell",
        orientation: "Neutral",
        painRate: 121.19,
        indexation: 0.605926025,
      },
      {
        name: "Monroe",
        orientation: "Defense",
        painRate: 204.48,
        indexation: 1.022379774,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 129.37,
        indexation: 0.6468597418,
      },
      {
        name: "Morgan",
        orientation: "Neutral",
        painRate: 198.89,
        indexation: 0.9944485319,
      },
      {
        name: "Murray",
        orientation: "Neutral",
        painRate: 166.17,
        indexation: 0.8308340579,
      },
      {
        name: "Muscogee",
        orientation: "Plaintiff",
        painRate: 143.78,
        indexation: 0.7188816802,
      },
      {
        name: "Newton",
        orientation: "Plaintiff",
        painRate: 192.97,
        indexation: 0.9648585379,
      },
      {
        name: "Oconee",
        orientation: "Defense",
        painRate: 307.02,
        indexation: 1.535121397,
      },
      {
        name: "Oglethorpe",
        orientation: "Neutral",
        painRate: 167.22,
        indexation: 0.8361179854,
      },
      {
        name: "Paulding",
        orientation: "Neutral",
        painRate: 233.99,
        indexation: 1.16994181,
      },
      {
        name: "Peach",
        orientation: "Plaintiff",
        painRate: 156.43,
        indexation: 0.7821684168,
      },
      {
        name: "Pickens",
        orientation: "Neutral",
        painRate: 192.51,
        indexation: 0.9625443114,
      },
      {
        name: "Pierce",
        orientation: "Neutral",
        painRate: 140.98,
        indexation: 0.7049026821,
      },
      {
        name: "Pike",
        orientation: "Plaintiff",
        painRate: 215.71,
        indexation: 1.078536553,
      },
      {
        name: "Polk",
        orientation: "Plaintiff",
        painRate: 146.86,
        indexation: 0.7343187747,
      },
      {
        name: "Pulaski",
        orientation: "Neutral",
        painRate: 125.68,
        indexation: 0.6283860611,
      },
      {
        name: "Putnam",
        orientation: "Neutral",
        painRate: 166.52,
        indexation: 0.8325998261,
      },
      {
        name: "Quitman",
        orientation: "Neutral",
        painRate: 111.07,
        indexation: 0.5553608454,
      },
      {
        name: "Rabun",
        orientation: "Plaintiff",
        painRate: 169.59,
        indexation: 0.8479299044,
      },
      {
        name: "Randolph",
        orientation: "Neutral",
        painRate: 104.84,
        indexation: 0.5242191158,
      },
      {
        name: "Richmond",
        orientation: "Plaintiff",
        painRate: 133.98,
        indexation: 0.6699083673,
      },
      {
        name: "Rockdale",
        orientation: "Plaintiff",
        painRate: 192.59,
        indexation: 0.9629322453,
      },
      {
        name: "Schley",
        orientation: "Neutral",
        painRate: 153.86,
        indexation: 0.7693130894,
      },
      {
        name: "Screven",
        orientation: "Neutral",
        painRate: 141.03,
        indexation: 0.7051434687,
      },
      {
        name: "Seminole",
        orientation: "Neutral",
        painRate: 120.99,
        indexation: 0.6049495017,
      },
      {
        name: "Spalding",
        orientation: "Plaintiff",
        painRate: 147.38,
        indexation: 0.7368871647,
      },
      {
        name: "Stephens",
        orientation: "Defense",
        painRate: 139.07,
        indexation: 0.6953381045,
      },
      {
        name: "Stewart",
        orientation: "Neutral",
        painRate: 110.11,
        indexation: 0.5505718681,
      },
      {
        name: "Sumter",
        orientation: "Neutral",
        painRate: 119.53,
        indexation: 0.5976456424,
      },
      {
        name: "Talbot",
        orientation: "Neutral",
        painRate: 120.83,
        indexation: 0.6041468798,
      },
      {
        name: "Taliaferro",
        orientation: "Neutral",
        painRate: 112.74,
        indexation: 0.5636813591,
      },
      {
        name: "Tattnall",
        orientation: "Neutral",
        painRate: 126.82,
        indexation: 0.6340846766,
      },
      {
        name: "Taylor",
        orientation: "Neutral",
        painRate: 117.81,
        indexation: 0.5890575881,
      },
      {
        name: "Telfair",
        orientation: "Neutral",
        painRate: 111.16,
        indexation: 0.5557755334,
      },
      {
        name: "Terrell",
        orientation: "Neutral",
        painRate: 142.55,
        indexation: 0.7127416226,
      },
      {
        name: "Thomas",
        orientation: "Plaintiff",
        painRate: 139.75,
        indexation: 0.6987626246,
      },
      {
        name: "Tift",
        orientation: "Plaintiff",
        painRate: 141.69,
        indexation: 0.7084743495,
      },
      {
        name: "Toombs",
        orientation: "Neutral",
        painRate: 120.73,
        indexation: 0.6036385526,
      },
      {
        name: "Towns",
        orientation: "Neutral",
        painRate: 158.58,
        indexation: 0.7928967962,
      },
      {
        name: "Treutlen",
        orientation: "Neutral",
        painRate: 116.47,
        indexation: 0.5823423182,
      },
      {
        name: "Troup",
        orientation: "Plaintiff",
        painRate: 149.43,
        indexation: 0.747133971,
      },
      {
        name: "Turner",
        orientation: "Neutral",
        painRate: 109.33,
        indexation: 0.5466523978,
      },
      {
        name: "Twiggs",
        orientation: "Plaintiff",
        painRate: 130.48,
        indexation: 0.652424587,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 172.8,
        indexation: 0.8639957193,
      },
      {
        name: "Upson",
        orientation: "Neutral",
        painRate: 128.58,
        indexation: 0.6429135175,
      },
      {
        name: "Walker",
        orientation: "Neutral",
        painRate: 142.89,
        indexation: 0.7144271286,
      },
      {
        name: "Walton",
        orientation: "Defense",
        painRate: 222.16,
        indexation: 1.110788576,
      },
      {
        name: "Ware",
        orientation: "Neutral",
        painRate: 129.07,
        indexation: 0.6453748913,
      },
      {
        name: "Warren",
        orientation: "Neutral",
        painRate: 118.7,
        indexation: 0.5934987626,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 129.66,
        indexation: 0.6482777072,
      },
      {
        name: "Wayne",
        orientation: "Neutral",
        painRate: 136.72,
        indexation: 0.6835930707,
      },
      {
        name: "Webster",
        orientation: "Defense",
        painRate: 123.03,
        indexation: 0.6151695539,
      },
      {
        name: "Wheeler",
        orientation: "Neutral",
        painRate: 96.19,
        indexation: 0.4809310414,
      },
      {
        name: "White",
        orientation: "Neutral",
        painRate: 166.14,
        indexation: 0.8307136646,
      },
      {
        name: "Whitfield",
        orientation: "Plaintiff",
        painRate: 165.02,
        indexation: 0.8250953114,
      },
      {
        name: "Wilcox",
        orientation: "Neutral",
        painRate: 120.14,
        indexation: 0.6006956056,
      },
      {
        name: "Wilkes",
        orientation: "Neutral",
        painRate: 126.75,
        indexation: 0.6337636279,
      },
      {
        name: "Wilkinson",
        orientation: "Neutral",
        painRate: 132.83,
        indexation: 0.6641428667,
      },
      {
        name: "Worth",
        orientation: "Neutral",
        painRate: 130.98,
        indexation: 0.6548859608,
      },
    ],
  },
  HI: {
    name: "HI",
    counties: [
      {
        name: "Hawaii",
        orientation: "Orientation",
        painRate: 250.64,
        indexation: 1.253200455,
      },
      {
        name: "Hilo",
        orientation: "Plaintiff",
        painRate: 190.34,
        indexation: 0.9516821617,
      },
      {
        name: "Honolulu",
        orientation: "Neutral",
        painRate: 257.65,
        indexation: 1.288261655,
      },
      {
        name: "Lana'i",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Maui",
        orientation: "Plaintiff",
        painRate: 240.24,
        indexation: 1.201177179,
      },
      {
        name: "Molokai",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Kalawao County",
        painRate: "#VALUE!",
        indexation: "#VALUE!",
      },
      {
        name: "Kauaâi",
        orientation: "Plaintiff",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Kahului",
        orientation: "Plaintiff",
        painRate: 219.22,
        indexation: 1.096100595,
      },
      {
        name: "Oahu ",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
    ],
  },
  IA: {
    name: "IA",
    counties: [
      {
        name: "Iowa",
        orientation: "Orientation",
        painRate: 186.5,
        indexation: 0.9324861213,
      },
      {
        name: "Adair",
        orientation: "Defense",
        painRate: 179.24,
        indexation: 0.8962209886,
      },
      {
        name: "Adams",
        orientation: "Defense",
        painRate: 157.78,
        indexation: 0.7888836867,
      },
      {
        name: "Allamakee",
        orientation: "Defense",
        painRate: 162.51,
        indexation: 0.8125610327,
      },
      {
        name: "Appanoose",
        orientation: "Neutral",
        painRate: 143.37,
        indexation: 0.7168617484,
      },
      {
        name: "Audubon",
        orientation: "Defense",
        painRate: 161.87,
        indexation: 0.8093639221,
      },
      {
        name: "Benton",
        orientation: "Neutral",
        painRate: 221.42,
        indexation: 1.107109892,
      },
      {
        name: "Black Hawk",
        orientation: "Plaintiff",
        painRate: 165.93,
        indexation: 0.8296435021,
      },
      {
        name: "Boone",
        orientation: "Defense",
        painRate: 200.38,
        indexation: 1.001886161,
      },
      {
        name: "Bremer",
        orientation: "Neutral",
        painRate: 224.19,
        indexation: 1.12095512,
      },
      {
        name: "Buchanan",
        orientation: "Neutral",
        painRate: 188.61,
        indexation: 0.9430539763,
      },
      {
        name: "Buena Vista",
        orientation: "Defense",
        painRate: 180.73,
        indexation: 0.9036452411,
      },
      {
        name: "Butler",
        orientation: "Neutral",
        painRate: 179.88,
        indexation: 0.899377968,
      },
      {
        name: "Calhoun",
        orientation: "Defense",
        painRate: 172.92,
        indexation: 0.8646110628,
      },
      {
        name: "Carroll",
        orientation: "Defense",
        painRate: 197.02,
        indexation: 0.9851247408,
      },
      {
        name: "Cass",
        orientation: "Defense",
        painRate: 163.75,
        indexation: 0.8187278443,
      },
      {
        name: "Cedar",
        orientation: "Neutral",
        painRate: 191.46,
        indexation: 0.957300515,
      },
      {
        name: "Cerro Gordo",
        orientation: "Defense",
        painRate: 169.3,
        indexation: 0.8464851849,
      },
      {
        name: "Cherokee",
        orientation: "Defense",
        painRate: 176.54,
        indexation: 0.8826834325,
      },
      {
        name: "Chickasaw",
        orientation: "Defense",
        painRate: 198.19,
        indexation: 0.9909303726,
      },
      {
        name: "Clarke",
        orientation: "Defense",
        painRate: 174.25,
        indexation: 0.8712326935,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 183.38,
        indexation: 0.9168885024,
      },
      {
        name: "Clayton",
        orientation: "Neutral",
        painRate: 172.53,
        indexation: 0.8626312621,
      },
      {
        name: "Clinton",
        orientation: "Neutral",
        painRate: 163.56,
        indexation: 0.8178182061,
      },
      {
        name: "Crawford",
        orientation: "Defense",
        painRate: 180.22,
        indexation: 0.9011169821,
      },
      {
        name: "Dallas",
        orientation: "Defense",
        painRate: 291.99,
        indexation: 1.459942479,
      },
      {
        name: "Davis",
        orientation: "Neutral",
        painRate: 174.44,
        indexation: 0.8721958397,
      },
      {
        name: "Decatur",
        orientation: "Defense",
        painRate: 138.45,
        indexation: 0.6922480102,
      },
      {
        name: "Delaware",
        orientation: "Neutral",
        painRate: 198.03,
        indexation: 0.9901277507,
      },
      {
        name: "Des Moines",
        orientation: "Neutral",
        painRate: 159.69,
        indexation: 0.7984482643,
      },
      {
        name: "Dickinson",
        orientation: "Defense",
        painRate: 201.74,
        indexation: 1.008708448,
      },
      {
        name: "Dubuque",
        orientation: "Neutral",
        painRate: 196.62,
        indexation: 0.983104809,
      },
      {
        name: "Emmet",
        orientation: "Defense",
        painRate: 152.05,
        indexation: 0.760270216,
      },
      {
        name: "Fayette",
        orientation: "Neutral",
        painRate: 151.43,
        indexation: 0.7571266136,
      },
      {
        name: "Floyd",
        orientation: "Defense",
        painRate: 172.71,
        indexation: 0.8635275232,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 170.62,
        indexation: 0.8531201926,
      },
      {
        name: "Fremont",
        orientation: "Defense",
        painRate: 184.65,
        indexation: 0.9232559695,
      },
      {
        name: "Greene",
        orientation: "Defense",
        painRate: 170.05,
        indexation: 0.8502708849,
      },
      {
        name: "Grundy",
        orientation: "Neutral",
        painRate: 212.39,
        indexation: 1.061949034,
      },
      {
        name: "Guthrie",
        orientation: "Defense",
        painRate: 194.5,
        indexation: 0.972523577,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 184.05,
        indexation: 0.9202728914,
      },
      {
        name: "Hancock",
        orientation: "Defense",
        painRate: 180.58,
        indexation: 0.9029228814,
      },
      {
        name: "Hardin",
        orientation: "Defense",
        painRate: 159.51,
        indexation: 0.7975653802,
      },
      {
        name: "Harrison",
        orientation: "Defense",
        painRate: 196.53,
        indexation: 0.9826366129,
      },
      {
        name: "Henry",
        orientation: "Neutral",
        painRate: 170.43,
        indexation: 0.8521436693,
      },
      {
        name: "Howard",
        orientation: "Defense",
        painRate: 179.2,
        indexation: 0.895980202,
      },
      {
        name: "Humboldt",
        orientation: "Defense",
        painRate: 191.35,
        indexation: 0.9567386797,
      },
      {
        name: "Ida",
        orientation: "Defense",
        painRate: 181.11,
        indexation: 0.9055715337,
      },
      {
        name: "Iowa",
        orientation: "Neutral",
        painRate: 195.17,
        indexation: 0.9758277038,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 173.34,
        indexation: 0.8667246338,
      },
      {
        name: "Jasper",
        orientation: "Neutral",
        painRate: 184.73,
        indexation: 0.9236439034,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 159.46,
        indexation: 0.7973112166,
      },
      {
        name: "Johnson",
        orientation: "Plaintiff",
        painRate: 200.41,
        indexation: 1.002060063,
      },
      {
        name: "Jones",
        orientation: "Neutral",
        painRate: 189.07,
        indexation: 0.9453682028,
      },
      {
        name: "Keokuk",
        orientation: "Neutral",
        painRate: 175.65,
        indexation: 0.878242258,
      },
      {
        name: "Kossuth",
        orientation: "Defense",
        painRate: 175.27,
        indexation: 0.8763293425,
      },
      {
        name: "Lee",
        orientation: "Neutral",
        painRate: 157.89,
        indexation: 0.7894722761,
      },
      {
        name: "Linn",
        orientation: "Plaintiff",
        painRate: 198.34,
        indexation: 0.9916928634,
      },
      {
        name: "Louisa",
        orientation: "Neutral",
        painRate: 189.83,
        indexation: 0.9491405257,
      },
      {
        name: "Lucas",
        orientation: "Neutral",
        painRate: 161.18,
        indexation: 0.805899271,
      },
      {
        name: "Lyon",
        orientation: "Defense",
        painRate: 198.14,
        indexation: 0.99071634,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 216.41,
        indexation: 1.082027958,
      },
      {
        name: "Mahaska",
        orientation: "Neutral",
        painRate: 183.96,
        indexation: 0.9197913183,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 196.51,
        indexation: 0.9825563507,
      },
      {
        name: "Marshall",
        orientation: "Defense",
        painRate: 186.79,
        indexation: 0.9339575948,
      },
      {
        name: "Mills",
        orientation: "Defense",
        painRate: 202.89,
        indexation: 1.014447194,
      },
      {
        name: "Mitchell",
        orientation: "Defense",
        painRate: 174.25,
        indexation: 0.8712728246,
      },
      {
        name: "Monona",
        orientation: "Defense",
        painRate: 161.16,
        indexation: 0.8057922547,
      },
      {
        name: "Monroe",
        orientation: "Neutral",
        painRate: 177.05,
        indexation: 0.8852384456,
      },
      {
        name: "Montgomery",
        orientation: "Defense",
        painRate: 157.57,
        indexation: 0.7878402782,
      },
      {
        name: "Muscatine",
        orientation: "Neutral",
        painRate: 184.86,
        indexation: 0.924299378,
      },
      {
        name: "O'Brien",
        orientation: "Defense",
        painRate: 180.51,
        indexation: 0.9025483245,
      },
      {
        name: "Osceola",
        orientation: "Defense",
        painRate: 173.88,
        indexation: 0.8694134172,
      },
      {
        name: "Page",
        orientation: "Defense",
        painRate: 162.04,
        indexation: 0.8102066751,
      },
      {
        name: "Palo Alto",
        orientation: "Defense",
        painRate: 175.07,
        indexation: 0.8753661962,
      },
      {
        name: "Plymouth",
        orientation: "Defense",
        painRate: 212.51,
        indexation: 1.062551,
      },
      {
        name: "Pocahontas",
        orientation: "Defense",
        painRate: 165.68,
        indexation: 0.8283994382,
      },
      {
        name: "Polk",
        orientation: "Plaintiff",
        painRate: 206.99,
        indexation: 1.034967561,
      },
      {
        name: "Pottawattamie",
        orientation: "Defense",
        painRate: 183.83,
        indexation: 0.9191625978,
      },
      {
        name: "Poweshiek",
        orientation: "Neutral",
        painRate: 186.12,
        indexation: 0.9305865828,
      },
      {
        name: "Ringgold",
        orientation: "Defense",
        painRate: 160.69,
        indexation: 0.8034378971,
      },
      {
        name: "Sac",
        orientation: "Defense",
        painRate: 193.96,
        indexation: 0.9698214166,
      },
      {
        name: "Scott",
        orientation: "Plaintiff",
        painRate: 183.47,
        indexation: 0.9173433215,
      },
      {
        name: "Shelby",
        orientation: "Defense",
        painRate: 191.63,
        indexation: 0.9581700221,
      },
      {
        name: "Sioux",
        orientation: "Defense",
        painRate: 203.31,
        indexation: 1.016574142,
      },
      {
        name: "Story",
        orientation: "Plaintiff",
        painRate: 183.85,
        indexation: 0.9192696141,
      },
      {
        name: "Tama",
        orientation: "Neutral",
        painRate: 177.37,
        indexation: 0.8868303124,
      },
      {
        name: "Taylor",
        orientation: "Defense",
        painRate: 149.88,
        indexation: 0.7493946893,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 149.32,
        indexation: 0.7465988897,
      },
      {
        name: "Van Buren",
        orientation: "Neutral",
        painRate: 172.7,
        indexation: 0.8634873921,
      },
      {
        name: "Wapello",
        orientation: "Neutral",
        painRate: 164.68,
        indexation: 0.8234231824,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 243.31,
        indexation: 1.216547388,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 188.72,
        indexation: 0.9436024346,
      },
      {
        name: "Wayne",
        orientation: "Neutral",
        painRate: 155.17,
        indexation: 0.7758678349,
      },
      {
        name: "Webster",
        orientation: "Defense",
        painRate: 179.55,
        indexation: 0.8977325931,
      },
      {
        name: "Winnebago",
        orientation: "Defense",
        painRate: 178.65,
        indexation: 0.8932646646,
      },
      {
        name: "Winneshiek",
        orientation: "Neutral",
        painRate: 192.71,
        indexation: 0.9635743429,
      },
      {
        name: "Woodbury",
        orientation: "Defense",
        painRate: 178.8,
        indexation: 0.8939870243,
      },
      {
        name: "Worth",
        orientation: "Defense",
        painRate: 190.83,
        indexation: 0.9541702896,
      },
      {
        name: "Wright",
        orientation: "Defense",
        painRate: 180.03,
        indexation: 0.9001538359,
      },
    ],
  },
  ID: {
    name: "ID",
    counties: [
      {
        name: "Idaho ",
        orientation: "Orientation",
        painRate: 194.33,
        indexation: 0.9716273159,
      },
      {
        name: "Ada",
        orientation: "Neutral",
        painRate: 234.76,
        indexation: 1.173807772,
      },
      {
        name: "Adams",
        orientation: "Defense",
        painRate: 150.82,
        indexation: 0.7541167815,
      },
      {
        name: "Bannock",
        orientation: "Neutral",
        painRate: 166.2,
        indexation: 0.8309812053,
      },
      {
        name: "Bear Lake",
        orientation: "Defense",
        painRate: 176.59,
        indexation: 0.8829643502,
      },
      {
        name: "Benewah",
        orientation: "Defense",
        painRate: 150.33,
        indexation: 0.7516286536,
      },
      {
        name: "Bingham",
        orientation: "Defense",
        painRate: 187.42,
        indexation: 0.9370878202,
      },
      {
        name: "Blaine",
        orientation: "Plaintiff",
        painRate: 264.08,
        indexation: 1.320406662,
      },
      {
        name: "Boise",
        orientation: "Defense",
        painRate: 198.3,
        indexation: 0.9915189619,
      },
      {
        name: "Bonner",
        orientation: "Neutral",
        painRate: 173.53,
        indexation: 0.867634272,
      },
      {
        name: "Bonneville",
        orientation: "Neutral",
        painRate: 199.33,
        indexation: 0.9966557421,
      },
      {
        name: "Boundary",
        orientation: "Defense",
        painRate: 168.02,
        indexation: 0.8400909638,
      },
      {
        name: "Butte",
        orientation: "Defense",
        painRate: 152.73,
        indexation: 0.763627851,
      },
      {
        name: "Camas",
        orientation: "Defense",
        painRate: 196.14,
        indexation: 0.9806835663,
      },
      {
        name: "Canyon",
        orientation: "Defense",
        painRate: 188.87,
        indexation: 0.9443381714,
      },
      {
        name: "Caribou",
        orientation: "Defense",
        painRate: 188.23,
        indexation: 0.9411276838,
      },
      {
        name: "Cassia",
        orientation: "Defense",
        painRate: 182.38,
        indexation: 0.9119122467,
      },
      {
        name: "Clark",
        orientation: "Defense",
        painRate: 142.78,
        indexation: 0.7138786703,
      },
      {
        name: "Clearwater",
        orientation: "Defense",
        painRate: 152.71,
        indexation: 0.7635743429,
      },
      {
        name: "Custer",
        orientation: "Defense",
        painRate: 153.58,
        indexation: 0.7679085011,
      },
      {
        name: "Elmore",
        orientation: "Defense",
        painRate: 168.57,
        indexation: 0.8428733864,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 191.74,
        indexation: 0.9587184804,
      },
      {
        name: "Fremont",
        orientation: "Defense",
        painRate: 163.51,
        indexation: 0.8175372885,
      },
      {
        name: "Gem",
        orientation: "Defense",
        painRate: 162.59,
        indexation: 0.8129355896,
      },
      {
        name: "Gooding",
        orientation: "Neutral",
        painRate: 160.09,
        indexation: 0.8004548191,
      },
      {
        name: "Idaho",
        orientation: "Neutral",
        painRate: 162.95,
        indexation: 0.8147548659,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 204.6,
        indexation: 1.022995117,
      },
      {
        name: "Jerome",
        orientation: "Neutral",
        painRate: 164.56,
        indexation: 0.8228078389,
      },
      {
        name: "Kootenai",
        orientation: "Neutral",
        painRate: 202.22,
        indexation: 1.011076182,
      },
      {
        name: "Latah",
        orientation: "Plaintiff",
        painRate: 160.81,
        indexation: 0.8040666176,
      },
      {
        name: "Lemhi",
        orientation: "Defense",
        painRate: 148.63,
        indexation: 0.7431476155,
      },
      {
        name: "Lewis",
        orientation: "Defense",
        painRate: 147.49,
        indexation: 0.7374490001,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 164.96,
        indexation: 0.8248010167,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 139.72,
        indexation: 0.6986021002,
      },
      {
        name: "Minidoka",
        orientation: "Defense",
        painRate: 162.76,
        indexation: 0.8137783426,
      },
      {
        name: "Nez Perce",
        orientation: "Neutral",
        painRate: 175.39,
        indexation: 0.87697144,
      },
      {
        name: "Oneida",
        orientation: "Defense",
        painRate: 177.05,
        indexation: 0.8852250686,
      },
      {
        name: "Owyhee",
        orientation: "Defense",
        painRate: 152.88,
        indexation: 0.7643903418,
      },
      {
        name: "Payette",
        orientation: "Defense",
        painRate: 165.14,
        indexation: 0.8256972778,
      },
      {
        name: "Power",
        orientation: "Defense",
        painRate: 153.64,
        indexation: 0.7681894188,
      },
      {
        name: "Shoshone",
        orientation: "Neutral",
        painRate: 125.76,
        indexation: 0.6287873721,
      },
      {
        name: "Teton",
        orientation: "Neutral",
        painRate: 255.72,
        indexation: 1.278576684,
      },
      {
        name: "Twin Falls",
        orientation: "Plaintiff",
        painRate: 170.85,
        indexation: 0.8542572403,
      },
      {
        name: "Valley",
        orientation: "Defense",
        painRate: 198.42,
        indexation: 0.9921209284,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 147.34,
        indexation: 0.7366998863,
      },
    ],
  },
  IL: {
    name: "IL",
    counties: [
      {
        name: "Illinois",
        orientation: "Orientation",
        painRate: 205.32,
        indexation: 1.026606916,
      },
      {
        name: "Adams",
        orientation: "Defense",
        painRate: 165.2,
        indexation: 0.8259915725,
      },
      {
        name: "Alexander",
        orientation: "Defense",
        painRate: 110.7,
        indexation: 0.5535148151,
      },
      {
        name: "Bond",
        orientation: "Defense",
        painRate: 176.77,
        indexation: 0.8838338573,
      },
      {
        name: "Boone",
        orientation: "Defense",
        painRate: 223.92,
        indexation: 1.119590663,
      },
      {
        name: "Brown",
        orientation: "Defense",
        painRate: 208.9,
        indexation: 1.044492007,
      },
      {
        name: "Bureau",
        orientation: "Defense",
        painRate: 168.66,
        indexation: 0.8433148284,
      },
      {
        name: "Calhoun",
        orientation: "Defense",
        painRate: 183.33,
        indexation: 0.9166610929,
      },
      {
        name: "Carroll",
        orientation: "Defense",
        painRate: 171.65,
        indexation: 0.8582302187,
      },
      {
        name: "Cass",
        orientation: "Defense",
        painRate: 166.22,
        indexation: 0.8311015986,
      },
      {
        name: "Champaign",
        orientation: "Neutral",
        painRate: 163.75,
        indexation: 0.8187412213,
      },
      {
        name: "Christian",
        orientation: "Defense",
        painRate: 152.2,
        indexation: 0.7609925757,
      },
      {
        name: "Clark",
        orientation: "Defense",
        painRate: 170.3,
        indexation: 0.8515149488,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 147.9,
        indexation: 0.7395090629,
      },
      {
        name: "Clinton",
        orientation: "Defense",
        painRate: 212.99,
        indexation: 1.064972243,
      },
      {
        name: "Coles",
        orientation: "Defense",
        painRate: 154.4,
        indexation: 0.7720152498,
      },
      {
        name: "Cook",
        orientation: "Plaintiff",
        painRate: 204.97,
        indexation: 1.024867902,
      },
      {
        name: "Crawford",
        orientation: "Defense",
        painRate: 177.47,
        indexation: 0.8873386396,
      },
      {
        name: "Cumberland",
        orientation: "Defense",
        painRate: 211.73,
        indexation: 1.058658284,
      },
      {
        name: "DeKalb",
        orientation: "Defense",
        painRate: 192.31,
        indexation: 0.9615677881,
      },
      {
        name: "De Witt",
        orientation: "Defense",
        painRate: 171.93,
        indexation: 0.859634807,
      },
      {
        name: "Douglas",
        orientation: "Defense",
        painRate: 181.38,
        indexation: 0.9069092368,
      },
      {
        name: "DuPage",
        orientation: "Defense",
        painRate: 273.92,
        indexation: 1.36958063,
      },
      {
        name: "Edgar",
        orientation: "Defense",
        painRate: 169.7,
        indexation: 0.8485051167,
      },
      {
        name: "Edwards",
        orientation: "Defense",
        painRate: 156.21,
        indexation: 0.7810447462,
      },
      {
        name: "Effingham",
        orientation: "Defense",
        painRate: 200.12,
        indexation: 1.000601966,
      },
      {
        name: "Fayette",
        orientation: "Defense",
        painRate: 154.45,
        indexation: 0.7722426594,
      },
      {
        name: "Ford",
        orientation: "Defense",
        painRate: 179.61,
        indexation: 0.8980670189,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 131.38,
        indexation: 0.6569192696,
      },
      {
        name: "Fulton",
        orientation: "Defense",
        painRate: 153.47,
        indexation: 0.7673332887,
      },
      {
        name: "Gallatin",
        orientation: "Defense",
        painRate: 137.43,
        indexation: 0.6871379841,
      },
      {
        name: "Greene",
        orientation: "Defense",
        painRate: 155.7,
        indexation: 0.7784897331,
      },
      {
        name: "Grundy",
        orientation: "Defense",
        painRate: 251.17,
        indexation: 1.255862484,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 152.46,
        indexation: 0.7622767708,
      },
      {
        name: "Hancock",
        orientation: "Defense",
        painRate: 169.42,
        indexation: 0.8470871514,
      },
      {
        name: "Hardin",
        orientation: "Defense",
        painRate: 124.59,
        indexation: 0.6229416093,
      },
      {
        name: "Henderson",
        orientation: "Defense",
        painRate: 165.59,
        indexation: 0.8279312421,
      },
      {
        name: "Henry",
        orientation: "Defense",
        painRate: 188.66,
        indexation: 0.9433081399,
      },
      {
        name: "Iroquois",
        orientation: "Defense",
        painRate: 174.39,
        indexation: 0.8719282991,
      },
      {
        name: "Jackson",
        orientation: "Defense",
        painRate: 119.74,
        indexation: 0.5987024279,
      },
      {
        name: "Jasper",
        orientation: "Defense",
        painRate: 177.35,
        indexation: 0.8867634272,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 156.84,
        indexation: 0.7842017256,
      },
      {
        name: "Jersey",
        orientation: "Defense",
        painRate: 193.6,
        indexation: 0.9680021403,
      },
      {
        name: "Jo Daviess",
        orientation: "Defense",
        painRate: 179.83,
        indexation: 0.8991639355,
      },
      {
        name: "Johnson",
        orientation: "Defense",
        painRate: 159.97,
        indexation: 0.7998662297,
      },
      {
        name: "Kane",
        orientation: "Neutral",
        painRate: 250.08,
        indexation: 1.250418032,
      },
      {
        name: "Kankakee",
        orientation: "Defense",
        painRate: 188.6,
        indexation: 0.9430004682,
      },
      {
        name: "Kendall",
        orientation: "Defense",
        painRate: 274.25,
        indexation: 1.371266136,
      },
      {
        name: "Knox",
        orientation: "Defense",
        painRate: 154.28,
        indexation: 0.7713999064,
      },
      {
        name: "La Salle",
        orientation: "Defense",
        painRate: 271.88,
        indexation: 1.359387332,
      },
      {
        name: "Lake",
        orientation: "Neutral",
        painRate: 173.13,
        indexation: 0.8656410942,
      },
      {
        name: "Lawrence",
        orientation: "Defense",
        painRate: 153.73,
        indexation: 0.7686442378,
      },
      {
        name: "Lee",
        orientation: "Defense",
        painRate: 180.24,
        indexation: 0.9012239984,
      },
      {
        name: "Livingston",
        orientation: "Defense",
        painRate: 199.1,
        indexation: 0.9954919403,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 143.87,
        indexation: 0.7193498763,
      },
      {
        name: "McDonough",
        orientation: "Defense",
        painRate: 141.29,
        indexation: 0.7064677948,
      },
      {
        name: "McHenry",
        orientation: "Defense",
        painRate: 265.21,
        indexation: 1.326038392,
      },
      {
        name: "McLean",
        orientation: "Neutral",
        painRate: 192.16,
        indexation: 0.9608052973,
      },
      {
        name: "Macon",
        orientation: "Defense",
        painRate: 162.26,
        indexation: 0.8112902147,
      },
      {
        name: "Macoupin",
        orientation: "Defense",
        painRate: 186.96,
        indexation: 0.9347869708,
      },
      {
        name: "Madison",
        orientation: "Plaintiff",
        painRate: 180.43,
        indexation: 0.9021603906,
      },
      {
        name: "Marion",
        orientation: "Defense",
        painRate: 156.48,
        indexation: 0.7824092034,
      },
      {
        name: "Marshall",
        orientation: "Defense",
        painRate: 186.19,
        indexation: 0.9309477627,
      },
      {
        name: "Mason",
        orientation: "Defense",
        painRate: 162.62,
        indexation: 0.813109491,
      },
      {
        name: "Massac",
        orientation: "Defense",
        painRate: 141.48,
        indexation: 0.7073774329,
      },
      {
        name: "Menard",
        orientation: "Defense",
        painRate: 208.99,
        indexation: 1.044946826,
      },
      {
        name: "Mercer",
        orientation: "Defense",
        painRate: 188.43,
        indexation: 0.9421443382,
      },
      {
        name: "Monroe",
        orientation: "Plaintiff",
        painRate: 268.84,
        indexation: 1.344217778,
      },
      {
        name: "Montgomery",
        orientation: "Defense",
        painRate: 150.84,
        indexation: 0.7542104207,
      },
      {
        name: "Morgan",
        orientation: "Defense",
        painRate: 173.48,
        indexation: 0.8673934854,
      },
      {
        name: "Moultrie",
        orientation: "Defense",
        painRate: 202.01,
        indexation: 1.010032774,
      },
      {
        name: "Ogle",
        orientation: "Defense",
        painRate: 210.23,
        indexation: 1.051127015,
      },
      {
        name: "Peoria",
        orientation: "Neutral",
        painRate: 175.03,
        indexation: 0.8751387867,
      },
      {
        name: "Perry",
        orientation: "Defense",
        painRate: 141.93,
        indexation: 0.7096649054,
      },
      {
        name: "Piatt",
        orientation: "Defense",
        painRate: 228.56,
        indexation: 1.142799813,
      },
      {
        name: "Pike",
        orientation: "Defense",
        painRate: 145.12,
        indexation: 0.7256237041,
      },
      {
        name: "Pope",
        orientation: "Defense",
        painRate: 146.82,
        indexation: 0.7341047422,
      },
      {
        name: "Pulaski",
        orientation: "Defense",
        painRate: 120.05,
        indexation: 0.6002675406,
      },
      {
        name: "Putnam",
        orientation: "Defense",
        painRate: 222.14,
        indexation: 1.11068156,
      },
      {
        name: "Randolph",
        orientation: "Defense",
        painRate: 167.41,
        indexation: 0.8370677547,
      },
      {
        name: "Richland",
        orientation: "Defense",
        painRate: 152.05,
        indexation: 0.7602300849,
      },
      {
        name: "Rock Island",
        orientation: "Defense",
        painRate: 167.62,
        indexation: 0.8380977861,
      },
      {
        name: "Saline",
        orientation: "Defense",
        painRate: 179.75,
        indexation: 0.8987358705,
      },
      {
        name: "Sangamon",
        orientation: "Neutral",
        painRate: 136.39,
        indexation: 0.6819476958,
      },
      {
        name: "Schuyler",
        orientation: "Defense",
        painRate: 197.13,
        indexation: 0.9856731991,
      },
      {
        name: "Scott",
        orientation: "Defense",
        painRate: 168.46,
        indexation: 0.8423249281,
      },
      {
        name: "Shelby",
        orientation: "Defense",
        painRate: 173.51,
        indexation: 0.8675406327,
      },
      {
        name: "St. Clair",
        orientation: "Plaintiff",
        painRate: 174.96,
        indexation: 0.8748043609,
      },
      {
        name: "Stark",
        orientation: "Defense",
        painRate: 158.48,
        indexation: 0.792375092,
      },
      {
        name: "Stephenson",
        orientation: "Defense",
        painRate: 163.17,
        indexation: 0.8158384055,
      },
      {
        name: "Tazewell",
        orientation: "Defense",
        painRate: 196.07,
        indexation: 0.9803357635,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 136.76,
        indexation: 0.6837803491,
      },
      {
        name: "Vermilion",
        orientation: "Defense",
        painRate: 139.34,
        indexation: 0.6967159387,
      },
      {
        name: "Wabash",
        orientation: "Defense",
        painRate: 170.63,
        indexation: 0.8531469467,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 153.45,
        indexation: 0.7672664036,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 192.52,
        indexation: 0.9625844425,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 142.76,
        indexation: 0.7137850311,
      },
      {
        name: "White",
        orientation: "Defense",
        painRate: 146.11,
        indexation: 0.7305330747,
      },
      {
        name: "Whiteside",
        orientation: "Defense",
        painRate: 165.98,
        indexation: 0.8298842887,
      },
      {
        name: "Will",
        orientation: "Neutral",
        painRate: 259.72,
        indexation: 1.298588723,
      },
      {
        name: "Williamson",
        orientation: "Defense",
        painRate: 171.63,
        indexation: 0.8581633336,
      },
      {
        name: "Winnebago",
        orientation: "Defense",
        painRate: 167.37,
        indexation: 0.8368269681,
      },
      {
        name: "Woodford",
        orientation: "Defense",
        painRate: 229.8,
        indexation: 1.148980001,
      },
    ],
  },
  IN: {
    name: "IN",
    counties: [
      {
        name: "Indiana ",
        orientation: "Orientation",
        painRate: 178.63,
        indexation: 0.8931576483,
      },
      {
        name: "Adams",
        orientation: "Defense",
        painRate: 162.18,
        indexation: 0.8109156578,
      },
      {
        name: "Allen",
        orientation: "Plaintiff",
        painRate: 176.49,
        indexation: 0.882442646,
      },
      {
        name: "Bartholomew",
        orientation: "Neutral",
        painRate: 184.36,
        indexation: 0.9218112501,
      },
      {
        name: "Benton",
        orientation: "Defense",
        painRate: 166.54,
        indexation: 0.8326934653,
      },
      {
        name: "Blackford",
        orientation: "Defense",
        painRate: 146.68,
        indexation: 0.7333957595,
      },
      {
        name: "Boone",
        orientation: "Defense",
        painRate: 275.05,
        indexation: 1.375225737,
      },
      {
        name: "Brown",
        orientation: "Defense",
        painRate: 198.41,
        indexation: 0.9920540432,
      },
      {
        name: "Carroll",
        orientation: "Defense",
        painRate: 173.18,
        indexation: 0.8659086349,
      },
      {
        name: "Cass",
        orientation: "Defense",
        painRate: 161.73,
        indexation: 0.8086683165,
      },
      {
        name: "Clark",
        orientation: "Neutral",
        painRate: 183.25,
        indexation: 0.9162330279,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 158,
        indexation: 0.7900073574,
      },
      {
        name: "Clinton",
        orientation: "Defense",
        painRate: 162.98,
        indexation: 0.8148752592,
      },
      {
        name: "Crawford",
        orientation: "Defense",
        painRate: 143.26,
        indexation: 0.716299913,
      },
      {
        name: "Daviess",
        orientation: "Defense",
        painRate: 170.63,
        indexation: 0.8531469467,
      },
      {
        name: "Dearborn",
        orientation: "Defense",
        painRate: 207.67,
        indexation: 1.038338573,
      },
      {
        name: "Decatur",
        orientation: "Defense",
        painRate: 176.17,
        indexation: 0.8808374022,
      },
      {
        name: "De Kalb",
        orientation: "Defense",
        painRate: 195.06,
        indexation: 0.9753193766,
      },
      {
        name: "Delaware",
        orientation: "Plaintiff",
        painRate: 144.63,
        indexation: 0.7231489532,
      },
      {
        name: "Dubois",
        orientation: "Defense",
        painRate: 190.41,
        indexation: 0.9520567186,
      },
      {
        name: "Elkhart",
        orientation: "Neutral",
        painRate: 170.63,
        indexation: 0.8531469467,
      },
      {
        name: "Fayette",
        orientation: "Defense",
        painRate: 146.86,
        indexation: 0.7342920206,
      },
      {
        name: "Floyd",
        orientation: "Neutral",
        painRate: 198.69,
        indexation: 0.9934318775,
      },
      {
        name: "Fountain",
        orientation: "Defense",
        painRate: 171.15,
        indexation: 0.8557688449,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 200.06,
        indexation: 1.000321049,
      },
      {
        name: "Fulton",
        orientation: "Defense",
        painRate: 166.98,
        indexation: 0.8348872985,
      },
      {
        name: "Gibson",
        orientation: "Defense",
        painRate: 177.24,
        indexation: 0.8861882148,
      },
      {
        name: "Grant",
        orientation: "Neutral",
        painRate: 136.59,
        indexation: 0.6829375961,
      },
      {
        name: "Greene",
        orientation: "Defense",
        painRate: 164.97,
        indexation: 0.8248679018,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 313.2,
        indexation: 1.56602234,
      },
      {
        name: "Hancock",
        orientation: "Neutral",
        painRate: 235.02,
        indexation: 1.175105344,
      },
      {
        name: "Harrison",
        orientation: "Neutral",
        painRate: 193.32,
        indexation: 0.966610929,
      },
      {
        name: "Hendricks",
        orientation: "Neutral",
        painRate: 253.52,
        indexation: 1.267607518,
      },
      {
        name: "Henry",
        orientation: "Defense",
        painRate: 146.38,
        indexation: 0.731910909,
      },
      {
        name: "Howard",
        orientation: "Neutral",
        painRate: 152.39,
        indexation: 0.761955722,
      },
      {
        name: "Huntington",
        orientation: "Defense",
        painRate: 159.16,
        indexation: 0.7958129891,
      },
      {
        name: "Jackson",
        orientation: "Defense",
        painRate: 176.8,
        indexation: 0.8839810046,
      },
      {
        name: "Jasper",
        orientation: "Defense",
        painRate: 202.63,
        indexation: 1.013149622,
      },
      {
        name: "Jay",
        orientation: "Defense",
        painRate: 154.34,
        indexation: 0.7717209551,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 171.8,
        indexation: 0.8589927095,
      },
      {
        name: "Jennings",
        orientation: "Defense",
        painRate: 170.15,
        indexation: 0.850739081,
      },
      {
        name: "Johnson",
        orientation: "Neutral",
        painRate: 222.16,
        indexation: 1.110775199,
      },
      {
        name: "Knox",
        orientation: "Neutral",
        painRate: 155.81,
        indexation: 0.7790515685,
      },
      {
        name: "Kosciusko",
        orientation: "Defense",
        painRate: 191.59,
        indexation: 0.9579426125,
      },
      {
        name: "La Porte",
        orientation: "Neutral",
        painRate: 216.73,
        indexation: 1.083673333,
      },
      {
        name: "Lagrange",
        orientation: "Defense",
        painRate: 177.03,
        indexation: 0.8851448064,
      },
      {
        name: "Lake",
        orientation: "Plaintiff",
        painRate: 177.48,
        indexation: 0.8873787707,
      },
      {
        name: "Lawrence",
        orientation: "Defense",
        painRate: 160.9,
        indexation: 0.8045080597,
      },
      {
        name: "Madison",
        orientation: "Plaintiff",
        painRate: 157.65,
        indexation: 0.7882549662,
      },
      {
        name: "Marion",
        orientation: "Plaintiff",
        painRate: 167.95,
        indexation: 0.839756538,
      },
      {
        name: "Marshall",
        orientation: "Neutral",
        painRate: 189.79,
        indexation: 0.9489398702,
      },
      {
        name: "Martin",
        orientation: "Defense",
        painRate: 171.8,
        indexation: 0.8590194636,
      },
      {
        name: "Miami",
        orientation: "Defense",
        painRate: 162.76,
        indexation: 0.8137917196,
      },
      {
        name: "Monroe",
        orientation: "Plaintiff",
        painRate: 172.03,
        indexation: 0.8601297572,
      },
      {
        name: "Montgomery",
        orientation: "Defense",
        painRate: 177.33,
        indexation: 0.886669788,
      },
      {
        name: "Morgan",
        orientation: "Defense",
        painRate: 202.11,
        indexation: 1.010527724,
      },
      {
        name: "Newton",
        orientation: "Defense",
        painRate: 178.82,
        indexation: 0.8940806635,
      },
      {
        name: "Noble",
        orientation: "Defense",
        painRate: 186.12,
        indexation: 0.9305999599,
      },
      {
        name: "Ohio",
        orientation: "Defense",
        painRate: 188.93,
        indexation: 0.9446592201,
      },
      {
        name: "Orange",
        orientation: "Defense",
        painRate: 144.81,
        indexation: 0.7240719684,
      },
      {
        name: "Owen",
        orientation: "Defense",
        painRate: 167.11,
        indexation: 0.835529396,
      },
      {
        name: "Parke",
        orientation: "Defense",
        painRate: 173.1,
        indexation: 0.8654939469,
      },
      {
        name: "Perry",
        orientation: "Defense",
        painRate: 148.49,
        indexation: 0.7424653869,
      },
      {
        name: "Pike",
        orientation: "Defense",
        painRate: 169.85,
        indexation: 0.8492676075,
      },
      {
        name: "Porter",
        orientation: "Plaintiff",
        painRate: 225.68,
        indexation: 1.128379373,
      },
      {
        name: "Posey",
        orientation: "Neutral",
        painRate: 203.08,
        indexation: 1.01541034,
      },
      {
        name: "Pulaski",
        orientation: "Defense",
        painRate: 161.83,
        indexation: 0.8091365126,
      },
      {
        name: "Putnam",
        orientation: "Defense",
        painRate: 182.73,
        indexation: 0.9136646378,
      },
      {
        name: "Randolph",
        orientation: "Defense",
        painRate: 152.28,
        indexation: 0.7614206408,
      },
      {
        name: "Ripley",
        orientation: "Defense",
        painRate: 170.08,
        indexation: 0.8503912782,
      },
      {
        name: "Rush",
        orientation: "Defense",
        painRate: 164.95,
        indexation: 0.8247341315,
      },
      {
        name: "St. Joseph",
        orientation: "Neutral",
        painRate: 163.27,
        indexation: 0.8163734867,
      },
      {
        name: "Scott",
        orientation: "Defense",
        painRate: 154.74,
        indexation: 0.7736873788,
      },
      {
        name: "Shelby",
        orientation: "Neutral",
        painRate: 176.7,
        indexation: 0.8834860544,
      },
      {
        name: "Spencer",
        orientation: "Defense",
        painRate: 188.46,
        indexation: 0.9422781085,
      },
      {
        name: "Starke",
        orientation: "Neutral",
        painRate: 156.57,
        indexation: 0.7828640225,
      },
      {
        name: "Steuben",
        orientation: "Defense",
        painRate: 195.97,
        indexation: 0.9798675674,
      },
      {
        name: "Sullivan",
        orientation: "Neutral",
        painRate: 148.48,
        indexation: 0.7423851247,
      },
      {
        name: "Switzerland",
        orientation: "Defense",
        painRate: 169.21,
        indexation: 0.8460437429,
      },
      {
        name: "Tippecanoe",
        orientation: "Neutral",
        painRate: 150.06,
        indexation: 0.7502909504,
      },
      {
        name: "Tipton",
        orientation: "Defense",
        painRate: 173.97,
        indexation: 0.8698414822,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 163.66,
        indexation: 0.8182864022,
      },
      {
        name: "Vanderburgh",
        orientation: "Neutral",
        painRate: 155.17,
        indexation: 0.7758277038,
      },
      {
        name: "Vermillion",
        orientation: "Defense",
        painRate: 155.92,
        indexation: 0.7796134038,
      },
      {
        name: "Vigo",
        orientation: "Plaintiff",
        painRate: 139.89,
        indexation: 0.6994314762,
      },
      {
        name: "Wabash",
        orientation: "Defense",
        painRate: 171.29,
        indexation: 0.8564644505,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 196.75,
        indexation: 0.9837335295,
      },
      {
        name: "Warrick",
        orientation: "Neutral",
        painRate: 226.25,
        indexation: 1.131255434,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 174.82,
        indexation: 0.8740820012,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 138.11,
        indexation: 0.6905491271,
      },
      {
        name: "Wells",
        orientation: "Defense",
        painRate: 184.28,
        indexation: 0.9213965621,
      },
      {
        name: "White",
        orientation: "Defense",
        painRate: 177.02,
        indexation: 0.8851180523,
      },
      {
        name: "Whitley",
        orientation: "Defense",
        painRate: 193.83,
        indexation: 0.969139188,
      },
    ],
  },
  KS: {
    name: "KS",
    counties: [
      {
        name: "Kansas ",
        orientation: "Orientation",
        painRate: 184.14,
        indexation: 0.9207009565,
      },
      {
        name: "Allen",
        orientation: "Defense",
        painRate: 136.43,
        indexation: 0.6821617283,
      },
      {
        name: "Anderson",
        orientation: "Defense",
        painRate: 159.68,
        indexation: 0.7983813792,
      },
      {
        name: "Atchison",
        orientation: "Neutral",
        painRate: 158.08,
        indexation: 0.7903819143,
      },
      {
        name: "Barber",
        orientation: "Defense",
        painRate: 160.11,
        indexation: 0.8005484583,
      },
      {
        name: "Barton",
        orientation: "Defense",
        painRate: 151.72,
        indexation: 0.7586248411,
      },
      {
        name: "Bourbon",
        orientation: "Neutral",
        painRate: 120.27,
        indexation: 0.6013510802,
      },
      {
        name: "Brown",
        orientation: "Defense",
        painRate: 150.87,
        indexation: 0.754344191,
      },
      {
        name: "Butler",
        orientation: "Neutral",
        painRate: 214.61,
        indexation: 1.073025216,
      },
      {
        name: "Chase",
        orientation: "Defense",
        painRate: 156.73,
        indexation: 0.7836265133,
      },
      {
        name: "Chautauqua",
        orientation: "Defense",
        painRate: 132.32,
        indexation: 0.6615878537,
      },
      {
        name: "Cherokee",
        orientation: "Plaintiff",
        painRate: 147.05,
        indexation: 0.7352685439,
      },
      {
        name: "Cheyenne",
        orientation: "Defense",
        painRate: 151.16,
        indexation: 0.7558022875,
      },
      {
        name: "Clark",
        orientation: "Defense",
        painRate: 157.62,
        indexation: 0.7880944418,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 157.91,
        indexation: 0.7895257842,
      },
      {
        name: "Cloud",
        orientation: "Defense",
        painRate: 140.41,
        indexation: 0.7020667514,
      },
      {
        name: "Coffey",
        orientation: "Defense",
        painRate: 184.87,
        indexation: 0.9243528861,
      },
      {
        name: "Comanche",
        orientation: "Defense",
        painRate: 143.55,
        indexation: 0.7177713865,
      },
      {
        name: "Cowley",
        orientation: "Defense",
        painRate: 150.94,
        indexation: 0.7546786168,
      },
      {
        name: "Crawford",
        orientation: "Plaintiff",
        painRate: 138.68,
        indexation: 0.6933984349,
      },
      {
        name: "Decatur",
        orientation: "Defense",
        painRate: 136.85,
        indexation: 0.6842619223,
      },
      {
        name: "Dickinson",
        orientation: "Neutral",
        painRate: 168.91,
        indexation: 0.8445722694,
      },
      {
        name: "Doniphan",
        orientation: "Neutral",
        painRate: 172.85,
        indexation: 0.86426326,
      },
      {
        name: "Douglas",
        orientation: "Plaintiff",
        painRate: 180.54,
        indexation: 0.9027088489,
      },
      {
        name: "Edwards",
        orientation: "Defense",
        painRate: 149.23,
        indexation: 0.7461306936,
      },
      {
        name: "Elk",
        orientation: "Defense",
        painRate: 126,
        indexation: 0.6299779279,
      },
      {
        name: "Ellis",
        orientation: "Neutral",
        painRate: 165.01,
        indexation: 0.8250284262,
      },
      {
        name: "Ellsworth",
        orientation: "Defense",
        painRate: 170.25,
        indexation: 0.8512607852,
      },
      {
        name: "Finney",
        orientation: "Plaintiff",
        painRate: 187.19,
        indexation: 0.9359373955,
      },
      {
        name: "Ford",
        orientation: "Defense",
        painRate: 181.16,
        indexation: 0.9058123202,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 179.96,
        indexation: 0.899779279,
      },
      {
        name: "Geary",
        orientation: "Plaintiff",
        painRate: 160.59,
        indexation: 0.802969701,
      },
      {
        name: "Gove",
        orientation: "Defense",
        painRate: 171.32,
        indexation: 0.8566249749,
      },
      {
        name: "Graham",
        orientation: "Defense",
        painRate: 142.42,
        indexation: 0.7120995251,
      },
      {
        name: "Grant",
        orientation: "Defense",
        painRate: 176.64,
        indexation: 0.8831783827,
      },
      {
        name: "Gray",
        orientation: "Defense",
        painRate: 208.04,
        indexation: 1.040211357,
      },
      {
        name: "Greeley",
        orientation: "Defense",
        painRate: 193.43,
        indexation: 0.9671593873,
      },
      {
        name: "Greenwood",
        orientation: "Defense",
        painRate: 139.51,
        indexation: 0.6975453147,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 169.73,
        indexation: 0.8486656411,
      },
      {
        name: "Harper",
        orientation: "Defense",
        painRate: 141.51,
        indexation: 0.7075513344,
      },
      {
        name: "Harvey",
        orientation: "Defense",
        painRate: 197.59,
        indexation: 0.9879472945,
      },
      {
        name: "Haskell",
        orientation: "Defense",
        painRate: 187.2,
        indexation: 0.9360042807,
      },
      {
        name: "Hodgeman",
        orientation: "Defense",
        painRate: 180.53,
        indexation: 0.9026553408,
      },
      {
        name: "Jackson",
        orientation: "Defense",
        painRate: 188.47,
        indexation: 0.9423449936,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 202.73,
        indexation: 1.013631195,
      },
      {
        name: "Jewell",
        orientation: "Defense",
        painRate: 150.21,
        indexation: 0.7510400642,
      },
      {
        name: "Johnson",
        orientation: "Neutral",
        painRate: 265.33,
        indexation: 1.326653736,
      },
      {
        name: "Kearny",
        orientation: "Defense",
        painRate: 202.72,
        indexation: 1.013617818,
      },
      {
        name: "Kingman",
        orientation: "Defense",
        painRate: 175.5,
        indexation: 0.8774931443,
      },
      {
        name: "Kiowa",
        orientation: "Defense",
        painRate: 165.07,
        indexation: 0.825362852,
      },
      {
        name: "Labette",
        orientation: "Plaintiff",
        painRate: 139.97,
        indexation: 0.6998595412,
      },
      {
        name: "Lane",
        orientation: "Defense",
        painRate: 173.72,
        indexation: 0.8686241723,
      },
      {
        name: "Leavenworth",
        orientation: "Neutral",
        painRate: 211.67,
        indexation: 1.058350612,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 155.3,
        indexation: 0.7765233095,
      },
      {
        name: "Linn",
        orientation: "Defense",
        painRate: 159.59,
        indexation: 0.7979533142,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 169.1,
        indexation: 0.8454819076,
      },
      {
        name: "Lyon",
        orientation: "Plaintiff",
        painRate: 149.46,
        indexation: 0.7472811183,
      },
      {
        name: "Marion",
        orientation: "Defense",
        painRate: 197.35,
        indexation: 0.9867701157,
      },
      {
        name: "Marshall",
        orientation: "Defense",
        painRate: 172.13,
        indexation: 0.8606380844,
      },
      {
        name: "McPherson",
        orientation: "Defense",
        painRate: 157.69,
        indexation: 0.7884689987,
      },
      {
        name: "Meade",
        orientation: "Defense",
        painRate: 182.03,
        indexation: 0.9101464785,
      },
      {
        name: "Miami",
        orientation: "Neutral",
        painRate: 221,
        indexation: 1.105023075,
      },
      {
        name: "Mitchell",
        orientation: "Defense",
        painRate: 171.04,
        indexation: 0.8551802555,
      },
      {
        name: "Montgomery",
        orientation: "Defense",
        painRate: 147.03,
        indexation: 0.7351347736,
      },
      {
        name: "Morris",
        orientation: "Defense",
        painRate: 162.51,
        indexation: 0.8125342786,
      },
      {
        name: "Morton",
        orientation: "Defense",
        painRate: 168.27,
        indexation: 0.8413617818,
      },
      {
        name: "Nemaha",
        orientation: "Defense",
        painRate: 194.55,
        indexation: 0.9727643636,
      },
      {
        name: "Neosho",
        orientation: "Defense",
        painRate: 131.96,
        indexation: 0.6597819544,
      },
      {
        name: "Ness",
        orientation: "Defense",
        painRate: 164.64,
        indexation: 0.8232091499,
      },
      {
        name: "Norton",
        orientation: "Defense",
        painRate: 151.44,
        indexation: 0.7571934988,
      },
      {
        name: "Osage",
        orientation: "Defense",
        painRate: 168.54,
        indexation: 0.842686108,
      },
      {
        name: "Osborne",
        orientation: "Defense",
        painRate: 144.39,
        indexation: 0.7219717745,
      },
      {
        name: "Ottawa",
        orientation: "Defense",
        painRate: 186.05,
        indexation: 0.9302655341,
      },
      {
        name: "Pawnee",
        orientation: "Defense",
        painRate: 128.26,
        indexation: 0.6412815196,
      },
      {
        name: "Phillips",
        orientation: "Defense",
        painRate: 157.4,
        indexation: 0.7870242793,
      },
      {
        name: "Pottawatomie",
        orientation: "Defense",
        painRate: 229.61,
        indexation: 1.148043609,
      },
      {
        name: "Pratt",
        orientation: "Defense",
        painRate: 176.07,
        indexation: 0.8803692061,
      },
      {
        name: "Rawlins",
        orientation: "Defense",
        painRate: 158.16,
        indexation: 0.7907966022,
      },
      {
        name: "Reno",
        orientation: "Neutral",
        painRate: 154.27,
        indexation: 0.7713597753,
      },
      {
        name: "Republic",
        orientation: "Defense",
        painRate: 144.4,
        indexation: 0.7220119056,
      },
      {
        name: "Rice",
        orientation: "Defense",
        painRate: 165.14,
        indexation: 0.8257240318,
      },
      {
        name: "Riley",
        orientation: "Defense",
        painRate: 149.49,
        indexation: 0.7474550197,
      },
      {
        name: "Rooks",
        orientation: "Defense",
        painRate: 152.58,
        indexation: 0.7628787372,
      },
      {
        name: "Rush",
        orientation: "Defense",
        painRate: 151.51,
        indexation: 0.7575279246,
      },
      {
        name: "Russell",
        orientation: "Defense",
        painRate: 130.44,
        indexation: 0.6521838004,
      },
      {
        name: "Saline",
        orientation: "Defense",
        painRate: 154,
        indexation: 0.7700220721,
      },
      {
        name: "Scott",
        orientation: "Defense",
        painRate: 190.28,
        indexation: 0.951387867,
      },
      {
        name: "Sedgwick",
        orientation: "Plaintiff",
        painRate: 173.15,
        indexation: 0.8657614875,
      },
      {
        name: "Seward",
        orientation: "Defense",
        painRate: 170.1,
        indexation: 0.8504982944,
      },
      {
        name: "Shawnee",
        orientation: "Plaintiff",
        painRate: 163.88,
        indexation: 0.8194100729,
      },
      {
        name: "Sheridan",
        orientation: "Defense",
        painRate: 168.33,
        indexation: 0.8416560765,
      },
      {
        name: "Sherman",
        orientation: "Defense",
        painRate: 152.62,
        indexation: 0.7630793927,
      },
      {
        name: "Smith",
        orientation: "Defense",
        painRate: 139.39,
        indexation: 0.6969567253,
      },
      {
        name: "Stafford",
        orientation: "Defense",
        painRate: 155.22,
        indexation: 0.7761219985,
      },
      {
        name: "Stanton",
        orientation: "Defense",
        painRate: 203.7,
        indexation: 1.018487058,
      },
      {
        name: "Stevens",
        orientation: "Neutral",
        painRate: 185.46,
        indexation: 0.9272958331,
      },
      {
        name: "Sumner",
        orientation: "Defense",
        painRate: 168.34,
        indexation: 0.8416962076,
      },
      {
        name: "Thomas",
        orientation: "Defense",
        painRate: 168.33,
        indexation: 0.8416293225,
      },
      {
        name: "Trego",
        orientation: "Defense",
        painRate: 146.15,
        indexation: 0.7307604842,
      },
      {
        name: "Wabaunsee",
        orientation: "Defense",
        painRate: 183.09,
        indexation: 0.9154571601,
      },
      {
        name: "Wallace",
        orientation: "Defense",
        painRate: 166.7,
        indexation: 0.8335094642,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 170.68,
        indexation: 0.8534144873,
      },
      {
        name: "Wichita",
        orientation: "Defense",
        painRate: 182.53,
        indexation: 0.9126479834,
      },
      {
        name: "Wilson",
        orientation: "Defense",
        painRate: 139.29,
        indexation: 0.6964617751,
      },
      {
        name: "Woodson",
        orientation: "Defense",
        painRate: 132.37,
        indexation: 0.6618553943,
      },
      {
        name: "Wyandotte",
        orientation: "Plaintiff",
        painRate: 157.05,
        indexation: 0.7852451341,
      },
    ],
  },
  KY: {
    name: "KY",
    counties: [
      {
        name: "Kentucky",
        orientation: "Orientation",
        painRate: 158.51,
        indexation: 0.7925356163,
      },
      {
        name: "Adair",
        orientation: "Neutral",
        painRate: 118.13,
        indexation: 0.5906360779,
      },
      {
        name: "Allen",
        orientation: "Neutral",
        painRate: 164.95,
        indexation: 0.8247608856,
      },
      {
        name: "Anderson",
        orientation: "Defense ",
        painRate: 178.84,
        indexation: 0.8942010568,
      },
      {
        name: "Ballard",
        orientation: "Neutral",
        painRate: 151.44,
        indexation: 0.7572068758,
      },
      {
        name: "Barren",
        orientation: "Defense ",
        painRate: 135.53,
        indexation: 0.6776536687,
      },
      {
        name: "Bath",
        orientation: "Defense ",
        painRate: 129.12,
        indexation: 0.6455755468,
      },
      {
        name: "Bell",
        orientation: "Plaintiff",
        painRate: 90.76,
        indexation: 0.4537890442,
      },
      {
        name: "Boone",
        orientation: "Defense ",
        painRate: 241.5,
        indexation: 1.207477761,
      },
      {
        name: "Bourbon",
        orientation: "Defense ",
        painRate: 152.17,
        indexation: 0.7608721825,
      },
      {
        name: "Boyd",
        orientation: "Neutral",
        painRate: 148.21,
        indexation: 0.7410474216,
      },
      {
        name: "Boyle",
        orientation: "Defense ",
        painRate: 155.11,
        indexation: 0.7755334091,
      },
      {
        name: "Bracken",
        orientation: "Defense ",
        painRate: 155.98,
        indexation: 0.7798943215,
      },
      {
        name: "Breathitt",
        orientation: "Plaintiff",
        painRate: 97.12,
        indexation: 0.4855862484,
      },
      {
        name: "Breckinridge",
        orientation: "Defense ",
        painRate: 140.19,
        indexation: 0.7009564578,
      },
      {
        name: "Bullitt",
        orientation: "Neutral",
        painRate: 206.52,
        indexation: 1.032599826,
      },
      {
        name: "Butler",
        orientation: "Neutral",
        painRate: 135.97,
        indexation: 0.6798742559,
      },
      {
        name: "Caldwell",
        orientation: "Neutral",
        painRate: 137.93,
        indexation: 0.689626112,
      },
      {
        name: "Calloway",
        orientation: "Neutral",
        painRate: 138.98,
        indexation: 0.6949234165,
      },
      {
        name: "Campbell",
        orientation: "Neutral",
        painRate: 198.38,
        indexation: 0.9918935188,
      },
      {
        name: "Carlisle",
        orientation: "Neutral",
        painRate: 145.9,
        indexation: 0.7295164203,
      },
      {
        name: "Carroll",
        orientation: "Neutral",
        painRate: 158.45,
        indexation: 0.7922680757,
      },
      {
        name: "Carter",
        orientation: "Plaintiff",
        painRate: 129.18,
        indexation: 0.6459099726,
      },
      {
        name: "Casey",
        orientation: "Neutral",
        painRate: 116.48,
        indexation: 0.5824225804,
      },
      {
        name: "Christian",
        orientation: "Neutral",
        painRate: 151.45,
        indexation: 0.757273761,
      },
      {
        name: "Clark",
        orientation: "Defense ",
        painRate: 164.91,
        indexation: 0.8245736071,
      },
      {
        name: "Clay",
        orientation: "Plaintiff",
        painRate: 92.23,
        indexation: 0.4611464116,
      },
      {
        name: "Clinton",
        orientation: "Neutral",
        painRate: 111.67,
        indexation: 0.5583439235,
      },
      {
        name: "Crittenden",
        orientation: "Neutral",
        painRate: 134.98,
        indexation: 0.6748980001,
      },
      {
        name: "Cumberland",
        orientation: "Neutral",
        painRate: 130.73,
        indexation: 0.6536552739,
      },
      {
        name: "Daviess",
        orientation: "Neutral",
        painRate: 176.44,
        indexation: 0.8822018594,
      },
      {
        name: "Edmonson",
        orientation: "Defense ",
        painRate: 137.52,
        indexation: 0.6876061802,
      },
      {
        name: "Elliott",
        orientation: "Plaintiff",
        painRate: 107.85,
        indexation: 0.5392548993,
      },
      {
        name: "Estill",
        orientation: "Neutral",
        painRate: 105.42,
        indexation: 0.5270818005,
      },
      {
        name: "Fayette",
        orientation: "Defense ",
        painRate: 170.34,
        indexation: 0.8516754732,
      },
      {
        name: "Fleming",
        orientation: "Defense ",
        painRate: 133.8,
        indexation: 0.6690121062,
      },
      {
        name: "Floyd",
        orientation: "Plaintiff",
        painRate: 99.78,
        indexation: 0.4989097719,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 156.39,
        indexation: 0.7819677614,
      },
      {
        name: "Fulton",
        orientation: "Neutral",
        painRate: 108.11,
        indexation: 0.5405524714,
      },
      {
        name: "Gallatin",
        orientation: "Neutral",
        painRate: 158.32,
        indexation: 0.7915858471,
      },
      {
        name: "Garrard",
        orientation: "Defense ",
        painRate: 154.5,
        indexation: 0.772496823,
      },
      {
        name: "Grant",
        orientation: "Defense ",
        painRate: 177.8,
        indexation: 0.8890107685,
      },
      {
        name: "Graves",
        orientation: "Neutral",
        painRate: 142.89,
        indexation: 0.7144405057,
      },
      {
        name: "Grayson",
        orientation: "Defense ",
        painRate: 139.47,
        indexation: 0.6973446592,
      },
      {
        name: "Green",
        orientation: "Defense ",
        painRate: 123.52,
        indexation: 0.6176041736,
      },
      {
        name: "Greenup",
        orientation: "Neutral",
        painRate: 145.71,
        indexation: 0.728539897,
      },
      {
        name: "Hancock",
        orientation: "Defense ",
        painRate: 177.38,
        indexation: 0.8868971975,
      },
      {
        name: "Hardin",
        orientation: "Defense ",
        painRate: 165.66,
        indexation: 0.8283057989,
      },
      {
        name: "Harlan",
        orientation: "Plaintiff",
        painRate: 96.37,
        indexation: 0.4818674336,
      },
      {
        name: "Harrison",
        orientation: "Defense ",
        painRate: 163.1,
        indexation: 0.8154906026,
      },
      {
        name: "Hart",
        orientation: "Defense ",
        painRate: 126.36,
        indexation: 0.6317838272,
      },
      {
        name: "Henderson",
        orientation: "Neutral",
        painRate: 148.57,
        indexation: 0.7428265668,
      },
      {
        name: "Henry",
        orientation: "Defense ",
        painRate: 174.08,
        indexation: 0.8704166945,
      },
      {
        name: "Hickman",
        orientation: "Neutral",
        painRate: 144.04,
        indexation: 0.7201926293,
      },
      {
        name: "Hopkins",
        orientation: "Neutral",
        painRate: 139.65,
        indexation: 0.6982542974,
      },
      {
        name: "Jackson",
        orientation: "Plaintiff",
        painRate: 109.71,
        indexation: 0.5485519363,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 173.15,
        indexation: 0.8657481105,
      },
      {
        name: "Jessamine",
        orientation: "Defense ",
        painRate: 185.18,
        indexation: 0.9259046218,
      },
      {
        name: "Johnson",
        orientation: "Plaintiff",
        painRate: 114.71,
        indexation: 0.5735669855,
      },
      {
        name: "Kenton",
        orientation: "Neutral",
        painRate: 206.77,
        indexation: 1.033830513,
      },
      {
        name: "Knott",
        orientation: "Plaintiff",
        painRate: 102.89,
        indexation: 0.5144271286,
      },
      {
        name: "Knox",
        orientation: "Plaintiff",
        painRate: 97.85,
        indexation: 0.489238178,
      },
      {
        name: "Larue",
        orientation: "Defense ",
        painRate: 144.31,
        indexation: 0.7215437095,
      },
      {
        name: "Laurel",
        orientation: "Plaintiff",
        painRate: 135.7,
        indexation: 0.6784964216,
      },
      {
        name: "Lawrence",
        orientation: "Plaintiff",
        painRate: 114.44,
        indexation: 0.5722159053,
      },
      {
        name: "Lee",
        orientation: "Plaintiff",
        painRate: 92.65,
        indexation: 0.4632733596,
      },
      {
        name: "Leslie",
        orientation: "Plaintiff",
        painRate: 98.1,
        indexation: 0.490482242,
      },
      {
        name: "Letcher",
        orientation: "Plaintiff",
        painRate: 101.27,
        indexation: 0.5063741556,
      },
      {
        name: "Lewis",
        orientation: "Defense ",
        painRate: 117.98,
        indexation: 0.5899003411,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 133.27,
        indexation: 0.6663634539,
      },
      {
        name: "Livingston",
        orientation: "Neutral",
        painRate: 148.1,
        indexation: 0.7404855862,
      },
      {
        name: "Logan",
        orientation: "Neutral",
        painRate: 152.02,
        indexation: 0.7601230687,
      },
      {
        name: "Lyon",
        orientation: "Neutral",
        painRate: 157.68,
        indexation: 0.7884154906,
      },
      {
        name: "McCracken",
        orientation: "Neutral",
        painRate: 160.59,
        indexation: 0.802969701,
      },
      {
        name: "McCreary",
        orientation: "Plaintiff",
        painRate: 94.82,
        indexation: 0.4741221323,
      },
      {
        name: "McLean",
        orientation: "Neutral",
        painRate: 148.63,
        indexation: 0.7431609926,
      },
      {
        name: "Madison",
        orientation: "Neutral",
        painRate: 163.18,
        indexation: 0.8159186676,
      },
      {
        name: "Magoffin",
        orientation: "Plaintiff",
        painRate: 95.43,
        indexation: 0.4771453414,
      },
      {
        name: "Marion",
        orientation: "Defense ",
        painRate: 143.61,
        indexation: 0.7180255501,
      },
      {
        name: "Marshall",
        orientation: "Neutral",
        painRate: 172.28,
        indexation: 0.8614139522,
      },
      {
        name: "Martin",
        orientation: "Plaintiff",
        painRate: 92.13,
        indexation: 0.4606380844,
      },
      {
        name: "Mason",
        orientation: "Defense ",
        painRate: 131.17,
        indexation: 0.6558624841,
      },
      {
        name: "Meade",
        orientation: "Defense ",
        painRate: 186.29,
        indexation: 0.9314694669,
      },
      {
        name: "Menifee",
        orientation: "Neutral",
        painRate: 115.65,
        indexation: 0.5782355695,
      },
      {
        name: "Mercer",
        orientation: "Defense ",
        painRate: 174.68,
        indexation: 0.8734131496,
      },
      {
        name: "Metcalfe",
        orientation: "Neutral",
        painRate: 120.32,
        indexation: 0.6016052438,
      },
      {
        name: "Monroe",
        orientation: "Defense ",
        painRate: 122.73,
        indexation: 0.6136311952,
      },
      {
        name: "Montgomery",
        orientation: "Defense ",
        painRate: 149.62,
        indexation: 0.7480971172,
      },
      {
        name: "Morgan",
        orientation: "Plaintiff",
        painRate: 112,
        indexation: 0.5600026754,
      },
      {
        name: "Muhlenberg",
        orientation: "Neutral",
        painRate: 128.93,
        indexation: 0.6446391546,
      },
      {
        name: "Nelson",
        orientation: "Neutral",
        painRate: 178.4,
        indexation: 0.8919938466,
      },
      {
        name: "Nicholas",
        orientation: "Defense ",
        painRate: 136.71,
        indexation: 0.6835663166,
      },
      {
        name: "Ohio",
        orientation: "Neutral",
        painRate: 134.51,
        indexation: 0.6725302655,
      },
      {
        name: "Oldham",
        orientation: "Defense ",
        painRate: 310.81,
        indexation: 1.554049896,
      },
      {
        name: "Owen",
        orientation: "Defense ",
        painRate: 162.11,
        indexation: 0.8105411009,
      },
      {
        name: "Owsley",
        orientation: "Plaintiff",
        painRate: 87.71,
        indexation: 0.4385392281,
      },
      {
        name: "Pendleton",
        orientation: "Defense ",
        painRate: 175.85,
        indexation: 0.8792321584,
      },
      {
        name: "Perry",
        orientation: "Plaintiff",
        painRate: 117.57,
        indexation: 0.5878536553,
      },
      {
        name: "Pike",
        orientation: "Plaintiff",
        painRate: 111.93,
        indexation: 0.5596548726,
      },
      {
        name: "Powell",
        orientation: "Plaintiff",
        painRate: 113.53,
        indexation: 0.5676409605,
      },
      {
        name: "Pulaski",
        orientation: "Plaintiff",
        painRate: 124.09,
        indexation: 0.6204267273,
      },
      {
        name: "Robertson",
        orientation: "Defense ",
        painRate: 143.02,
        indexation: 0.7150826032,
      },
      {
        name: "Rockcastle",
        orientation: "Neutral",
        painRate: 120.82,
        indexation: 0.6041201257,
      },
      {
        name: "Rowan",
        orientation: "Defense ",
        painRate: 135.1,
        indexation: 0.6755133436,
      },
      {
        name: "Russell",
        orientation: "Neutral",
        painRate: 118.85,
        indexation: 0.5942746305,
      },
      {
        name: "Scott",
        orientation: "Defense ",
        painRate: 230.54,
        indexation: 1.152685439,
      },
      {
        name: "Shelby",
        orientation: "Neutral",
        painRate: 218.89,
        indexation: 1.094441843,
      },
      {
        name: "Simpson",
        orientation: "Defense ",
        painRate: 156.65,
        indexation: 0.7832252023,
      },
      {
        name: "Spencer",
        orientation: "Defense ",
        painRate: 271.69,
        indexation: 1.358464317,
      },
      {
        name: "Taylor",
        orientation: "Defense ",
        painRate: 140.23,
        indexation: 0.7011437362,
      },
      {
        name: "Todd",
        orientation: "Neutral",
        painRate: 142.55,
        indexation: 0.7127282456,
      },
      {
        name: "Trigg",
        orientation: "Neutral",
        painRate: 150.98,
        indexation: 0.7549060264,
      },
      {
        name: "Trimble",
        orientation: "Neutral",
        painRate: 176.12,
        indexation: 0.8805832386,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 155.77,
        indexation: 0.778850913,
      },
      {
        name: "Warren",
        orientation: "Defense ",
        painRate: 162.27,
        indexation: 0.8113303458,
      },
      {
        name: "Washington",
        orientation: "Defense ",
        painRate: 160.36,
        indexation: 0.8017791452,
      },
      {
        name: "Wayne",
        orientation: "Plaintiff",
        painRate: 106.65,
        indexation: 0.5332486121,
      },
      {
        name: "Webster",
        orientation: "Neutral",
        painRate: 149.96,
        indexation: 0.7498093773,
      },
      {
        name: "Whitley",
        orientation: "Plaintiff",
        painRate: 116.66,
        indexation: 0.5832920875,
      },
      {
        name: "Wolfe",
        orientation: "Plaintiff",
        painRate: 100.91,
        indexation: 0.5045548793,
      },
      {
        name: "Woodford",
        orientation: "Defense ",
        painRate: 205.02,
        indexation: 1.025122065,
      },
    ],
  },
  LA: {
    name: "LA",
    counties: [
      {
        name: "Louisiana ",
        orientation: "Orientation",
        painRate: 148.37,
        indexation: 0.7418634205,
      },
      {
        name: "Acadia",
        orientation: "Neutral",
        painRate: 114.99,
        indexation: 0.5749581968,
      },
      {
        name: "Allen",
        orientation: "Plaintiff",
        painRate: 137.58,
        indexation: 0.6878870979,
      },
      {
        name: "Ascension",
        orientation: "Plaintiff",
        painRate: 217.47,
        indexation: 1.08733864,
      },
      {
        name: "Assumption",
        orientation: "Plaintiff",
        painRate: 145.55,
        indexation: 0.7277506521,
      },
      {
        name: "Avoyelles",
        orientation: "Plaintiff",
        painRate: 111.26,
        indexation: 0.5563106147,
      },
      {
        name: "Beauregard",
        orientation: "Neutral",
        painRate: 172.28,
        indexation: 0.8614005752,
      },
      {
        name: "Bienville",
        orientation: "Defense",
        painRate: 111.85,
        indexation: 0.5592535616,
      },
      {
        name: "Bossier",
        orientation: "Defense",
        painRate: 170.72,
        indexation: 0.8536151428,
      },
      {
        name: "Caddo",
        orientation: "Neutral",
        painRate: 127.61,
        indexation: 0.638071032,
      },
      {
        name: "Calcasieu",
        orientation: "Plaintiff",
        painRate: 165.33,
        indexation: 0.8266604241,
      },
      {
        name: "Caldwell",
        orientation: "Neutral",
        painRate: 124.31,
        indexation: 0.621550398,
      },
      {
        name: "Cameron",
        orientation: "Plaintiff",
        painRate: 169.66,
        indexation: 0.8482777072,
      },
      {
        name: "Catahoula",
        orientation: "Plaintiff",
        painRate: 111.5,
        indexation: 0.5575011705,
      },
      {
        name: "Claiborne",
        orientation: "Defense",
        painRate: 101.1,
        indexation: 0.5054912715,
      },
      {
        name: "Concordia",
        orientation: "Plaintiff",
        painRate: 109.96,
        indexation: 0.5498093773,
      },
      {
        name: "De Soto",
        orientation: "Defense",
        painRate: 141.2,
        indexation: 0.7059862217,
      },
      {
        name: "East Baton Rouge",
        orientation: "Plaintiff",
        painRate: 164.21,
        indexation: 0.8210286937,
      },
      {
        name: "East Carroll",
        orientation: "Plaintiff",
        painRate: 82.1,
        indexation: 0.4105143469,
      },
      {
        name: "East Feliciana",
        orientation: "Neutral",
        painRate: 152.04,
        indexation: 0.7602167079,
      },
      {
        name: "Evangeline",
        orientation: "Plaintiff",
        painRate: 109.51,
        indexation: 0.547562036,
      },
      {
        name: "Franklin",
        orientation: "Plaintiff",
        painRate: 103.23,
        indexation: 0.5161393887,
      },
      {
        name: "Grant",
        orientation: "Plaintiff",
        painRate: 141.35,
        indexation: 0.7067353354,
      },
      {
        name: "Iberia",
        orientation: "Neutral",
        painRate: 120.6,
        indexation: 0.6029964551,
      },
      {
        name: "Iberville",
        orientation: "Plaintiff",
        painRate: 142.34,
        indexation: 0.7117249682,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 117.22,
        indexation: 0.5860878871,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 163.62,
        indexation: 0.8180991238,
      },
      {
        name: "Jefferson Davis",
        orientation: "Plaintiff",
        painRate: 140.23,
        indexation: 0.7011437362,
      },
      {
        name: "La Salle",
        orientation: "Plaintiff",
        painRate: 156.89,
        indexation: 0.7844425122,
      },
      {
        name: "Lafayette",
        orientation: "Plaintiff",
        painRate: 159.39,
        indexation: 0.7969366598,
      },
      {
        name: "Lafourche",
        orientation: "Neutral",
        painRate: 148.84,
        indexation: 0.744204401,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 119.73,
        indexation: 0.5986489198,
      },
      {
        name: "Livingston",
        orientation: "Neutral",
        painRate: 195,
        indexation: 0.9750117049,
      },
      {
        name: "Madison",
        orientation: "Plaintiff",
        painRate: 89.37,
        indexation: 0.4468329878,
      },
      {
        name: "Morehouse",
        orientation: "Plaintiff",
        painRate: 105.66,
        indexation: 0.5282991104,
      },
      {
        name: "Natchitoches",
        orientation: "Plaintiff",
        painRate: 112.84,
        indexation: 0.5642164404,
      },
      {
        name: "Orleans",
        orientation: "Plaintiff",
        painRate: 138.89,
        indexation: 0.6944418434,
      },
      {
        name: "Ouachita",
        orientation: "Neutral",
        painRate: 126.5,
        indexation: 0.6325061869,
      },
      {
        name: "Plaquemines",
        orientation: "Neutral",
        painRate: 192.3,
        indexation: 0.9614875259,
      },
      {
        name: "Pointe Coupee",
        orientation: "Plaintiff",
        painRate: 151.05,
        indexation: 0.7552270751,
      },
      {
        name: "Rapides",
        orientation: "Plaintiff",
        painRate: 149.47,
        indexation: 0.7473747575,
      },
      {
        name: "Red River",
        orientation: "Defense",
        painRate: 118.09,
        indexation: 0.5904487994,
      },
      {
        name: "Richland",
        orientation: "Plaintiff",
        painRate: 118.98,
        indexation: 0.5948765969,
      },
      {
        name: "Sabine",
        orientation: "Defense",
        painRate: 120.59,
        indexation: 0.6029295699,
      },
      {
        name: "St. Bernard",
        orientation: "Plaintiff",
        painRate: 137.74,
        indexation: 0.6887164738,
      },
      {
        name: "St. Charles",
        orientation: "Plaintiff",
        painRate: 201.25,
        indexation: 1.006260451,
      },
      {
        name: "St. Helena",
        orientation: "Neutral",
        painRate: 120.46,
        indexation: 0.6022874724,
      },
      {
        name: "St. James",
        orientation: "Plaintiff",
        painRate: 181.76,
        indexation: 0.9088087753,
      },
      {
        name: "St. John The Baptist",
        orientation: "Plaintiff",
        painRate: 162.05,
        indexation: 0.8102334292,
      },
      {
        name: "St. Landry",
        orientation: "Plaintiff",
        painRate: 114.41,
        indexation: 0.5720687579,
      },
      {
        name: "St. Martin",
        orientation: "Neutral",
        painRate: 145.18,
        indexation: 0.7259046218,
      },
      {
        name: "St. Mary",
        orientation: "Neutral",
        painRate: 129.07,
        indexation: 0.6453347602,
      },
      {
        name: "St. Tammany",
        orientation: "Defense",
        painRate: 210.36,
        indexation: 1.051822621,
      },
      {
        name: "Tangipahoa",
        orientation: "Neutral",
        painRate: 146.99,
        indexation: 0.7349608722,
      },
      {
        name: "Tensas",
        orientation: "Plaintiff",
        painRate: 89.06,
        indexation: 0.4453213832,
      },
      {
        name: "Terrebonne",
        orientation: "Neutral",
        painRate: 153.5,
        indexation: 0.7675205672,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 128.43,
        indexation: 0.6421376497,
      },
      {
        name: "Vermilion",
        orientation: "Neutral",
        painRate: 135.25,
        indexation: 0.6762624574,
      },
      {
        name: "Vernon",
        orientation: "Defense",
        painRate: 140.8,
        indexation: 0.7039796669,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 111.78,
        indexation: 0.5588790047,
      },
      {
        name: "Webster",
        orientation: "Defense",
        painRate: 116.91,
        indexation: 0.5845495285,
      },
      {
        name: "West Baton Rouge",
        orientation: "Plaintiff",
        painRate: 190.92,
        indexation: 0.9546117317,
      },
      {
        name: "West Carroll",
        orientation: "Plaintiff",
        painRate: 110.71,
        indexation: 0.5535415691,
      },
      {
        name: "West Feliciana",
        orientation: "Neutral",
        painRate: 182.64,
        indexation: 0.9131964417,
      },
      {
        name: "Winn",
        orientation: "Neutral",
        painRate: 122.12,
        indexation: 0.6106079861,
      },
    ],
  },
  MA: {
    name: "MA",
    counties: [
      {
        name: "Massachusetts",
        orientation: "Orientation",
        painRate: 252.93,
        indexation: 1.264637817,
      },
      {
        name: "Barnstable",
        orientation: "Defense",
        painRate: 240.18,
        indexation: 1.200909638,
      },
      {
        name: "Berkshire",
        orientation: "Neutral",
        painRate: 190.23,
        indexation: 0.9511337034,
      },
      {
        name: "Bristol",
        orientation: "Defense",
        painRate: 215.91,
        indexation: 1.079553207,
      },
      {
        name: "Dukes",
        orientation: "Defense",
        painRate: 238.48,
        indexation: 1.192375092,
      },
      {
        name: "Essex",
        orientation: "Neutral",
        painRate: 246.45,
        indexation: 1.2322654,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 187.09,
        indexation: 0.9354424453,
      },
      {
        name: "Hampden",
        orientation: "Neutral",
        painRate: 171.31,
        indexation: 0.8565313357,
      },
      {
        name: "Hampshire",
        orientation: "Defense",
        painRate: 219.06,
        indexation: 1.095284596,
      },
      {
        name: "Middlesex",
        orientation: "Plaintiff",
        painRate: 317.02,
        indexation: 1.585097987,
      },
      {
        name: "Nantucket",
        orientation: "Defense",
        painRate: 287.91,
        indexation: 1.439542506,
      },
      {
        name: "Norfolk",
        orientation: "Defense",
        painRate: 309.73,
        indexation: 1.548658953,
      },
      {
        name: "Plymouth",
        orientation: "Plaintiff",
        painRate: 274.62,
        indexation: 1.373112166,
      },
      {
        name: "Suffolk",
        orientation: "Defense",
        painRate: 226.2,
        indexation: 1.131001271,
      },
      {
        name: "Worcester",
        orientation: "Defense",
        painRate: 230.29,
        indexation: 1.151468129,
      },
    ],
  },
  MD: {
    name: "MD",
    counties: [
      {
        name: "Maryland",
        orientation: "Orientation",
        painRate: 254.05,
        indexation: 1.270242793,
      },
      {
        name: "Allegany",
        orientation: "Defense",
        painRate: 135.33,
        indexation: 0.6766370142,
      },
      {
        name: "Anne Arundel",
        orientation: "Neutral",
        painRate: 301.05,
        indexation: 1.505250485,
      },
      {
        name: "Baltimore",
        orientation: "Neutral",
        painRate: 230.61,
        indexation: 1.153073373,
      },
      {
        name: "Baltimore City",
        orientation: "Plaintiff",
        painRate: 146.44,
        indexation: 0.7321918266,
      },
      {
        name: "Calvert",
        orientation: "Neutral",
        painRate: 315.99,
        indexation: 1.579961207,
      },
      {
        name: "Caroline",
        orientation: "Defense",
        painRate: 176.53,
        indexation: 0.8826299244,
      },
      {
        name: "Carroll",
        orientation: "Defense",
        painRate: 281.17,
        indexation: 1.405872517,
      },
      {
        name: "Cecil",
        orientation: "Defense",
        painRate: 226.11,
        indexation: 1.130533075,
      },
      {
        name: "Charles",
        orientation: "Neutral",
        painRate: 302.14,
        indexation: 1.510694937,
      },
      {
        name: "Dorchester",
        orientation: "Defense",
        painRate: 145.02,
        indexation: 0.7251153769,
      },
      {
        name: "Frederick",
        orientation: "Defense",
        painRate: 312.48,
        indexation: 1.562383787,
      },
      {
        name: "Garrett",
        orientation: "Defense",
        painRate: 158.06,
        indexation: 0.7903150291,
      },
      {
        name: "Harford",
        orientation: "Defense",
        painRate: 268.43,
        indexation: 1.342171092,
      },
      {
        name: "Howard",
        orientation: "Neutral",
        painRate: 356.01,
        indexation: 1.780054846,
      },
      {
        name: "Kent",
        orientation: "Defense",
        painRate: 186.46,
        indexation: 0.9323122199,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 315.75,
        indexation: 1.578757274,
      },
      {
        name: "Prince George's",
        orientation: "Plaintiff",
        painRate: 251.04,
        indexation: 1.25520701,
      },
      {
        name: "Queen Anne's",
        orientation: "Defense",
        painRate: 292.79,
        indexation: 1.463928834,
      },
      {
        name: "St. Mary's",
        orientation: "Defense",
        painRate: 300.06,
        indexation: 1.500287606,
      },
      {
        name: "Somerset",
        orientation: "Defense",
        painRate: 143.38,
        indexation: 0.7168751254,
      },
      {
        name: "Talbot",
        orientation: "Defense",
        painRate: 204.89,
        indexation: 1.024453214,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 185.33,
        indexation: 0.9266403585,
      },
      {
        name: "Wicomico",
        orientation: "Defense",
        painRate: 184.2,
        indexation: 0.9210220052,
      },
      {
        name: "Worcester",
        orientation: "Defense",
        painRate: 203.25,
        indexation: 1.016239716,
      },
    ],
  },
  ME: {
    name: "ME",
    counties: [
      {
        name: "Maine ",
        orientation: "Orientation",
        painRate: 185.9,
        indexation: 0.9295030433,
      },
      {
        name: "Androscoggin",
        orientation: "Plaintiff",
        painRate: 168.14,
        indexation: 0.8407196843,
      },
      {
        name: "Aroostook",
        orientation: "Neutral",
        painRate: 137.95,
        indexation: 0.6897465053,
      },
      {
        name: "Cumberland",
        orientation: "Defense",
        painRate: 236.96,
        indexation: 1.184817069,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 139.55,
        indexation: 0.6977325931,
      },
      {
        name: "Hancock",
        orientation: "Neutral",
        painRate: 170.9,
        indexation: 0.8545114039,
      },
      {
        name: "Kennebec",
        orientation: "Defense",
        painRate: 169.06,
        indexation: 0.8453080062,
      },
      {
        name: "Knox",
        orientation: "Neutral",
        painRate: 175.55,
        indexation: 0.8777339308,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 194.39,
        indexation: 0.9719349876,
      },
      {
        name: "Oxford",
        orientation: "Neutral",
        painRate: 157.43,
        indexation: 0.7871312956,
      },
      {
        name: "Penobscot",
        orientation: "Neutral",
        painRate: 162.56,
        indexation: 0.8128018193,
      },
      {
        name: "Piscataquis",
        orientation: "Defense",
        painRate: 140.54,
        indexation: 0.7026820948,
      },
      {
        name: "Sagadahoc",
        orientation: "Neutral",
        painRate: 196.88,
        indexation: 0.984375627,
      },
      {
        name: "Somerset",
        orientation: "Neutral",
        painRate: 147.4,
        indexation: 0.736980804,
      },
      {
        name: "Waldo",
        orientation: "Neutral",
        painRate: 170.21,
        indexation: 0.8510333757,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 148.17,
        indexation: 0.7408333891,
      },
      {
        name: "York",
        orientation: "Plaintiff",
        painRate: 217.55,
        indexation: 1.087753328,
      },
    ],
  },
  MI: {
    name: "MI",
    counties: [
      {
        name: "Michigan ",
        orientation: "Orientation",
        painRate: 179.05,
        indexation: 0.8952712193,
      },
      {
        name: "Alcona",
        orientation: "Defense",
        painRate: 143.9,
        indexation: 0.7195237777,
      },
      {
        name: "Alger",
        orientation: "Defense",
        painRate: 158.89,
        indexation: 0.7944619089,
      },
      {
        name: "Allegan",
        orientation: "Defense",
        painRate: 203.04,
        indexation: 1.015223062,
      },
      {
        name: "Alpena",
        orientation: "Defense",
        painRate: 144.9,
        indexation: 0.7245134105,
      },
      {
        name: "Antrim",
        orientation: "Defense",
        painRate: 192.48,
        indexation: 0.9624105411,
      },
      {
        name: "Arenac",
        orientation: "Defense",
        painRate: 143.44,
        indexation: 0.7172095512,
      },
      {
        name: "Baraga",
        orientation: "Defense",
        painRate: 140.43,
        indexation: 0.7021737676,
      },
      {
        name: "Barry",
        orientation: "Defense",
        painRate: 190.42,
        indexation: 0.9520834727,
      },
      {
        name: "Bay",
        orientation: "Neutral",
        painRate: 150.86,
        indexation: 0.7543040599,
      },
      {
        name: "Benzie",
        orientation: "Defense",
        painRate: 176.16,
        indexation: 0.8808240251,
      },
      {
        name: "Berrien",
        orientation: "Neutral",
        painRate: 165.31,
        indexation: 0.8265667848,
      },
      {
        name: "Branch",
        orientation: "Defense",
        painRate: 170.25,
        indexation: 0.8512340312,
      },
      {
        name: "Calhoun",
        orientation: "Neutral",
        painRate: 159.59,
        indexation: 0.7979666912,
      },
      {
        name: "Cass",
        orientation: "Defense",
        painRate: 175.78,
        indexation: 0.8789111096,
      },
      {
        name: "Charlevoix",
        orientation: "Defense",
        painRate: 190.92,
        indexation: 0.9546117317,
      },
      {
        name: "Cheboygan",
        orientation: "Defense",
        painRate: 162.8,
        indexation: 0.8139789981,
      },
      {
        name: "Chippewa",
        orientation: "Neutral",
        painRate: 157.65,
        indexation: 0.7882282122,
      },
      {
        name: "Clare",
        orientation: "Neutral",
        painRate: 128.35,
        indexation: 0.6417363387,
      },
      {
        name: "Clinton",
        orientation: "Neutral",
        painRate: 214.6,
        indexation: 1.072998462,
      },
      {
        name: "Crawford",
        orientation: "Neutral",
        painRate: 154.1,
        indexation: 0.7705170223,
      },
      {
        name: "Delta",
        orientation: "Neutral",
        painRate: 152.55,
        indexation: 0.7627449669,
      },
      {
        name: "Dickinson",
        orientation: "Plaintiff",
        painRate: 150.76,
        indexation: 0.7537823557,
      },
      {
        name: "Eaton",
        orientation: "Neutral",
        painRate: 191.29,
        indexation: 0.956444385,
      },
      {
        name: "Emmet",
        orientation: "Defense",
        painRate: 186.76,
        indexation: 0.9337836934,
      },
      {
        name: "Genesee",
        orientation: "Plaintiff",
        painRate: 153.68,
        indexation: 0.7684168283,
      },
      {
        name: "Gladwin",
        orientation: "Defense",
        painRate: 147.19,
        indexation: 0.7359641496,
      },
      {
        name: "Gogebic",
        orientation: "Defense",
        painRate: 134.15,
        indexation: 0.6707511203,
      },
      {
        name: "Grand Traverse",
        orientation: "Neutral",
        painRate: 199.98,
        indexation: 0.9998796067,
      },
      {
        name: "Gratiot",
        orientation: "Neutral",
        painRate: 155.37,
        indexation: 0.7768577353,
      },
      {
        name: "Hillsdale",
        orientation: "Defense",
        painRate: 160.71,
        indexation: 0.8035582904,
      },
      {
        name: "Houghton",
        orientation: "Neutral",
        painRate: 148.81,
        indexation: 0.7440706307,
      },
      {
        name: "Huron",
        orientation: "Defense",
        painRate: 134.24,
        indexation: 0.6712193164,
      },
      {
        name: "Ingham",
        orientation: "Plaintiff",
        painRate: 162.05,
        indexation: 0.8102601833,
      },
      {
        name: "Ionia",
        orientation: "Neutral",
        painRate: 199.03,
        indexation: 0.9951307605,
      },
      {
        name: "Iosco",
        orientation: "Defense",
        painRate: 123.41,
        indexation: 0.6170690924,
      },
      {
        name: "Iron",
        orientation: "Neutral",
        painRate: 144.87,
        indexation: 0.7243528861,
      },
      {
        name: "Isabella",
        orientation: "Neutral",
        painRate: 146.19,
        indexation: 0.7309477627,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 164,
        indexation: 0.8199986623,
      },
      {
        name: "Kalamazoo",
        orientation: "Neutral",
        painRate: 186.45,
        indexation: 0.9322319577,
      },
      {
        name: "Kalkaska",
        orientation: "Defense",
        painRate: 158.28,
        indexation: 0.7913851916,
      },
      {
        name: "Kent",
        orientation: "Neutral",
        painRate: 205.8,
        indexation: 1.029001405,
      },
      {
        name: "Keweenaw",
        orientation: "Defense",
        painRate: 152.77,
        indexation: 0.7638686375,
      },
      {
        name: "Lake",
        orientation: "Defense",
        painRate: 129.88,
        indexation: 0.6493880008,
      },
      {
        name: "Lapeer",
        orientation: "Neutral",
        painRate: 194.6,
        indexation: 0.9730051502,
      },
      {
        name: "Leelanau",
        orientation: "Neutral",
        painRate: 238.13,
        indexation: 1.190649455,
      },
      {
        name: "Lenawee",
        orientation: "Defense",
        painRate: 180.55,
        indexation: 0.902762357,
      },
      {
        name: "Livingston",
        orientation: "Neutral",
        painRate: 270.82,
        indexation: 1.354116781,
      },
      {
        name: "Luce",
        orientation: "Neutral",
        painRate: 132.09,
        indexation: 0.6604374289,
      },
      {
        name: "Mackinac",
        orientation: "Neutral",
        painRate: 152.34,
        indexation: 0.7617149355,
      },
      {
        name: "Macomb",
        orientation: "Neutral",
        painRate: 197.78,
        indexation: 0.9889238178,
      },
      {
        name: "Manistee",
        orientation: "Defense",
        painRate: 149.26,
        indexation: 0.7462778409,
      },
      {
        name: "Marquette",
        orientation: "Neutral",
        painRate: 164.8,
        indexation: 0.8239983948,
      },
      {
        name: "Mason",
        orientation: "Defense",
        painRate: 171.26,
        indexation: 0.8563173032,
      },
      {
        name: "Mecosta",
        orientation: "Neutral",
        painRate: 150.08,
        indexation: 0.7503979667,
      },
      {
        name: "Menominee",
        orientation: "Defense",
        painRate: 135.28,
        indexation: 0.6763962277,
      },
      {
        name: "Midland",
        orientation: "Neutral",
        painRate: 207.6,
        indexation: 1.03799077,
      },
      {
        name: "Missaukee",
        orientation: "Defense",
        painRate: 158.46,
        indexation: 0.7923215838,
      },
      {
        name: "Monroe",
        orientation: "Neutral",
        painRate: 193.07,
        indexation: 0.9653668651,
      },
      {
        name: "Montcalm",
        orientation: "Defense",
        painRate: 164.78,
        indexation: 0.8238913785,
      },
      {
        name: "Montmorency",
        orientation: "Defense",
        painRate: 135.59,
        indexation: 0.6779479633,
      },
      {
        name: "Muskegon",
        orientation: "Neutral",
        painRate: 155.98,
        indexation: 0.7799210755,
      },
      {
        name: "Newaygo",
        orientation: "Defense",
        painRate: 155.15,
        indexation: 0.7757608187,
      },
      {
        name: "Oakland",
        orientation: "Plaintiff",
        painRate: 242.93,
        indexation: 1.21464785,
      },
      {
        name: "Oceana",
        orientation: "Defense",
        painRate: 152.1,
        indexation: 0.7605243796,
      },
      {
        name: "Ogemaw",
        orientation: "Defense",
        painRate: 143.08,
        indexation: 0.7154036519,
      },
      {
        name: "Ontonagon",
        orientation: "Defense",
        painRate: 122.25,
        indexation: 0.6112500836,
      },
      {
        name: "Osceola",
        orientation: "Defense",
        painRate: 146.42,
        indexation: 0.7320848104,
      },
      {
        name: "Oscoda",
        orientation: "Defense",
        painRate: 124.74,
        indexation: 0.6237174771,
      },
      {
        name: "Otsego",
        orientation: "Defense",
        painRate: 176.35,
        indexation: 0.8817470403,
      },
      {
        name: "Ottawa",
        orientation: "Defense",
        painRate: 230,
        indexation: 1.149996656,
      },
      {
        name: "Presque Isle",
        orientation: "Defense",
        painRate: 150.83,
        indexation: 0.7541301585,
      },
      {
        name: "Roscommon",
        orientation: "Defense",
        painRate: 131.5,
        indexation: 0.657494482,
      },
      {
        name: "Saginaw",
        orientation: "Neutral",
        painRate: 144.64,
        indexation: 0.7232158384,
      },
      {
        name: "St. Clair",
        orientation: "Neutral",
        painRate: 173.62,
        indexation: 0.868089091,
      },
      {
        name: "St. Joseph",
        orientation: "Defense",
        painRate: 161.83,
        indexation: 0.8091632667,
      },
      {
        name: "Sanilac",
        orientation: "Neutral",
        painRate: 150.03,
        indexation: 0.7501705572,
      },
      {
        name: "Schoolcraft",
        orientation: "Defense",
        painRate: 151.71,
        indexation: 0.758571333,
      },
      {
        name: "Shiawassee",
        orientation: "Defense",
        painRate: 165.77,
        indexation: 0.8288275032,
      },
      {
        name: "Tuscola",
        orientation: "Neutral",
        painRate: 165.82,
        indexation: 0.8290816668,
      },
      {
        name: "Van Buren",
        orientation: "Defense",
        painRate: 164.91,
        indexation: 0.824533476,
      },
      {
        name: "Washtenaw",
        orientation: "Plaintiff",
        painRate: 216.55,
        indexation: 1.082750318,
      },
      {
        name: "Wayne",
        orientation: "Plaintiff",
        painRate: 149.63,
        indexation: 0.7481506254,
      },
      {
        name: "Wexford",
        orientation: "Defense",
        painRate: 150.84,
        indexation: 0.7541836666,
      },
    ],
  },
  MN: {
    name: "MN",
    counties: [
      {
        name: "Minneosta ",
        orientation: "Orientation",
        painRate: 220.3,
        indexation: 1.101504916,
      },
      {
        name: "Aitkin",
        orientation: "Neutral",
        painRate: 158.02,
        indexation: 0.7901009966,
      },
      {
        name: "Anoka",
        orientation: "Neutral",
        painRate: 246.8,
        indexation: 1.234017791,
      },
      {
        name: "Becker",
        orientation: "Neutral",
        painRate: 180.45,
        indexation: 0.9022540298,
      },
      {
        name: "Beltrami",
        orientation: "Plaintiff",
        painRate: 160.21,
        indexation: 0.8010434085,
      },
      {
        name: "Benton",
        orientation: "Neutral",
        painRate: 191.27,
        indexation: 0.9563641228,
      },
      {
        name: "Big Stone",
        orientation: "Neutral",
        painRate: 154.69,
        indexation: 0.7734733463,
      },
      {
        name: "Blue Earth",
        orientation: "Neutral",
        painRate: 182.67,
        indexation: 0.9133703431,
      },
      {
        name: "Brown",
        orientation: "Neutral",
        painRate: 171.47,
        indexation: 0.8573740887,
      },
      {
        name: "Carlton",
        orientation: "Neutral",
        painRate: 209.96,
        indexation: 1.049802689,
      },
      {
        name: "Carver",
        orientation: "Neutral",
        painRate: 314.63,
        indexation: 1.573165675,
      },
      {
        name: "Cass",
        orientation: "Plaintiff",
        painRate: 179.58,
        indexation: 0.8978797405,
      },
      {
        name: "Chippewa",
        orientation: "Neutral",
        painRate: 165.55,
        indexation: 0.8277439636,
      },
      {
        name: "Chisago",
        orientation: "Neutral",
        painRate: 262.68,
        indexation: 1.313397097,
      },
      {
        name: "Clay",
        orientation: "Neutral",
        painRate: 212.31,
        indexation: 1.0615611,
      },
      {
        name: "Clearwater",
        orientation: "Neutral",
        painRate: 173.14,
        indexation: 0.8657079794,
      },
      {
        name: "Cook",
        orientation: "Plaintiff",
        painRate: 185.35,
        indexation: 0.9267473748,
      },
      {
        name: "Cottonwood",
        orientation: "Neutral",
        painRate: 160.47,
        indexation: 0.8023677346,
      },
      {
        name: "Crow Wing",
        orientation: "Neutral",
        painRate: 180.25,
        indexation: 0.9012507525,
      },
      {
        name: "Dakota",
        orientation: "Plaintiff",
        painRate: 261.46,
        indexation: 1.307297171,
      },
      {
        name: "Dodge",
        orientation: "Neutral",
        painRate: 238.1,
        indexation: 1.190475553,
      },
      {
        name: "Douglas",
        orientation: "Neutral",
        painRate: 196.7,
        indexation: 0.983492743,
      },
      {
        name: "Faribault",
        orientation: "Neutral",
        painRate: 180.06,
        indexation: 0.9003009832,
      },
      {
        name: "Fillmore",
        orientation: "Neutral",
        painRate: 192.8,
        indexation: 0.9640157849,
      },
      {
        name: "Freeborn",
        orientation: "Neutral",
        painRate: 175.59,
        indexation: 0.8779613404,
      },
      {
        name: "Goodhue",
        orientation: "Neutral",
        painRate: 219.63,
        indexation: 1.098133904,
      },
      {
        name: "Grant",
        orientation: "Neutral",
        painRate: 182.95,
        indexation: 0.9147348003,
      },
      {
        name: "Hennepin",
        orientation: "Plaintiff",
        painRate: 239.23,
        indexation: 1.196147415,
      },
      {
        name: "Houston",
        orientation: "Neutral",
        painRate: 203.79,
        indexation: 1.0189285,
      },
      {
        name: "Hubbard",
        orientation: "Neutral",
        painRate: 181.38,
        indexation: 0.9069226139,
      },
      {
        name: "Isanti",
        orientation: "Neutral",
        painRate: 222.63,
        indexation: 1.113129557,
      },
      {
        name: "Itasca",
        orientation: "Plaintiff",
        painRate: 179.14,
        indexation: 0.8956992843,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 178.43,
        indexation: 0.892167748,
      },
      {
        name: "Kanabec",
        orientation: "Neutral",
        painRate: 186.18,
        indexation: 0.9308808775,
      },
      {
        name: "Kandiyohi",
        orientation: "Neutral",
        painRate: 193.38,
        indexation: 0.9669052237,
      },
      {
        name: "Kittson",
        orientation: "Plaintiff",
        painRate: 174.55,
        indexation: 0.8727710521,
      },
      {
        name: "Koochiching",
        orientation: "Plaintiff",
        painRate: 168.64,
        indexation: 0.8432211892,
      },
      {
        name: "Lac Qui Parle",
        orientation: "Neutral",
        painRate: 180.74,
        indexation: 0.9036987492,
      },
      {
        name: "Lake",
        orientation: "Plaintiff",
        painRate: 197.24,
        indexation: 0.9862082804,
      },
      {
        name: "Lake Of The Wood",
        orientation: "Neutral",
        painRate: 166.26,
        indexation: 0.831288877,
      },
      {
        name: "Le Sueur",
        orientation: "Neutral",
        painRate: 232.4,
        indexation: 1.161982476,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 173.53,
        indexation: 0.867634272,
      },
      {
        name: "Lyon",
        orientation: "Neutral",
        painRate: 189.84,
        indexation: 0.9492074109,
      },
      {
        name: "Mahnomen",
        orientation: "Neutral",
        painRate: 216.37,
        indexation: 1.081854057,
      },
      {
        name: "Marshall",
        orientation: "Neutral",
        painRate: 141.6,
        indexation: 0.7079927764,
      },
      {
        name: "Martin",
        orientation: "Neutral",
        painRate: 188.9,
        indexation: 0.9444986957,
      },
      {
        name: "McLeod",
        orientation: "Neutral",
        painRate: 165.13,
        indexation: 0.8256437696,
      },
      {
        name: "Meeker",
        orientation: "Neutral",
        painRate: 204.68,
        indexation: 1.023423182,
      },
      {
        name: "Mille Lacs",
        orientation: "Neutral",
        painRate: 174.69,
        indexation: 0.8734399037,
      },
      {
        name: "Morrison",
        orientation: "Neutral",
        painRate: 172.8,
        indexation: 0.8639957193,
      },
      {
        name: "Mower",
        orientation: "Neutral",
        painRate: 183.85,
        indexation: 0.91924286,
      },
      {
        name: "Murray",
        orientation: "Neutral",
        painRate: 183.71,
        indexation: 0.9185338773,
      },
      {
        name: "Nicollet",
        orientation: "Neutral",
        painRate: 208.93,
        indexation: 1.044625778,
      },
      {
        name: "Nobles",
        orientation: "Neutral",
        painRate: 174.9,
        indexation: 0.8745100662,
      },
      {
        name: "Norman",
        orientation: "Neutral",
        painRate: 172.08,
        indexation: 0.8603972978,
      },
      {
        name: "Olmsted",
        orientation: "Defense",
        painRate: 235.52,
        indexation: 1.177606849,
      },
      {
        name: "Otter Tail",
        orientation: "Neutral",
        painRate: 174.1,
        indexation: 0.8705237108,
      },
      {
        name: "Pennington",
        orientation: "Neutral",
        painRate: 204.46,
        indexation: 1.022312889,
      },
      {
        name: "Pine",
        orientation: "Neutral",
        painRate: 174.27,
        indexation: 0.8713664638,
      },
      {
        name: "Pipestone",
        orientation: "Neutral",
        painRate: 175.24,
        indexation: 0.8761821952,
      },
      {
        name: "Polk",
        orientation: "Neutral",
        painRate: 186.04,
        indexation: 0.930212026,
      },
      {
        name: "Pope",
        orientation: "Neutral",
        painRate: 184.3,
        indexation: 0.9215169554,
      },
      {
        name: "Ramsey",
        orientation: "Neutral",
        painRate: 200.77,
        indexation: 1.003825831,
      },
      {
        name: "Red Lake",
        orientation: "Neutral",
        painRate: 198.3,
        indexation: 0.9915055849,
      },
      {
        name: "Redwood",
        orientation: "Neutral",
        painRate: 178.13,
        indexation: 0.8906695204,
      },
      {
        name: "Renville",
        orientation: "Neutral",
        painRate: 178.1,
        indexation: 0.890482242,
      },
      {
        name: "Rice",
        orientation: "Neutral",
        painRate: 208.58,
        indexation: 1.04290014,
      },
      {
        name: "Rock",
        orientation: "Neutral",
        painRate: 186.84,
        indexation: 0.9341850043,
      },
      {
        name: "Roseau",
        orientation: "Neutral",
        painRate: 192.08,
        indexation: 0.9603906093,
      },
      {
        name: "St. Louis",
        orientation: "Neutral",
        painRate: 168.93,
        indexation: 0.8446391546,
      },
      {
        name: "Scott",
        orientation: "Neutral",
        painRate: 309.51,
        indexation: 1.547548659,
      },
      {
        name: "Sherburne",
        orientation: "Neutral",
        painRate: 262.19,
        indexation: 1.310935723,
      },
      {
        name: "Sibley",
        orientation: "Neutral",
        painRate: 202.25,
        indexation: 1.011250084,
      },
      {
        name: "Stearns",
        orientation: "Neutral",
        painRate: 193.64,
        indexation: 0.9682027958,
      },
      {
        name: "Steele",
        orientation: "Neutral",
        painRate: 201.92,
        indexation: 1.009618086,
      },
      {
        name: "Stevens",
        orientation: "Neutral",
        painRate: 185.06,
        indexation: 0.9253026553,
      },
      {
        name: "Swift",
        orientation: "Neutral",
        painRate: 179.7,
        indexation: 0.8984817069,
      },
      {
        name: "Todd",
        orientation: "Neutral",
        painRate: 177.38,
        indexation: 0.8868971975,
      },
      {
        name: "Traverse",
        orientation: "Neutral",
        painRate: 170.99,
        indexation: 0.854966223,
      },
      {
        name: "Wabasha",
        orientation: "Neutral",
        painRate: 211.23,
        indexation: 1.056156779,
      },
      {
        name: "Wadena",
        orientation: "Neutral",
        painRate: 153.91,
        indexation: 0.769540499,
      },
      {
        name: "Waseca",
        orientation: "Neutral",
        painRate: 191.34,
        indexation: 0.9567119256,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 288,
        indexation: 1.440024079,
      },
      {
        name: "Watonwan",
        orientation: "Neutral",
        painRate: 163.99,
        indexation: 0.8199451542,
      },
      {
        name: "Wilkin",
        orientation: "Neutral",
        painRate: 192.43,
        indexation: 0.9621296234,
      },
      {
        name: "Winona",
        orientation: "Neutral",
        painRate: 175.77,
        indexation: 0.8788576015,
      },
      {
        name: "Wright",
        orientation: "Neutral",
        painRate: 254.86,
        indexation: 1.274282657,
      },
      {
        name: "Yellow Medicine",
        orientation: "Neutral",
        painRate: 180.7,
        indexation: 0.9035248478,
      },
    ],
  },
  MO: {
    name: "MO",
    counties: [
      {
        name: "Missouri",
        orientation: "Orientation",
        painRate: 173.32,
        indexation: 0.8666042405,
      },
      {
        name: "Adair",
        orientation: "Defense",
        painRate: 124.23,
        indexation: 0.62113571,
      },
      {
        name: "Andrew",
        orientation: "Defense",
        painRate: 194.31,
        indexation: 0.9715738078,
      },
      {
        name: "Atchison",
        orientation: "Defense",
        painRate: 154.86,
        indexation: 0.7743027222,
      },
      {
        name: "Audrain",
        orientation: "Defense",
        painRate: 150.64,
        indexation: 0.7531937663,
      },
      {
        name: "Barry",
        orientation: "Defense",
        painRate: 133.68,
        indexation: 0.6684235168,
      },
      {
        name: "Barton",
        orientation: "Defense",
        painRate: 133.39,
        indexation: 0.6669386663,
      },
      {
        name: "Bates",
        orientation: "Defense",
        painRate: 153.28,
        indexation: 0.7663835195,
      },
      {
        name: "Benton",
        orientation: "Defense",
        painRate: 137.71,
        indexation: 0.6885559494,
      },
      {
        name: "Bollinger",
        orientation: "Defense",
        painRate: 144.29,
        indexation: 0.7214500702,
      },
      {
        name: "Boone",
        orientation: "Neutral",
        painRate: 171.18,
        indexation: 0.8558758611,
      },
      {
        name: "Buchanan",
        orientation: "Neutral",
        painRate: 151.83,
        indexation: 0.7591599224,
      },
      {
        name: "Butler",
        orientation: "Defense",
        painRate: 120,
        indexation: 0.6,
      },
      {
        name: "Caldwell",
        orientation: "Defense",
        painRate: 151.59,
        indexation: 0.7579426125,
      },
      {
        name: "Callaway",
        orientation: "Defense",
        painRate: 193.69,
        indexation: 0.9684703364,
      },
      {
        name: "Camden",
        orientation: "Neutral",
        painRate: 170.43,
        indexation: 0.8521570464,
      },
      {
        name: "Cape Girardeau",
        orientation: "Neutral",
        painRate: 171.54,
        indexation: 0.8577085145,
      },
      {
        name: "Carroll",
        orientation: "Defense",
        painRate: 152.01,
        indexation: 0.7600561835,
      },
      {
        name: "Carter",
        orientation: "Defense",
        painRate: 114.79,
        indexation: 0.5739682964,
      },
      {
        name: "Cass",
        orientation: "Defense",
        painRate: 208.16,
        indexation: 1.040799946,
      },
      {
        name: "Cedar",
        orientation: "Defense",
        painRate: 122.94,
        indexation: 0.6147013578,
      },
      {
        name: "Chariton",
        orientation: "Defense",
        painRate: 151.52,
        indexation: 0.7575814327,
      },
      {
        name: "Christian",
        orientation: "Neutral",
        painRate: 196.95,
        indexation: 0.984763561,
      },
      {
        name: "Clark",
        orientation: "Defense",
        painRate: 169.22,
        indexation: 0.846083874,
      },
      {
        name: "Clay",
        orientation: "Neutral",
        painRate: 211.17,
        indexation: 1.055849107,
      },
      {
        name: "Clinton",
        orientation: "Defense",
        painRate: 182.83,
        indexation: 0.914146211,
      },
      {
        name: "Cole",
        orientation: "Neutral",
        painRate: 189.52,
        indexation: 0.94758879,
      },
      {
        name: "Cooper",
        orientation: "Defense",
        painRate: 169.21,
        indexation: 0.8460571199,
      },
      {
        name: "Crawford",
        orientation: "Defense",
        painRate: 140.56,
        indexation: 0.7027757341,
      },
      {
        name: "Dade",
        orientation: "Defense",
        painRate: 132.13,
        indexation: 0.6606380844,
      },
      {
        name: "Dallas",
        orientation: "Defense",
        painRate: 133.32,
        indexation: 0.6665774865,
      },
      {
        name: "Daviess",
        orientation: "Defense",
        painRate: 143.51,
        indexation: 0.7175306,
      },
      {
        name: "De Kalb",
        orientation: "Defense",
        painRate: 178.93,
        indexation: 0.8946558759,
      },
      {
        name: "Dent",
        orientation: "Defense",
        painRate: 141.65,
        indexation: 0.70824694,
      },
      {
        name: "Douglas",
        orientation: "Defense",
        painRate: 116.9,
        indexation: 0.5844960203,
      },
      {
        name: "Dunklin",
        orientation: "Defense",
        painRate: 106.18,
        indexation: 0.5309076316,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 175.99,
        indexation: 0.879927764,
      },
      {
        name: "Gasconade",
        orientation: "Defense",
        painRate: 158.62,
        indexation: 0.7930840746,
      },
      {
        name: "Gentry",
        orientation: "Defense",
        painRate: 140.87,
        indexation: 0.7043408468,
      },
      {
        name: "Greene",
        orientation: "Neutral",
        painRate: 149.16,
        indexation: 0.7457828908,
      },
      {
        name: "Grundy",
        orientation: "Defense",
        painRate: 129.64,
        indexation: 0.648210822,
      },
      {
        name: "Harrison",
        orientation: "Defense",
        painRate: 138.88,
        indexation: 0.6944017123,
      },
      {
        name: "Henry",
        orientation: "Defense",
        painRate: 144.95,
        indexation: 0.724727443,
      },
      {
        name: "Hickory",
        orientation: "Defense",
        painRate: 116.29,
        indexation: 0.5814728112,
      },
      {
        name: "Holt",
        orientation: "Defense",
        painRate: 137.23,
        indexation: 0.6861748378,
      },
      {
        name: "Howard",
        orientation: "Defense",
        painRate: 144.1,
        indexation: 0.720513678,
      },
      {
        name: "Howell",
        orientation: "Defense",
        painRate: 120.01,
        indexation: 0.6000668852,
      },
      {
        name: "Iron",
        orientation: "Defense",
        painRate: 118.6,
        indexation: 0.5929904354,
      },
      {
        name: "Jackson",
        orientation: "Plaintiff",
        painRate: 167.24,
        indexation: 0.8361848706,
      },
      {
        name: "Jasper",
        orientation: "Neutral",
        painRate: 143.26,
        indexation: 0.7163132901,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 209.48,
        indexation: 1.047381446,
      },
      {
        name: "Johnson",
        orientation: "Neutral",
        painRate: 160.75,
        indexation: 0.8037723229,
      },
      {
        name: "Knox",
        orientation: "Defense",
        painRate: 132.57,
        indexation: 0.6628586717,
      },
      {
        name: "Laclede",
        orientation: "Defense",
        painRate: 142.4,
        indexation: 0.7119791318,
      },
      {
        name: "Lafayette",
        orientation: "Neutral",
        painRate: 188.5,
        indexation: 0.942505518,
      },
      {
        name: "Lawrence",
        orientation: "Defense",
        painRate: 155.26,
        indexation: 0.7762825229,
      },
      {
        name: "Lewis",
        orientation: "Defense",
        painRate: 152.64,
        indexation: 0.763199786,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 208.45,
        indexation: 1.042244666,
      },
      {
        name: "Linn",
        orientation: "Defense",
        painRate: 141.34,
        indexation: 0.7067219584,
      },
      {
        name: "Livingston",
        orientation: "Defense",
        painRate: 151.57,
        indexation: 0.7578623503,
      },
      {
        name: "Macon",
        orientation: "Defense",
        painRate: 134.04,
        indexation: 0.670189285,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 141.39,
        indexation: 0.7069359909,
      },
      {
        name: "Maries",
        orientation: "Defense",
        painRate: 125.93,
        indexation: 0.6296301251,
      },
      {
        name: "Marion",
        orientation: "Defense",
        painRate: 146.65,
        indexation: 0.7332619892,
      },
      {
        name: "McDonald",
        orientation: "Defense",
        painRate: 154.2,
        indexation: 0.7709852184,
      },
      {
        name: "Mercer",
        orientation: "Defense",
        painRate: 133.01,
        indexation: 0.6650391278,
      },
      {
        name: "Miller",
        orientation: "Defense",
        painRate: 165.1,
        indexation: 0.8255099993,
      },
      {
        name: "Mississippi",
        orientation: "Defense",
        painRate: 113.34,
        indexation: 0.5667045683,
      },
      {
        name: "Moniteau",
        orientation: "Defense",
        painRate: 172.61,
        indexation: 0.8630727042,
      },
      {
        name: "Monroe",
        orientation: "Defense",
        painRate: 134.51,
        indexation: 0.6725436426,
      },
      {
        name: "Montgomery",
        orientation: "Defense",
        painRate: 151.26,
        indexation: 0.7563106147,
      },
      {
        name: "Morgan",
        orientation: "Defense",
        painRate: 138.32,
        indexation: 0.6915925356,
      },
      {
        name: "New Madrid",
        orientation: "Defense",
        painRate: 128.85,
        indexation: 0.6442645977,
      },
      {
        name: "Newton",
        orientation: "Defense",
        painRate: 153.63,
        indexation: 0.7681492877,
      },
      {
        name: "Nodaway",
        orientation: "Defense",
        painRate: 153.85,
        indexation: 0.7692729583,
      },
      {
        name: "Oregon",
        orientation: "Defense",
        painRate: 121.12,
        indexation: 0.6056183533,
      },
      {
        name: "Osage",
        orientation: "Defense",
        painRate: 184.85,
        indexation: 0.9242726239,
      },
      {
        name: "Ozark",
        orientation: "Defense",
        painRate: 118.37,
        indexation: 0.5918266337,
      },
      {
        name: "Pemiscot",
        orientation: "Defense",
        painRate: 102.89,
        indexation: 0.5144271286,
      },
      {
        name: "Perry",
        orientation: "Defense",
        painRate: 168.89,
        indexation: 0.8444518761,
      },
      {
        name: "Pettis",
        orientation: "Defense",
        painRate: 157.39,
        indexation: 0.7869306401,
      },
      {
        name: "Phelps",
        orientation: "Defense",
        painRate: 146.45,
        indexation: 0.7322587118,
      },
      {
        name: "Pike",
        orientation: "Defense",
        painRate: 158.07,
        indexation: 0.7903284061,
      },
      {
        name: "Platte",
        orientation: "Plaintiff",
        painRate: 251.29,
        indexation: 1.256437696,
      },
      {
        name: "Polk",
        orientation: "Defense",
        painRate: 143.65,
        indexation: 0.7182529597,
      },
      {
        name: "Pulaski",
        orientation: "Neutral",
        painRate: 165.12,
        indexation: 0.8256036386,
      },
      {
        name: "Putnam",
        orientation: "Defense",
        painRate: 132.52,
        indexation: 0.662591131,
      },
      {
        name: "Ralls",
        orientation: "Defense",
        painRate: 173.94,
        indexation: 0.8697210889,
      },
      {
        name: "Randolph",
        orientation: "Defense",
        painRate: 130.46,
        indexation: 0.6522908167,
      },
      {
        name: "Ray",
        orientation: "Defense",
        painRate: 188.63,
        indexation: 0.9431609926,
      },
      {
        name: "Reynolds",
        orientation: "Defense",
        painRate: 118.81,
        indexation: 0.594060598,
      },
      {
        name: "Ripley",
        orientation: "Defense",
        painRate: 111.26,
        indexation: 0.5563106147,
      },
      {
        name: "St. Charles",
        orientation: "Neutral",
        painRate: 264.66,
        indexation: 1.323322855,
      },
      {
        name: "St. Clair",
        orientation: "Defense",
        painRate: 120.86,
        indexation: 0.6042940272,
      },
      {
        name: "Ste. Genevieve",
        orientation: "Defense",
        painRate: 170.65,
        indexation: 0.85326734,
      },
      {
        name: "St. Francois",
        orientation: "Defense",
        painRate: 129.96,
        indexation: 0.6497759347,
      },
      {
        name: "St. Louis",
        orientation: "Neutral",
        painRate: 212.99,
        indexation: 1.064932112,
      },
      {
        name: "St. Louis City",
        orientation: "Plaintiff",
        painRate: 146.14,
        indexation: 0.7306935991,
      },
      {
        name: "Saline",
        orientation: "Defense",
        painRate: 132.45,
        indexation: 0.6622700823,
      },
      {
        name: "Schuyler",
        orientation: "Defense",
        painRate: 147.04,
        indexation: 0.7352150358,
      },
      {
        name: "Scotland",
        orientation: "Defense",
        painRate: 154.31,
        indexation: 0.7715604307,
      },
      {
        name: "Scott",
        orientation: "Defense",
        painRate: 106.14,
        indexation: 0.5306802221,
      },
      {
        name: "Shannon",
        orientation: "Defense",
        painRate: 145.18,
        indexation: 0.7258912447,
      },
      {
        name: "Shelby",
        orientation: "Defense",
        painRate: 142.84,
        indexation: 0.7142130961,
      },
      {
        name: "Stoddard",
        orientation: "Defense",
        painRate: 150.94,
        indexation: 0.7547053709,
      },
      {
        name: "Stone",
        orientation: "Defense",
        painRate: 140.15,
        indexation: 0.7007424253,
      },
      {
        name: "Sullivan",
        orientation: "Defense",
        painRate: 141.82,
        indexation: 0.70910307,
      },
      {
        name: "Taney",
        orientation: "Defense",
        painRate: 124.73,
        indexation: 0.623663969,
      },
      {
        name: "Texas",
        orientation: "Defense",
        painRate: 138.85,
        indexation: 0.6942679419,
      },
      {
        name: "Vernon",
        orientation: "Defense",
        painRate: 198.93,
        indexation: 0.9946491873,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 127.45,
        indexation: 0.637228279,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 111.63,
        indexation: 0.558143268,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 159.95,
        indexation: 0.7997324594,
      },
      {
        name: "Webster",
        orientation: "Defense",
        painRate: 129.48,
        indexation: 0.6474082001,
      },
      {
        name: "Worth",
        orientation: "Defense",
        painRate: 120.44,
        indexation: 0.6021938332,
      },
      {
        name: "Wright",
        orientation: "Defense",
        painRate: 139.86,
        indexation: 0.6993244599,
      },
    ],
  },
  MS: {
    name: "MS",
    counties: [
      {
        name: "Mississippi",
        orientation: "Orientation",
        painRate: 141.23,
        indexation: 0.706146746,
      },
      {
        name: "Adams",
        orientation: "Neutral",
        painRate: 105.8,
        indexation: 0.5290214701,
      },
      {
        name: "Alcorn",
        orientation: "Defense",
        painRate: 132.77,
        indexation: 0.663861949,
      },
      {
        name: "Amite",
        orientation: "Neutral",
        painRate: 121.55,
        indexation: 0.6077586783,
      },
      {
        name: "Attala",
        orientation: "Neutral",
        painRate: 118.8,
        indexation: 0.5939937128,
      },
      {
        name: "Benton",
        orientation: "Neutral",
        painRate: 115.7,
        indexation: 0.5785164872,
      },
      {
        name: "Bolivar",
        orientation: "Plaintiff",
        painRate: 106.97,
        indexation: 0.534867233,
      },
      {
        name: "Calhoun",
        orientation: "Neutral",
        painRate: 118.18,
        indexation: 0.5908902415,
      },
      {
        name: "Carroll",
        orientation: "Neutral",
        painRate: 132.16,
        indexation: 0.6607852318,
      },
      {
        name: "Chickasaw",
        orientation: "Neutral",
        painRate: 117.12,
        indexation: 0.5855929369,
      },
      {
        name: "Choctaw",
        orientation: "Neutral",
        painRate: 131.7,
        indexation: 0.6584843823,
      },
      {
        name: "Claiborne",
        orientation: "Plaintiff",
        painRate: 95.22,
        indexation: 0.476101933,
      },
      {
        name: "Clarke",
        orientation: "Neutral",
        painRate: 124.47,
        indexation: 0.6223530199,
      },
      {
        name: "Clay",
        orientation: "Neutral",
        painRate: 116.25,
        indexation: 0.5812454016,
      },
      {
        name: "Coahoma",
        orientation: "Plaintiff",
        painRate: 96.5,
        indexation: 0.4825229082,
      },
      {
        name: "Copiah",
        orientation: "Neutral",
        painRate: 115.87,
        indexation: 0.5793324861,
      },
      {
        name: "Covington",
        orientation: "Neutral",
        painRate: 124.75,
        indexation: 0.6237709852,
      },
      {
        name: "De Soto",
        orientation: "Defense",
        painRate: 213.94,
        indexation: 1.069721089,
      },
      {
        name: "Forrest",
        orientation: "Neutral",
        painRate: 137.37,
        indexation: 0.6868303124,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 126.19,
        indexation: 0.6309276971,
      },
      {
        name: "George",
        orientation: "Neutral",
        painRate: 165.76,
        indexation: 0.8287873721,
      },
      {
        name: "Greene",
        orientation: "Defense",
        painRate: 140.69,
        indexation: 0.7034312086,
      },
      {
        name: "Grenada",
        orientation: "Neutral",
        painRate: 124.99,
        indexation: 0.624948164,
      },
      {
        name: "Hancock",
        orientation: "Defense",
        painRate: 160.19,
        indexation: 0.8009363922,
      },
      {
        name: "Harrison",
        orientation: "Neutral",
        painRate: 149.6,
        indexation: 0.747990101,
      },
      {
        name: "Hinds",
        orientation: "Plaintiff",
        painRate: 127.03,
        indexation: 0.6351280851,
      },
      {
        name: "Holmes",
        orientation: "Plaintiff",
        painRate: 85.54,
        indexation: 0.4276904555,
      },
      {
        name: "Humphreys",
        orientation: "Plaintiff",
        painRate: 88.68,
        indexation: 0.4434218447,
      },
      {
        name: "Issaquena",
        orientation: "Plaintiff",
        painRate: 98.78,
        indexation: 0.4939067621,
      },
      {
        name: "Itawamba",
        orientation: "Neutral",
        painRate: 151.12,
        indexation: 0.755588255,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 164.58,
        indexation: 0.8228881011,
      },
      {
        name: "Jasper",
        orientation: "Plaintiff",
        painRate: 119.88,
        indexation: 0.5994247876,
      },
      {
        name: "Jefferson",
        orientation: "Plaintiff",
        painRate: 90.57,
        indexation: 0.452839275,
      },
      {
        name: "Jefferson Davis",
        orientation: "Neutral",
        painRate: 98.03,
        indexation: 0.4901611932,
      },
      {
        name: "Jones",
        orientation: "Plaintiff",
        painRate: 155.17,
        indexation: 0.7758678349,
      },
      {
        name: "Kemper",
        orientation: "Neutral",
        painRate: 106.63,
        indexation: 0.5331549729,
      },
      {
        name: "Lafayette",
        orientation: "Defense",
        painRate: 165.17,
        indexation: 0.8258444251,
      },
      {
        name: "Lamar",
        orientation: "Neutral",
        painRate: 166.75,
        indexation: 0.8337368738,
      },
      {
        name: "Lauderdale",
        orientation: "Defense",
        painRate: 132.03,
        indexation: 0.6601698883,
      },
      {
        name: "Lawrence",
        orientation: "Neutral",
        painRate: 124.16,
        indexation: 0.6207879072,
      },
      {
        name: "Leake",
        orientation: "Neutral",
        painRate: 124.22,
        indexation: 0.6210822019,
      },
      {
        name: "Lee",
        orientation: "Defense",
        painRate: 155.89,
        indexation: 0.7794261253,
      },
      {
        name: "Leflore",
        orientation: "Plaintiff",
        painRate: 95.03,
        indexation: 0.4751521637,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 130.01,
        indexation: 0.6500702294,
      },
      {
        name: "Lowndes",
        orientation: "Neutral",
        painRate: 132.02,
        indexation: 0.6600762491,
      },
      {
        name: "Madison",
        orientation: "Neutral",
        painRate: 215.75,
        indexation: 1.078737208,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 118.06,
        indexation: 0.5903150291,
      },
      {
        name: "Marshall",
        orientation: "Neutral",
        painRate: 139.55,
        indexation: 0.6977727242,
      },
      {
        name: "Monroe",
        orientation: "Neutral",
        painRate: 138.46,
        indexation: 0.6923148953,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 112.89,
        indexation: 0.5644438499,
      },
      {
        name: "Neshoba",
        orientation: "Neutral",
        painRate: 134.66,
        indexation: 0.6732793793,
      },
      {
        name: "Newton",
        orientation: "Neutral",
        painRate: 126.6,
        indexation: 0.633001137,
      },
      {
        name: "Noxubee",
        orientation: "Plaintiff",
        painRate: 97.94,
        indexation: 0.4897063742,
      },
      {
        name: "Oktibbeha",
        orientation: "Neutral",
        painRate: 126.5,
        indexation: 0.6325195639,
      },
      {
        name: "Panola",
        orientation: "Neutral",
        painRate: 108.61,
        indexation: 0.5430405993,
      },
      {
        name: "Pearl River",
        orientation: "Defense",
        painRate: 149.37,
        indexation: 0.7468396763,
      },
      {
        name: "Perry",
        orientation: "Neutral",
        painRate: 120.58,
        indexation: 0.6029161929,
      },
      {
        name: "Pike",
        orientation: "Neutral",
        painRate: 109.54,
        indexation: 0.5477225604,
      },
      {
        name: "Pontotoc",
        orientation: "Defense",
        painRate: 136.68,
        indexation: 0.6833790382,
      },
      {
        name: "Prentiss",
        orientation: "Defense",
        painRate: 131.68,
        indexation: 0.6583773661,
      },
      {
        name: "Quitman",
        orientation: "Plaintiff",
        painRate: 107.7,
        indexation: 0.5384924085,
      },
      {
        name: "Rankin",
        orientation: "Defense",
        painRate: 195.05,
        indexation: 0.9752658685,
      },
      {
        name: "Scott",
        orientation: "Neutral",
        painRate: 122.44,
        indexation: 0.6121864758,
      },
      {
        name: "Sharkey",
        orientation: "Plaintiff",
        painRate: 97.32,
        indexation: 0.4865895258,
      },
      {
        name: "Simpson",
        orientation: "Neutral",
        painRate: 136.43,
        indexation: 0.6821483513,
      },
      {
        name: "Smith",
        orientation: "Plaintiff",
        painRate: 138.44,
        indexation: 0.6922078791,
      },
      {
        name: "Stone",
        orientation: "Neutral",
        painRate: 145.23,
        indexation: 0.7261454083,
      },
      {
        name: "Sunflower",
        orientation: "Plaintiff",
        painRate: 102.8,
        indexation: 0.5139856866,
      },
      {
        name: "Tallahatchie",
        orientation: "Plaintiff",
        painRate: 104.36,
        indexation: 0.5218246271,
      },
      {
        name: "Tate",
        orientation: "Defense",
        painRate: 156.84,
        indexation: 0.7842017256,
      },
      {
        name: "Tippah",
        orientation: "Defense",
        painRate: 140.82,
        indexation: 0.7041000602,
      },
      {
        name: "Tishomingo",
        orientation: "Defense",
        painRate: 138.19,
        indexation: 0.6909504381,
      },
      {
        name: "Tunica",
        orientation: "Plaintiff",
        painRate: 100.85,
        indexation: 0.5042338305,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 149.96,
        indexation: 0.7497826232,
      },
      {
        name: "Walthall",
        orientation: "Neutral",
        painRate: 109.23,
        indexation: 0.5461574477,
      },
      {
        name: "Warren",
        orientation: "Neutral",
        painRate: 139.43,
        indexation: 0.6971707578,
      },
      {
        name: "Washington",
        orientation: "Plaintiff",
        painRate: 106.19,
        indexation: 0.5309343857,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 116.55,
        indexation: 0.5827302522,
      },
      {
        name: "Webster",
        orientation: "Defense",
        painRate: 137.11,
        indexation: 0.6855594943,
      },
      {
        name: "Wilkinson",
        orientation: "Plaintiff",
        painRate: 98.45,
        indexation: 0.4922480102,
      },
      {
        name: "Winston",
        orientation: "Neutral",
        painRate: 133.99,
        indexation: 0.6699484984,
      },
      {
        name: "Yalobusha",
        orientation: "Neutral",
        painRate: 122.15,
        indexation: 0.6107685105,
      },
      {
        name: "Yazoo",
        orientation: "Plaintiff",
        painRate: 106,
        indexation: 0.5299979934,
      },
    ],
  },
  MT: {
    name: "MT",
    counties: [
      {
        name: "Montana ",
        orientation: "Orientation",
        painRate: 181.7,
        indexation: 0.9085011036,
      },
      {
        name: "Beaverhead",
        orientation: "Neutral",
        painRate: 153.83,
        indexation: 0.769139188,
      },
      {
        name: "Big Horn",
        orientation: "Defense",
        painRate: 131.4,
        indexation: 0.6569861548,
      },
      {
        name: "Blaine",
        orientation: "Defense",
        painRate: 143.34,
        indexation: 0.716701224,
      },
      {
        name: "Broadwater",
        orientation: "Neutral",
        painRate: 181.73,
        indexation: 0.908661628,
      },
      {
        name: "Carbon",
        orientation: "Defense",
        painRate: 179.42,
        indexation: 0.8970904956,
      },
      {
        name: "Carter",
        orientation: "Defense",
        painRate: 135.37,
        indexation: 0.6768376697,
      },
      {
        name: "Cascade",
        orientation: "Plaintiff",
        painRate: 158.34,
        indexation: 0.7917062404,
      },
      {
        name: "Chouteau",
        orientation: "Defense",
        painRate: 136.81,
        indexation: 0.6840612668,
      },
      {
        name: "Custer",
        orientation: "Defense",
        painRate: 173.21,
        indexation: 0.8660691593,
      },
      {
        name: "Daniels",
        orientation: "Defense",
        painRate: 152.88,
        indexation: 0.7643769648,
      },
      {
        name: "Dawson",
        orientation: "Defense",
        painRate: 162.57,
        indexation: 0.8128285733,
      },
      {
        name: "Deer Lodge",
        orientation: "Plaintiff",
        painRate: 141.39,
        indexation: 0.7069493679,
      },
      {
        name: "Fallon",
        orientation: "Defense",
        painRate: 175.61,
        indexation: 0.8780282255,
      },
      {
        name: "Fergus",
        orientation: "Neutral",
        painRate: 161.77,
        indexation: 0.8088555949,
      },
      {
        name: "Flathead",
        orientation: "Defense",
        painRate: 177.63,
        indexation: 0.8881680155,
      },
      {
        name: "Gallatin",
        orientation: "Neutral",
        painRate: 223.45,
        indexation: 1.117249682,
      },
      {
        name: "Garfield",
        orientation: "Defense",
        painRate: 135.81,
        indexation: 0.6790448799,
      },
      {
        name: "Glacier",
        orientation: "Defense",
        painRate: 123.79,
        indexation: 0.6189552538,
      },
      {
        name: "Golden Valley",
        orientation: "Defense",
        painRate: 122.06,
        indexation: 0.6102869373,
      },
      {
        name: "Granite",
        orientation: "Defense",
        painRate: 155.67,
        indexation: 0.7783559628,
      },
      {
        name: "Hill",
        orientation: "Neutral",
        painRate: 135.16,
        indexation: 0.6757808842,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 223.92,
        indexation: 1.119617417,
      },
      {
        name: "Judith Basin",
        orientation: "Defense",
        painRate: 162.34,
        indexation: 0.8116781486,
      },
      {
        name: "Lake",
        orientation: "Neutral",
        painRate: 153.1,
        indexation: 0.7655006354,
      },
      {
        name: "Lewis And Clark",
        orientation: "Neutral",
        painRate: 193.94,
        indexation: 0.9697144004,
      },
      {
        name: "Liberty",
        orientation: "Defense",
        painRate: 143.4,
        indexation: 0.7170222728,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 129.58,
        indexation: 0.6478763962,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 158.08,
        indexation: 0.7903952913,
      },
      {
        name: "McCone",
        orientation: "Defense",
        painRate: 193.93,
        indexation: 0.9696608922,
      },
      {
        name: "Meagher",
        orientation: "Defense",
        painRate: 143.73,
        indexation: 0.7186676476,
      },
      {
        name: "Mineral",
        orientation: "Defense",
        painRate: 149.77,
        indexation: 0.748832854,
      },
      {
        name: "Missoula",
        orientation: "Neutral",
        painRate: 182.49,
        indexation: 0.9124473279,
      },
      {
        name: "Musselshell",
        orientation: "Neutral",
        painRate: 147.93,
        indexation: 0.7396294562,
      },
      {
        name: "Park",
        orientation: "Neutral",
        painRate: 183.52,
        indexation: 0.9175841081,
      },
      {
        name: "Petroleum",
        orientation: "Defense",
        painRate: 131.07,
        indexation: 0.6553541569,
      },
      {
        name: "Phillips",
        orientation: "Defense",
        painRate: 149.94,
        indexation: 0.749688984,
      },
      {
        name: "Pondera",
        orientation: "Defense",
        painRate: 136.6,
        indexation: 0.6829911043,
      },
      {
        name: "Powder River",
        orientation: "Defense",
        painRate: 144.74,
        indexation: 0.7237241656,
      },
      {
        name: "Powell",
        orientation: "Neutral",
        painRate: 161.46,
        indexation: 0.8073038593,
      },
      {
        name: "Prairie",
        orientation: "Defense",
        painRate: 133.34,
        indexation: 0.6666845027,
      },
      {
        name: "Ravalli",
        orientation: "Defense",
        painRate: 176.01,
        indexation: 0.8800615343,
      },
      {
        name: "Richland",
        orientation: "Neutral",
        painRate: 195.24,
        indexation: 0.9761755067,
      },
      {
        name: "Roosevelt",
        orientation: "Defense",
        painRate: 130.32,
        indexation: 0.6516219651,
      },
      {
        name: "Rosebud",
        orientation: "Defense",
        painRate: 171.23,
        indexation: 0.8561701558,
      },
      {
        name: "Sanders",
        orientation: "Defense",
        painRate: 128.36,
        indexation: 0.6417764698,
      },
      {
        name: "Sheridan",
        orientation: "Defense",
        painRate: 168.5,
        indexation: 0.8425122065,
      },
      {
        name: "Silver Bow",
        orientation: "Plaintiff",
        painRate: 157.58,
        indexation: 0.7878804093,
      },
      {
        name: "Stillwater",
        orientation: "Neutral",
        painRate: 217.1,
        indexation: 1.085505986,
      },
      {
        name: "Sweet Grass",
        orientation: "Defense",
        painRate: 181.94,
        indexation: 0.9097184135,
      },
      {
        name: "Teton",
        orientation: "Defense",
        painRate: 152.64,
        indexation: 0.763213163,
      },
      {
        name: "Toole",
        orientation: "Defense",
        painRate: 133.37,
        indexation: 0.6668450271,
      },
      {
        name: "Treasure",
        orientation: "Defense",
        painRate: 161.74,
        indexation: 0.8086950706,
      },
      {
        name: "Valley",
        orientation: "Defense",
        painRate: 213.24,
        indexation: 1.066189553,
      },
      {
        name: "Wheatland",
        orientation: "Defense",
        painRate: 117.72,
        indexation: 0.5886161461,
      },
      {
        name: "Wibaux",
        orientation: "Defense",
        painRate: 150.67,
        indexation: 0.7533542907,
      },
      {
        name: "Yellowstone",
        orientation: "Neutral",
        painRate: 209.26,
        indexation: 1.046297906,
      },
      {
        name: "Yellowstone National Park",
        orientation: "Defense",
        painRate: "#N/A",
        indexation: "#N/A",
      },
    ],
  },
  NC: {
    name: "NC",
    counties: [
      {
        name: "North Carolina ",
        orientation: "Orientation",
        painRate: 180.63,
        indexation: 0.903163668,
      },
      {
        name: "Alamance",
        orientation: "Neutral",
        painRate: 163.29,
        indexation: 0.8164537489,
      },
      {
        name: "Alexander",
        orientation: "Neutral",
        painRate: 181.17,
        indexation: 0.9058524513,
      },
      {
        name: "Alleghany",
        orientation: "Neutral",
        painRate: 132.5,
        indexation: 0.6624841148,
      },
      {
        name: "Anson",
        orientation: "Plaintiff",
        painRate: 129.18,
        indexation: 0.6458965955,
      },
      {
        name: "Ashe",
        orientation: "Defense",
        painRate: 145.49,
        indexation: 0.7274296034,
      },
      {
        name: "Avery",
        orientation: "Neutral",
        painRate: 144.19,
        indexation: 0.720941743,
      },
      {
        name: "Beaufort",
        orientation: "Neutral",
        painRate: 145.58,
        indexation: 0.7279111765,
      },
      {
        name: "Bertie",
        orientation: "Plaintiff",
        painRate: 118.88,
        indexation: 0.5943950237,
      },
      {
        name: "Bladen",
        orientation: "Neutral",
        painRate: 123.07,
        indexation: 0.6153702094,
      },
      {
        name: "Brunswick",
        orientation: "Neutral",
        painRate: 196.74,
        indexation: 0.9836933984,
      },
      {
        name: "Buncombe",
        orientation: "Plaintiff",
        painRate: 181.68,
        indexation: 0.9083807103,
      },
      {
        name: "Burke",
        orientation: "Defense",
        painRate: 162.21,
        indexation: 0.8110360511,
      },
      {
        name: "Cabarrus",
        orientation: "Defense",
        painRate: 213.09,
        indexation: 1.065467193,
      },
      {
        name: "Caldwell",
        orientation: "Defense",
        painRate: 143.36,
        indexation: 0.7168216173,
      },
      {
        name: "Camden",
        orientation: "Neutral",
        painRate: 221.04,
        indexation: 1.105210354,
      },
      {
        name: "Carteret",
        orientation: "Plaintiff",
        painRate: 181.41,
        indexation: 0.9070430072,
      },
      {
        name: "Caswell",
        orientation: "Neutral",
        painRate: 145.05,
        indexation: 0.7252357702,
      },
      {
        name: "Catawba",
        orientation: "Neutral",
        painRate: 171.05,
        indexation: 0.8552471407,
      },
      {
        name: "Chatham",
        orientation: "Neutral",
        painRate: 226.11,
        indexation: 1.130573206,
      },
      {
        name: "Cherokee",
        orientation: "Defense",
        painRate: 138.07,
        indexation: 0.6903484717,
      },
      {
        name: "Chowan",
        orientation: "Neutral",
        painRate: 149.34,
        indexation: 0.7466791519,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 154.37,
        indexation: 0.7718279714,
      },
      {
        name: "Cleveland",
        orientation: "Neutral",
        painRate: 142.25,
        indexation: 0.7112567721,
      },
      {
        name: "Columbus",
        orientation: "Neutral",
        painRate: 121.41,
        indexation: 0.6070363186,
      },
      {
        name: "Craven",
        orientation: "Neutral",
        painRate: 168.75,
        indexation: 0.8437696475,
      },
      {
        name: "Cumberland",
        orientation: "Plaintiff",
        painRate: 155.21,
        indexation: 0.7760417363,
      },
      {
        name: "Currituck",
        orientation: "Plaintiff",
        painRate: 203.91,
        indexation: 1.01955722,
      },
      {
        name: "Dare",
        orientation: "Defense",
        painRate: 219.52,
        indexation: 1.0976122,
      },
      {
        name: "Davidson",
        orientation: "Defense",
        painRate: 170.13,
        indexation: 0.8506454418,
      },
      {
        name: "Davie",
        orientation: "Defense",
        painRate: 169.72,
        indexation: 0.8485853789,
      },
      {
        name: "Duplin",
        orientation: "Neutral",
        painRate: 137.17,
        indexation: 0.685840412,
      },
      {
        name: "Durham",
        orientation: "Plaintiff",
        painRate: 212.76,
        indexation: 1.063795064,
      },
      {
        name: "Edgecombe",
        orientation: "Plaintiff",
        painRate: 130.48,
        indexation: 0.6523978329,
      },
      {
        name: "Forsyth",
        orientation: "Neutral",
        painRate: 168.53,
        indexation: 0.8426459769,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 193.74,
        indexation: 0.968697746,
      },
      {
        name: "Gaston",
        orientation: "Neutral",
        painRate: 175.05,
        indexation: 0.87525918,
      },
      {
        name: "Gates",
        orientation: "Neutral",
        painRate: 178.08,
        indexation: 0.8903886028,
      },
      {
        name: "Graham",
        orientation: "Defense",
        painRate: 134.58,
        indexation: 0.6728780684,
      },
      {
        name: "Granville",
        orientation: "Neutral",
        painRate: 198.3,
        indexation: 0.9915055849,
      },
      {
        name: "Greene",
        orientation: "Neutral",
        painRate: 133.75,
        indexation: 0.6687445656,
      },
      {
        name: "Guilford",
        orientation: "Neutral",
        painRate: 170.75,
        indexation: 0.8537489131,
      },
      {
        name: "Halifax",
        orientation: "Neutral",
        painRate: 119.87,
        indexation: 0.5993445254,
      },
      {
        name: "Harnett",
        orientation: "Neutral",
        painRate: 174.55,
        indexation: 0.872744298,
      },
      {
        name: "Haywood",
        orientation: "Neutral",
        painRate: 166.88,
        indexation: 0.8343789713,
      },
      {
        name: "Henderson",
        orientation: "Neutral",
        painRate: 174.58,
        indexation: 0.8728780684,
      },
      {
        name: "Hertford",
        orientation: "Plaintiff",
        painRate: 117.37,
        indexation: 0.5868637549,
      },
      {
        name: "Hoke",
        orientation: "Plaintiff",
        painRate: 162.94,
        indexation: 0.8147013578,
      },
      {
        name: "Hyde",
        orientation: "Defense",
        painRate: 128.6,
        indexation: 0.6430071567,
      },
      {
        name: "Iredell",
        orientation: "Neutral",
        painRate: 196.5,
        indexation: 0.9824894656,
      },
      {
        name: "Jackson",
        orientation: "Defense",
        painRate: 156.49,
        indexation: 0.7824359575,
      },
      {
        name: "Johnston",
        orientation: "Defense",
        painRate: 215.67,
        indexation: 1.078349274,
      },
      {
        name: "Jones",
        orientation: "Neutral",
        painRate: 150.03,
        indexation: 0.7501705572,
      },
      {
        name: "Lee",
        orientation: "Defense",
        painRate: 155.83,
        indexation: 0.7791318306,
      },
      {
        name: "Lenoir",
        orientation: "Plaintiff",
        painRate: 124.17,
        indexation: 0.6208280383,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 210.33,
        indexation: 1.051635342,
      },
      {
        name: "McDowell",
        orientation: "Neutral",
        painRate: 144.58,
        indexation: 0.7229215437,
      },
      {
        name: "Macon",
        orientation: "Defense",
        painRate: 144.02,
        indexation: 0.7201123671,
      },
      {
        name: "Madison",
        orientation: "Neutral",
        painRate: 144.84,
        indexation: 0.7241923617,
      },
      {
        name: "Martin",
        orientation: "Neutral",
        painRate: 118.91,
        indexation: 0.5945421711,
      },
      {
        name: "Mecklenburg",
        orientation: "Neutral",
        painRate: 215.76,
        indexation: 1.078790716,
      },
      {
        name: "Mitchell",
        orientation: "Neutral",
        painRate: 143.28,
        indexation: 0.7164069293,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 147.98,
        indexation: 0.7399237509,
      },
      {
        name: "Moore",
        orientation: "Neutral",
        painRate: 225.96,
        indexation: 1.129797338,
      },
      {
        name: "Nash",
        orientation: "Defense",
        painRate: 148.23,
        indexation: 0.7411678149,
      },
      {
        name: "New Hanover",
        orientation: "Defense",
        painRate: 189.94,
        indexation: 0.949702361,
      },
      {
        name: "Northampton",
        orientation: "Neutral",
        painRate: 137.92,
        indexation: 0.6895859809,
      },
      {
        name: "Onslow",
        orientation: "Neutral",
        painRate: 166.38,
        indexation: 0.8319175975,
      },
      {
        name: "Orange",
        orientation: "Plaintiff",
        painRate: 234.85,
        indexation: 1.174235837,
      },
      {
        name: "Pamlico",
        orientation: "Neutral",
        painRate: 160.11,
        indexation: 0.8005484583,
      },
      {
        name: "Pasquotank",
        orientation: "Neutral",
        painRate: 158.03,
        indexation: 0.7901277507,
      },
      {
        name: "Pender",
        orientation: "Neutral",
        painRate: 197.08,
        indexation: 0.9853922815,
      },
      {
        name: "Perquimans",
        orientation: "Neutral",
        painRate: 162.3,
        indexation: 0.8115176242,
      },
      {
        name: "Person",
        orientation: "Neutral",
        painRate: 164.01,
        indexation: 0.8200387934,
      },
      {
        name: "Pitt",
        orientation: "Neutral",
        painRate: 153.35,
        indexation: 0.7667580764,
      },
      {
        name: "Polk",
        orientation: "Neutral",
        painRate: 164.76,
        indexation: 0.8238244933,
      },
      {
        name: "Randolph",
        orientation: "Defense",
        painRate: 155.27,
        indexation: 0.7763627851,
      },
      {
        name: "Richmond",
        orientation: "Plaintiff",
        painRate: 118.74,
        indexation: 0.5937127951,
      },
      {
        name: "Robeson",
        orientation: "Plaintiff",
        painRate: 106.83,
        indexation: 0.5341582503,
      },
      {
        name: "Rockingham",
        orientation: "Defense",
        painRate: 140.44,
        indexation: 0.7022138987,
      },
      {
        name: "Rowan",
        orientation: "Neutral",
        painRate: 163.48,
        indexation: 0.8174035182,
      },
      {
        name: "Rutherford",
        orientation: "Neutral",
        painRate: 143.09,
        indexation: 0.7154705371,
      },
      {
        name: "Sampson",
        orientation: "Defense",
        painRate: 139.78,
        indexation: 0.698923149,
      },
      {
        name: "Scotland",
        orientation: "Plaintiff",
        painRate: 123.06,
        indexation: 0.6152899472,
      },
      {
        name: "Stanly",
        orientation: "Neutral",
        painRate: 156.89,
        indexation: 0.7844558892,
      },
      {
        name: "Stokes",
        orientation: "Defense",
        painRate: 150.52,
        indexation: 0.7525784228,
      },
      {
        name: "Surry",
        orientation: "Defense",
        painRate: 132.5,
        indexation: 0.6624841148,
      },
      {
        name: "Swain",
        orientation: "Defense",
        painRate: 148.38,
        indexation: 0.7419169286,
      },
      {
        name: "Transylvania",
        orientation: "Defense",
        painRate: 166.34,
        indexation: 0.8316901879,
      },
      {
        name: "Tyrrell",
        orientation: "Neutral",
        painRate: 113.74,
        indexation: 0.568711123,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 254.37,
        indexation: 1.271874791,
      },
      {
        name: "Vance",
        orientation: "Neutral",
        painRate: 133.67,
        indexation: 0.6683298776,
      },
      {
        name: "Wake",
        orientation: "Defense",
        painRate: 259.78,
        indexation: 1.298896395,
      },
      {
        name: "Warren",
        orientation: "Plaintiff",
        painRate: 126.2,
        indexation: 0.6309945823,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 123.26,
        indexation: 0.6163066016,
      },
      {
        name: "Watauga",
        orientation: "Plaintiff",
        painRate: 154.87,
        indexation: 0.7743696074,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 148.85,
        indexation: 0.7442311551,
      },
      {
        name: "Wilkes",
        orientation: "Neutral",
        painRate: 129.03,
        indexation: 0.6451608588,
      },
      {
        name: "Wilson",
        orientation: "Plaintiff",
        painRate: 137.88,
        indexation: 0.6893853254,
      },
      {
        name: "Yadkin",
        orientation: "Neutral",
        painRate: 164.44,
        indexation: 0.8222058725,
      },
      {
        name: "Yancey",
        orientation: "Neutral",
        painRate: 145.69,
        indexation: 0.7284462578,
      },
    ],
  },
  ND: {
    name: "ND",
    counties: [
      {
        name: "North Dakota ",
        orientation: "Orientation",
        painRate: 195.95,
        indexation: 0.9797337971,
      },
      {
        name: "Adams",
        orientation: "Defense",
        painRate: 154.08,
        indexation: 0.7704233831,
      },
      {
        name: "Barnes",
        orientation: "Defense",
        painRate: 179.41,
        indexation: 0.8970369875,
      },
      {
        name: "Benson",
        orientation: "Defense",
        painRate: 151.44,
        indexation: 0.7572068758,
      },
      {
        name: "Billings",
        orientation: "Defense",
        painRate: 229.94,
        indexation: 1.149715738,
      },
      {
        name: "Bottineau",
        orientation: "Defense",
        painRate: 185.54,
        indexation: 0.927710521,
      },
      {
        name: "Bowman",
        orientation: "Defense",
        painRate: 201.69,
        indexation: 1.008440907,
      },
      {
        name: "Burke",
        orientation: "Defense",
        painRate: 203.15,
        indexation: 1.015731389,
      },
      {
        name: "Burleigh",
        orientation: "Defense",
        painRate: 222.36,
        indexation: 1.111778476,
      },
      {
        name: "Cass",
        orientation: "Defense",
        painRate: 187.28,
        indexation: 0.9364189686,
      },
      {
        name: "Cavalier",
        orientation: "Defense",
        painRate: 195.9,
        indexation: 0.9794796335,
      },
      {
        name: "Dickey",
        orientation: "Defense",
        painRate: 150.69,
        indexation: 0.753474684,
      },
      {
        name: "Divide",
        orientation: "Defense",
        painRate: 188.65,
        indexation: 0.9432546318,
      },
      {
        name: "Dunn",
        orientation: "Defense",
        painRate: 220.69,
        indexation: 1.10347134,
      },
      {
        name: "Eddy",
        orientation: "Defense",
        painRate: 157.44,
        indexation: 0.7872115578,
      },
      {
        name: "Emmons",
        orientation: "Defense",
        painRate: 163.07,
        indexation: 0.8153568323,
      },
      {
        name: "Foster",
        orientation: "Defense",
        painRate: 222.48,
        indexation: 1.112380443,
      },
      {
        name: "Golden Valley",
        orientation: "Defense",
        painRate: 173.43,
        indexation: 0.8671393218,
      },
      {
        name: "Grand Forks",
        orientation: "Defense",
        painRate: 173.67,
        indexation: 0.8683566317,
      },
      {
        name: "Grant",
        orientation: "Defense",
        painRate: 117.69,
        indexation: 0.5884288676,
      },
      {
        name: "Griggs",
        orientation: "Defense",
        painRate: 158.74,
        indexation: 0.7936860411,
      },
      {
        name: "Hettinger",
        orientation: "Defense",
        painRate: 177.09,
        indexation: 0.8854524781,
      },
      {
        name: "Kidder",
        orientation: "Defense",
        painRate: 152.37,
        indexation: 0.7618353287,
      },
      {
        name: "LaMoure",
        orientation: "Defense",
        painRate: 208,
        indexation: 1.039983948,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 162.38,
        indexation: 0.8119189352,
      },
      {
        name: "McHenry",
        orientation: "Defense",
        painRate: 174.91,
        indexation: 0.8745635743,
      },
      {
        name: "McIntosh",
        orientation: "Defense",
        painRate: 154.14,
        indexation: 0.7707043007,
      },
      {
        name: "McKenzie",
        orientation: "Defense",
        painRate: 225.29,
        indexation: 1.126426326,
      },
      {
        name: "McLean",
        orientation: "Defense",
        painRate: 213.86,
        indexation: 1.069293024,
      },
      {
        name: "Mercer",
        orientation: "Defense",
        painRate: 241.03,
        indexation: 1.205163534,
      },
      {
        name: "Morton",
        orientation: "Defense",
        painRate: 201.01,
        indexation: 1.005056518,
      },
      {
        name: "Mountrail",
        orientation: "Defense",
        painRate: 198.23,
        indexation: 0.9911577821,
      },
      {
        name: "Nelson",
        orientation: "Defense",
        painRate: 160.38,
        indexation: 0.8019129155,
      },
      {
        name: "Oliver",
        orientation: "Defense",
        painRate: 212.75,
        indexation: 1.063754933,
      },
      {
        name: "Pembina",
        orientation: "Defense",
        painRate: 184.98,
        indexation: 0.9249013444,
      },
      {
        name: "Pierce",
        orientation: "Defense",
        painRate: 157.87,
        indexation: 0.7893385058,
      },
      {
        name: "Ramsey",
        orientation: "Defense",
        painRate: 190.78,
        indexation: 0.9538759949,
      },
      {
        name: "Ransom",
        orientation: "Defense",
        painRate: 203.68,
        indexation: 1.018393419,
      },
      {
        name: "Renville",
        orientation: "Defense",
        painRate: 190.68,
        indexation: 0.9533944218,
      },
      {
        name: "Richland",
        orientation: "Defense",
        painRate: 198.66,
        indexation: 0.9932847301,
      },
      {
        name: "Rolette",
        orientation: "Defense",
        painRate: 135.37,
        indexation: 0.6768510468,
      },
      {
        name: "Sargent",
        orientation: "Defense",
        painRate: 201.52,
        indexation: 1.007624908,
      },
      {
        name: "Sheridan",
        orientation: "Defense",
        painRate: 152.29,
        indexation: 0.7614473948,
      },
      {
        name: "Sioux",
        orientation: "Defense",
        painRate: 116.97,
        indexation: 0.5848572002,
      },
      {
        name: "Slope",
        orientation: "Defense",
        painRate: 185.27,
        indexation: 0.9263460638,
      },
      {
        name: "Stark",
        orientation: "Defense",
        painRate: 232.66,
        indexation: 1.163306802,
      },
      {
        name: "Steele",
        orientation: "Defense",
        painRate: 216.92,
        indexation: 1.084623102,
      },
      {
        name: "Stutsman",
        orientation: "Defense",
        painRate: 175.59,
        indexation: 0.8779747174,
      },
      {
        name: "Towner",
        orientation: "Defense",
        painRate: 189.03,
        indexation: 0.9451675473,
      },
      {
        name: "Traill",
        orientation: "Defense",
        painRate: 211.53,
        indexation: 1.057655006,
      },
      {
        name: "Walsh",
        orientation: "Defense",
        painRate: 179.92,
        indexation: 0.8995920005,
      },
      {
        name: "Ward",
        orientation: "Defense",
        painRate: 192.87,
        indexation: 0.9643635877,
      },
      {
        name: "Wells",
        orientation: "Defense",
        painRate: 177.36,
        indexation: 0.8868169353,
      },
      {
        name: "Williams",
        orientation: "Defense",
        painRate: 225.12,
        indexation: 1.125610327,
      },
    ],
  },
  NE: {
    name: "NE",
    counties: [
      {
        name: "Nebraska ",
        orientation: "Orientation",
        painRate: 186.82,
        indexation: 0.9340913651,
      },
      {
        name: "Adams",
        orientation: "Defense",
        painRate: 171.79,
        indexation: 0.8589659555,
      },
      {
        name: "Antelope",
        orientation: "Defense",
        painRate: 169.22,
        indexation: 0.846097251,
      },
      {
        name: "Arthur",
        orientation: "Defense",
        painRate: 152.55,
        indexation: 0.7627315899,
      },
      {
        name: "Banner",
        orientation: "Defense",
        painRate: 178.14,
        indexation: 0.8906962745,
      },
      {
        name: "Blaine",
        orientation: "Defense",
        painRate: 146.98,
        indexation: 0.73488061,
      },
      {
        name: "Boone",
        orientation: "Defense",
        painRate: 183.35,
        indexation: 0.9167681092,
      },
      {
        name: "Box Butte",
        orientation: "Defense",
        painRate: 178.62,
        indexation: 0.8930773861,
      },
      {
        name: "Boyd",
        orientation: "Defense",
        painRate: 145.66,
        indexation: 0.7282857334,
      },
      {
        name: "Brown",
        orientation: "Defense",
        painRate: 148.2,
        indexation: 0.7410072905,
      },
      {
        name: "Buffalo",
        orientation: "Defense",
        painRate: 181.42,
        indexation: 0.9070831383,
      },
      {
        name: "Burt",
        orientation: "Defense",
        painRate: 155.51,
        indexation: 0.7775399639,
      },
      {
        name: "Butler",
        orientation: "Defense",
        painRate: 204.45,
        indexation: 1.022232627,
      },
      {
        name: "Cass",
        orientation: "Defense",
        painRate: 249.33,
        indexation: 1.246672463,
      },
      {
        name: "Cedar",
        orientation: "Defense",
        painRate: 184.69,
        indexation: 0.923456625,
      },
      {
        name: "Chase",
        orientation: "Defense",
        painRate: 173.86,
        indexation: 0.8693064009,
      },
      {
        name: "Cherry",
        orientation: "Defense",
        painRate: 155.22,
        indexation: 0.7760952445,
      },
      {
        name: "Cheyenne",
        orientation: "Defense",
        painRate: 151.44,
        indexation: 0.7571934988,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 171.9,
        indexation: 0.8594876597,
      },
      {
        name: "Colfax",
        orientation: "Defense",
        painRate: 186.07,
        indexation: 0.9303591733,
      },
      {
        name: "Cuming",
        orientation: "Defense",
        painRate: 176.07,
        indexation: 0.880329075,
      },
      {
        name: "Custer",
        orientation: "Defense",
        painRate: 161.4,
        indexation: 0.8070095646,
      },
      {
        name: "Dakota",
        orientation: "Defense",
        painRate: 176.54,
        indexation: 0.8826968096,
      },
      {
        name: "Dawes",
        orientation: "Defense",
        painRate: 140.78,
        indexation: 0.7039127818,
      },
      {
        name: "Dawson",
        orientation: "Defense",
        painRate: 166.11,
        indexation: 0.8305665173,
      },
      {
        name: "Deuel",
        orientation: "Defense",
        painRate: 150.09,
        indexation: 0.7504380978,
      },
      {
        name: "Dixon",
        orientation: "Defense",
        painRate: 163.59,
        indexation: 0.8179252224,
      },
      {
        name: "Dodge",
        orientation: "Defense",
        painRate: 182.64,
        indexation: 0.9132098187,
      },
      {
        name: "Douglas",
        orientation: "Plaintiff",
        painRate: 199.11,
        indexation: 0.9955722025,
      },
      {
        name: "Dundy",
        orientation: "Defense",
        painRate: 156.35,
        indexation: 0.7817671059,
      },
      {
        name: "Fillmore",
        orientation: "Defense",
        painRate: 183.35,
        indexation: 0.9167547321,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 158.92,
        indexation: 0.7945956792,
      },
      {
        name: "Frontier",
        orientation: "Defense",
        painRate: 155.82,
        indexation: 0.7790916996,
      },
      {
        name: "Furnas",
        orientation: "Defense",
        painRate: 145.04,
        indexation: 0.7252090161,
      },
      {
        name: "Gage",
        orientation: "Defense",
        painRate: 172.31,
        indexation: 0.8615610996,
      },
      {
        name: "Garden",
        orientation: "Defense",
        painRate: 138.41,
        indexation: 0.6920473547,
      },
      {
        name: "Garfield",
        orientation: "Defense",
        painRate: 137.86,
        indexation: 0.6893184402,
      },
      {
        name: "Gosper",
        orientation: "Defense",
        painRate: 181.12,
        indexation: 0.9055849107,
      },
      {
        name: "Grant",
        orientation: "Defense",
        painRate: 169.81,
        indexation: 0.8490268209,
      },
      {
        name: "Greeley",
        orientation: "Defense",
        painRate: 156.67,
        indexation: 0.7833723497,
      },
      {
        name: "Hall",
        orientation: "Defense",
        painRate: 177.32,
        indexation: 0.8865895258,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 201.64,
        indexation: 1.008213497,
      },
      {
        name: "Harlan",
        orientation: "Defense",
        painRate: 166.15,
        indexation: 0.8307671728,
      },
      {
        name: "Hayes",
        orientation: "Defense",
        painRate: 160.9,
        indexation: 0.8045080597,
      },
      {
        name: "Hitchcock",
        orientation: "Defense",
        painRate: 145.78,
        indexation: 0.7288876998,
      },
      {
        name: "Holt",
        orientation: "Defense",
        painRate: 161.87,
        indexation: 0.8093371681,
      },
      {
        name: "Hooker",
        orientation: "Defense",
        painRate: 143.23,
        indexation: 0.7161661427,
      },
      {
        name: "Howard",
        orientation: "Defense",
        painRate: 185.73,
        indexation: 0.9286736673,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 159.13,
        indexation: 0.7956390877,
      },
      {
        name: "Johnson",
        orientation: "Defense",
        painRate: 161.99,
        indexation: 0.8099658886,
      },
      {
        name: "Kearney",
        orientation: "Defense",
        painRate: 199.82,
        indexation: 0.9990769848,
      },
      {
        name: "Keith",
        orientation: "Defense",
        painRate: 162.15,
        indexation: 0.8107551334,
      },
      {
        name: "Keya Paha",
        orientation: "Defense",
        painRate: 137.99,
        indexation: 0.6899739148,
      },
      {
        name: "Kimball",
        orientation: "Defense",
        painRate: 149.28,
        indexation: 0.7463982342,
      },
      {
        name: "Knox",
        orientation: "Defense",
        painRate: 146.83,
        indexation: 0.7341314962,
      },
      {
        name: "Lancaster",
        orientation: "Plaintiff",
        painRate: 182.89,
        indexation: 0.9144271286,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 167.06,
        indexation: 0.8352886095,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 183.86,
        indexation: 0.9193097452,
      },
      {
        name: "Loup",
        orientation: "Defense",
        painRate: 161.83,
        indexation: 0.8091365126,
      },
      {
        name: "McPherson",
        orientation: "Defense",
        painRate: 141.19,
        indexation: 0.7059594676,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 169.84,
        indexation: 0.8492007224,
      },
      {
        name: "Merrick",
        orientation: "Defense",
        painRate: 183.94,
        indexation: 0.9197244331,
      },
      {
        name: "Morrill",
        orientation: "Defense",
        painRate: 157.55,
        indexation: 0.7877600161,
      },
      {
        name: "Nance",
        orientation: "Defense",
        painRate: 168.18,
        indexation: 0.8409203398,
      },
      {
        name: "Nemaha",
        orientation: "Defense",
        painRate: 165.7,
        indexation: 0.8284797004,
      },
      {
        name: "Nuckolls",
        orientation: "Defense",
        painRate: 153.24,
        indexation: 0.7662096181,
      },
      {
        name: "Otoe",
        orientation: "Defense",
        painRate: 188.44,
        indexation: 0.9421844693,
      },
      {
        name: "Pawnee",
        orientation: "Defense",
        painRate: 148.71,
        indexation: 0.7435489265,
      },
      {
        name: "Perkins",
        orientation: "Defense",
        painRate: 180.04,
        indexation: 0.900220721,
      },
      {
        name: "Phelps",
        orientation: "Defense",
        painRate: 177.34,
        indexation: 0.8867232961,
      },
      {
        name: "Pierce",
        orientation: "Defense",
        painRate: 189.7,
        indexation: 0.9485118052,
      },
      {
        name: "Platte",
        orientation: "Defense",
        painRate: 179.61,
        indexation: 0.8980268878,
      },
      {
        name: "Polk",
        orientation: "Defense",
        painRate: 189.45,
        indexation: 0.9472677413,
      },
      {
        name: "Red Willow",
        orientation: "Defense",
        painRate: 160.81,
        indexation: 0.8040666176,
      },
      {
        name: "Richardson",
        orientation: "Defense",
        painRate: 150.15,
        indexation: 0.7507323925,
      },
      {
        name: "Rock",
        orientation: "Defense",
        painRate: 148.88,
        indexation: 0.7444050565,
      },
      {
        name: "Saline",
        orientation: "Defense",
        painRate: 195.17,
        indexation: 0.9758277038,
      },
      {
        name: "Sarpy",
        orientation: "Defense",
        painRate: 263.85,
        indexation: 1.319229483,
      },
      {
        name: "Saunders",
        orientation: "Defense",
        painRate: 230.56,
        indexation: 1.152819209,
      },
      {
        name: "Scotts Bluff",
        orientation: "Plaintiff",
        painRate: 152.1,
        indexation: 0.7605243796,
      },
      {
        name: "Seward",
        orientation: "Defense",
        painRate: 208.09,
        indexation: 1.040438767,
      },
      {
        name: "Sheridan",
        orientation: "Defense",
        painRate: 148.19,
        indexation: 0.7409270283,
      },
      {
        name: "Sherman",
        orientation: "Defense",
        painRate: 156.39,
        indexation: 0.7819677614,
      },
      {
        name: "Sioux",
        orientation: "Defense",
        painRate: 162.68,
        indexation: 0.8134037857,
      },
      {
        name: "Stanton",
        orientation: "Defense",
        painRate: 200.84,
        indexation: 1.004187011,
      },
      {
        name: "Thayer",
        orientation: "Defense",
        painRate: 163.53,
        indexation: 0.8176710588,
      },
      {
        name: "Thomas",
        orientation: "Defense",
        painRate: 157.36,
        indexation: 0.7868102468,
      },
      {
        name: "Thurston",
        orientation: "Defense",
        painRate: 152.84,
        indexation: 0.7642164404,
      },
      {
        name: "Valley",
        orientation: "Defense",
        painRate: 147.28,
        indexation: 0.7364055916,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 233.74,
        indexation: 1.1687245,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 175.68,
        indexation: 0.8783894054,
      },
      {
        name: "Webster",
        orientation: "Defense",
        painRate: 167.69,
        indexation: 0.838458966,
      },
      {
        name: "Wheeler",
        orientation: "Defense",
        painRate: 149.69,
        indexation: 0.7484582971,
      },
      {
        painRate: 183.1,
        indexation: 0.9155240452,
      },
    ],
  },
  NH: {
    name: "NH",
    counties: [
      {
        name: "New Hampshire ",
        orientation: "Orientation",
        painRate: 242.79,
        indexation: 1.213965621,
      },
      {
        name: "Belknap",
        orientation: "Neutral",
        painRate: 207.43,
        indexation: 1.03713464,
      },
      {
        name: "Carroll",
        orientation: "Neutral",
        painRate: 192.58,
        indexation: 0.9628921142,
      },
      {
        name: "Cheshire",
        orientation: "Plaintiff",
        painRate: 203.94,
        indexation: 1.019690991,
      },
      {
        name: "Coos",
        orientation: "Defense",
        painRate: 139.9,
        indexation: 0.6995117383,
      },
      {
        name: "Grafton",
        orientation: "Neutral",
        painRate: 217.26,
        indexation: 1.086281854,
      },
      {
        name: "Hillsborough",
        orientation: "Plaintiff",
        painRate: 257.95,
        indexation: 1.289746505,
      },
      {
        name: "Merrimack",
        orientation: "Plaintiff",
        painRate: 227.14,
        indexation: 1.135683232,
      },
      {
        name: "Rockingham",
        orientation: "Neutral",
        painRate: 287.46,
        indexation: 1.437295164,
      },
      {
        name: "Strafford",
        orientation: "Neutral",
        painRate: 221.64,
        indexation: 1.108220186,
      },
      {
        name: "Sullivan",
        orientation: "Defense",
        painRate: 199.85,
        indexation: 0.9992642633,
      },
    ],
  },
  NJ: {
    name: "NJ",
    counties: [
      {
        name: "New Jersey ",
        orientation: "Orientation",
        painRate: 257.75,
        indexation: 1.288756605,
      },
      {
        name: "Atlantic",
        orientation: "Plaintiff",
        painRate: 203.62,
        indexation: 1.018099124,
      },
      {
        name: "Bergen",
        orientation: "Neutral",
        painRate: 304.69,
        indexation: 1.523443248,
      },
      {
        name: "Burlington",
        orientation: "Defense",
        painRate: 268.04,
        indexation: 1.340218046,
      },
      {
        name: "Camden",
        orientation: "Plaintiff",
        painRate: 217.7,
        indexation: 1.088515818,
      },
      {
        name: "Cape May",
        orientation: "Defense",
        painRate: 216.29,
        indexation: 1.081466123,
      },
      {
        name: "Cumberland",
        orientation: "Defense",
        painRate: 162.91,
        indexation: 0.8145542104,
      },
      {
        name: "Essex",
        orientation: "Plaintiff",
        painRate: 199.98,
        indexation: 0.9998929837,
      },
      {
        name: "Gloucester",
        orientation: "Defense",
        painRate: 261.79,
        indexation: 1.308955923,
      },
      {
        name: "Hudson",
        orientation: "Plaintiff",
        painRate: 220.35,
        indexation: 1.101745703,
      },
      {
        name: "Hunterdon",
        orientation: "Defense",
        painRate: 367.42,
        indexation: 1.837121263,
      },
      {
        name: "Mercer",
        orientation: "Plaintiff",
        painRate: 253.71,
        indexation: 1.268570664,
      },
      {
        name: "Middlesex",
        orientation: "Plaintiff",
        painRate: 273.09,
        indexation: 1.36543375,
      },
      {
        name: "Monmouth",
        orientation: "Defense",
        painRate: 314.89,
        indexation: 1.574463247,
      },
      {
        name: "Morris",
        orientation: "Defense",
        painRate: 351.98,
        indexation: 1.759909036,
      },
      {
        name: "Ocean",
        orientation: "Defense",
        painRate: 217.11,
        indexation: 1.085572871,
      },
      {
        name: "Passaic",
        orientation: "Neutral",
        painRate: 210.77,
        indexation: 1.053829175,
      },
      {
        name: "Salem",
        orientation: "Defense",
        painRate: 203.34,
        indexation: 1.01672129,
      },
      {
        name: "Somerset",
        orientation: "Neutral",
        painRate: 360.47,
        indexation: 1.802354358,
      },
      {
        name: "Sussex",
        orientation: "Defense",
        painRate: 297.79,
        indexation: 1.488970637,
      },
      {
        name: "Union",
        orientation: "Plaintiff",
        painRate: 258.13,
        indexation: 1.290656143,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 261.46,
        indexation: 1.307323925,
      },
    ],
  },
  NM: {
    name: "NM",
    counties: [
      {
        name: "New Mexico ",
        orientation: "Orientation",
        painRate: 160.1,
        indexation: 0.8005083272,
      },
      {
        name: "Bernalillo",
        orientation: "Neutral",
        painRate: 173.99,
        indexation: 0.8699618755,
      },
      {
        name: "Catron",
        orientation: "Defense",
        painRate: 118.38,
        indexation: 0.5918935188,
      },
      {
        name: "Chaves",
        orientation: "Defense",
        painRate: 128.03,
        indexation: 0.640157849,
      },
      {
        name: "Cibola",
        orientation: "Neutral",
        painRate: 122.2,
        indexation: 0.610982543,
      },
      {
        name: "Colfax",
        orientation: "Defense",
        painRate: 133.68,
        indexation: 0.6683967628,
      },
      {
        name: "Curry",
        orientation: "Defense",
        painRate: 140.36,
        indexation: 0.7017992108,
      },
      {
        name: "De Baca",
        orientation: "Neutral",
        painRate: 120.95,
        indexation: 0.6047488462,
      },
      {
        name: "Dona Ana",
        orientation: "Neutral",
        painRate: 139.15,
        indexation: 0.6957527925,
      },
      {
        name: "Eddy",
        orientation: "Defense",
        painRate: 174.07,
        indexation: 0.8703498094,
      },
      {
        name: "Grant",
        orientation: "Neutral",
        painRate: 126.01,
        indexation: 0.6300448131,
      },
      {
        name: "Guadalupe",
        orientation: "Neutral",
        painRate: 117.35,
        indexation: 0.5867433616,
      },
      {
        name: "Harding",
        orientation: "Neutral",
        painRate: 120.97,
        indexation: 0.6048692395,
      },
      {
        name: "Hidalgo",
        orientation: "Neutral",
        painRate: 116.67,
        indexation: 0.5833723497,
      },
      {
        name: "Lea",
        orientation: "Defense",
        painRate: 158.37,
        indexation: 0.7918400107,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 145.39,
        indexation: 0.7269614073,
      },
      {
        name: "Los Alamos",
        orientation: "Neutral",
        painRate: 372.46,
        indexation: 1.862310213,
      },
      {
        name: "Luna",
        orientation: "Neutral",
        painRate: 103.8,
        indexation: 0.5189753194,
      },
      {
        name: "McKinley",
        orientation: "Plaintiff",
        painRate: 116.97,
        indexation: 0.5848438232,
      },
      {
        name: "Mora",
        orientation: "Plaintiff",
        painRate: 120.81,
        indexation: 0.6040398636,
      },
      {
        name: "Otero",
        orientation: "Neutral",
        painRate: 141.23,
        indexation: 0.706133369,
      },
      {
        name: "Quay",
        orientation: "Defense",
        painRate: 106.71,
        indexation: 0.5335429068,
      },
      {
        name: "Rio Arriba",
        orientation: "Plaintiff",
        painRate: 150.77,
        indexation: 0.7538358638,
      },
      {
        name: "Roosevelt",
        orientation: "Defense",
        painRate: 128.03,
        indexation: 0.6401444719,
      },
      {
        name: "Sandoval",
        orientation: "Neutral",
        painRate: 221.69,
        indexation: 1.108447595,
      },
      {
        name: "San Juan",
        orientation: "Defense",
        painRate: 138.85,
        indexation: 0.6942411879,
      },
      {
        name: "San Miguel",
        orientation: "Plaintiff",
        painRate: 127.55,
        indexation: 0.6377499833,
      },
      {
        name: "Santa Fe",
        orientation: "Plaintiff",
        painRate: 194.87,
        indexation: 0.9743294763,
      },
      {
        name: "Sierra",
        orientation: "Defense",
        painRate: 108.14,
        indexation: 0.5406996188,
      },
      {
        name: "Socorro",
        orientation: "Neutral",
        painRate: 113.12,
        indexation: 0.5655808976,
      },
      {
        name: "Taos",
        orientation: "Plaintiff",
        painRate: 129.66,
        indexation: 0.6482777072,
      },
      {
        name: "Torrance",
        orientation: "Neutral",
        painRate: 129.28,
        indexation: 0.6464049227,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 129.88,
        indexation: 0.6493880008,
      },
      {
        name: "Valencia",
        orientation: "Neutral",
        painRate: 142.53,
        indexation: 0.7126613604,
      },
    ],
  },
  NV: {
    name: "NV",
    counties: [
      {
        name: "Nevada ",
        orientation: "Orientation",
        painRate: 193.56,
        indexation: 0.9678014849,
      },
      {
        name: "Carson City",
        orientation: "Defense",
        painRate: 168.17,
        indexation: 0.8408668317,
      },
      {
        name: "Churchill",
        orientation: "Neutral",
        painRate: 187.68,
        indexation: 0.9383853923,
      },
      {
        name: "Clark",
        orientation: "Plaintiff",
        painRate: 189.52,
        indexation: 0.9476021671,
      },
      {
        name: "Douglas",
        orientation: "Defense",
        painRate: 246.03,
        indexation: 1.230151829,
      },
      {
        name: "Elko",
        orientation: "Defense",
        painRate: 237.46,
        indexation: 1.187305197,
      },
      {
        name: "Esmeralda",
        orientation: "Defense",
        painRate: 153.84,
        indexation: 0.7691926961,
      },
      {
        name: "Eureka",
        orientation: "Defense",
        painRate: 214.81,
        indexation: 1.074055247,
      },
      {
        name: "Humboldt",
        orientation: "Defense",
        painRate: 214.71,
        indexation: 1.073573674,
      },
      {
        name: "Lander",
        orientation: "Neutral",
        painRate: 241.29,
        indexation: 1.206474483,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 171.17,
        indexation: 0.8558357301,
      },
      {
        name: "Lyon",
        orientation: "Neutral",
        painRate: 199.96,
        indexation: 0.9998127216,
      },
      {
        name: "Mineral",
        orientation: "Neutral",
        painRate: 149.03,
        indexation: 0.7451541703,
      },
      {
        name: "Nye",
        orientation: "Defense",
        painRate: 138.94,
        indexation: 0.694709384,
      },
      {
        name: "Pershing",
        orientation: "Neutral",
        painRate: 174.74,
        indexation: 0.8737208213,
      },
      {
        name: "Storey",
        orientation: "Neutral",
        painRate: 241.24,
        indexation: 1.20622032,
      },
      {
        name: "Washoe",
        orientation: "Plaintiff",
        painRate: 214.69,
        indexation: 1.073439904,
      },
      {
        name: "White Pine",
        orientation: "Defense",
        painRate: 180.96,
        indexation: 0.9048090429,
      },
    ],
  },
  NY: {
    name: "NY",
    counties: [
      {
        name: "New York ",
        orientation: "Orientation",
        painRate: 212.6,
        indexation: 1.062979065,
      },
      {
        name: "Albany",
        orientation: "Neutral",
        painRate: 206,
        indexation: 1.029991305,
      },
      {
        name: "Allegany",
        orientation: "Defense",
        painRate: 148.39,
        indexation: 0.7419704368,
      },
      {
        name: "Bronx",
        orientation: "Plaintiff",
        painRate: 122.7,
        indexation: 0.613524179,
      },
      {
        name: "Broome",
        orientation: "Neutral",
        painRate: 161.95,
        indexation: 0.8097518561,
      },
      {
        name: "Cattaraugus",
        orientation: "Neutral",
        painRate: 140.15,
        indexation: 0.7007290482,
      },
      {
        name: "Cayuga",
        orientation: "Neutral",
        painRate: 171.57,
        indexation: 0.8578556618,
      },
      {
        name: "Chautauqua",
        orientation: "Neutral",
        painRate: 148.87,
        indexation: 0.7443515484,
      },
      {
        name: "Chemung",
        orientation: "Defense",
        painRate: 151.6,
        indexation: 0.7579961207,
      },
      {
        name: "Chenango",
        orientation: "Defense",
        painRate: 162.54,
        indexation: 0.8127215571,
      },
      {
        name: "Clinton",
        orientation: "Defense",
        painRate: 174.63,
        indexation: 0.873145609,
      },
      {
        name: "Columbia",
        orientation: "Neutral",
        painRate: 223.48,
        indexation: 1.117423584,
      },
      {
        name: "Cortland",
        orientation: "Neutral",
        painRate: 155.91,
        indexation: 0.7795331416,
      },
      {
        name: "Delaware",
        orientation: "Neutral",
        painRate: 164.33,
        indexation: 0.8216707913,
      },
      {
        name: "Dutchess",
        orientation: "Neutral",
        painRate: 242.77,
        indexation: 1.213831851,
      },
      {
        name: "Erie",
        orientation: "Plaintiff",
        painRate: 186.98,
        indexation: 0.934893987,
      },
      {
        name: "Essex",
        orientation: "Defense",
        painRate: 166.88,
        indexation: 0.8344057254,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 158.36,
        indexation: 0.7918132566,
      },
      {
        name: "Fulton",
        orientation: "Defense",
        painRate: 164.57,
        indexation: 0.82284797,
      },
      {
        name: "Genesee",
        orientation: "Defense",
        painRate: 184.53,
        indexation: 0.9226540031,
      },
      {
        name: "Greene",
        orientation: "Neutral",
        painRate: 191.11,
        indexation: 0.9555615009,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 166.27,
        indexation: 0.8313691392,
      },
      {
        name: "Herkimer",
        orientation: "Defense",
        painRate: 166.4,
        indexation: 0.8320112367,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 157.21,
        indexation: 0.7860745101,
      },
      {
        name: "Kings",
        orientation: "Plaintiff",
        painRate: 195.96,
        indexation: 0.9797873052,
      },
      {
        name: "Lewis",
        orientation: "Defense",
        painRate: 172.44,
        indexation: 0.862176443,
      },
      {
        name: "Livingston",
        orientation: "Defense",
        painRate: 200.86,
        indexation: 1.004307404,
      },
      {
        name: "Madison",
        orientation: "Defense",
        painRate: 186.88,
        indexation: 0.9343990369,
      },
      {
        name: "Monroe",
        orientation: "Neutral",
        painRate: 182.38,
        indexation: 0.9118988696,
      },
      {
        name: "Montgomery",
        orientation: "Defense",
        painRate: 155.64,
        indexation: 0.7782221925,
      },
      {
        name: "Nassau",
        orientation: "Neutral",
        painRate: 362.59,
        indexation: 1.812962344,
      },
      {
        name: "New York City",
        orientation: "Neutral",
        painRate: 255.54,
        indexation: 1.2776938,
      },
      {
        name: "Niagara",
        orientation: "Plaintiff",
        painRate: 170.06,
        indexation: 0.850311016,
      },
      {
        name: "Oneida",
        orientation: "Neutral",
        painRate: 170.94,
        indexation: 0.8547120594,
      },
      {
        name: "Onondaga",
        orientation: "Neutral",
        painRate: 189.79,
        indexation: 0.9489532473,
      },
      {
        name: "Ontario",
        orientation: "Neutral",
        painRate: 204.94,
        indexation: 1.024694,
      },
      {
        name: "Orange",
        orientation: "Defense",
        painRate: 236.86,
        indexation: 1.184295365,
      },
      {
        name: "Orleans",
        orientation: "Defense",
        painRate: 168.28,
        indexation: 0.8414152899,
      },
      {
        name: "Oswego",
        orientation: "Neutral",
        painRate: 158.75,
        indexation: 0.7937261722,
      },
      {
        name: "Otsego",
        orientation: "Defense",
        painRate: 184.5,
        indexation: 0.9225068557,
      },
      {
        name: "Putnam",
        orientation: "Neutral",
        painRate: 300.09,
        indexation: 1.500461508,
      },
      {
        name: "Queens",
        orientation: "Plaintiff",
        painRate: 214.51,
        indexation: 1.072570397,
      },
      {
        name: "Rensselaer",
        orientation: "Neutral",
        painRate: 214.14,
        indexation: 1.070697612,
      },
      {
        name: "Richmond",
        orientation: "Defense",
        painRate: 245.02,
        indexation: 1.225095311,
      },
      {
        name: "Rockland",
        orientation: "Defense",
        painRate: 267.11,
        indexation: 1.335549462,
      },
      {
        name: "St. Lawrence",
        orientation: "Defense",
        painRate: 159.06,
        indexation: 0.7952779078,
      },
      {
        name: "Saratoga",
        orientation: "Neutral",
        painRate: 249.73,
        indexation: 1.24862551,
      },
      {
        name: "Schenectady",
        orientation: "Neutral",
        painRate: 198.53,
        indexation: 0.9926560096,
      },
      {
        name: "Schoharie",
        orientation: "Defense",
        painRate: 181.86,
        indexation: 0.9093037255,
      },
      {
        name: "Schuyler",
        orientation: "Defense",
        painRate: 169.17,
        indexation: 0.8458698415,
      },
      {
        name: "Seneca",
        orientation: "Defense",
        painRate: 174.85,
        indexation: 0.8742425256,
      },
      {
        name: "Steuben",
        orientation: "Defense",
        painRate: 162.02,
        indexation: 0.8100862819,
      },
      {
        name: "Suffolk",
        orientation: "Defense",
        painRate: 319.05,
        indexation: 1.595251154,
      },
      {
        name: "Sullivan",
        orientation: "Defense",
        painRate: 170.51,
        indexation: 0.8525583573,
      },
      {
        name: "Tioga",
        orientation: "Defense",
        painRate: 203.86,
        indexation: 1.01928968,
      },
      {
        name: "Tompkins",
        orientation: "Neutral",
        painRate: 192.7,
        indexation: 0.9634807036,
      },
      {
        name: "Ulster",
        orientation: "Neutral",
        painRate: 212.27,
        indexation: 1.06133369,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 186.87,
        indexation: 0.9343722828,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 179.29,
        indexation: 0.8964350211,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 189.73,
        indexation: 0.9486589526,
      },
      {
        name: "Westchester",
        orientation: "Defense",
        painRate: 289.04,
        indexation: 1.445214367,
      },
      {
        name: "Wyoming",
        orientation: "Defense",
        painRate: 175.31,
        indexation: 0.876543375,
      },
      {
        name: "Yates",
        orientation: "Defense",
        painRate: 167.58,
        indexation: 0.8378971306,
      },
    ],
  },
  OH: {
    name: "OH",
    counties: [
      {
        name: "Ohio ",
        orientation: "Orientation",
        painRate: 176,
        indexation: 0.8800214033,
      },
      {
        name: "Adams",
        orientation: "Neutral",
        painRate: 131.28,
        indexation: 0.6563841883,
      },
      {
        name: "Allen",
        orientation: "Neutral",
        painRate: 161.55,
        indexation: 0.8077586783,
      },
      {
        name: "Ashland",
        orientation: "Defense",
        painRate: 165.97,
        indexation: 0.8298709116,
      },
      {
        name: "Ashtabula",
        orientation: "Plaintiff",
        painRate: 140.37,
        indexation: 0.7018660959,
      },
      {
        name: "Athens",
        orientation: "Defense",
        painRate: 129.13,
        indexation: 0.6456424319,
      },
      {
        name: "Auglaize",
        orientation: "Defense",
        painRate: 191.74,
        indexation: 0.9587184804,
      },
      {
        name: "Belmont",
        orientation: "Defense",
        painRate: 148.68,
        indexation: 0.7434017791,
      },
      {
        name: "Brown",
        orientation: "Neutral",
        painRate: 171.32,
        indexation: 0.8565848438,
      },
      {
        name: "Butler",
        orientation: "Defense",
        painRate: 200.23,
        indexation: 1.001150425,
      },
      {
        name: "Carroll",
        orientation: "Defense",
        painRate: 167.16,
        indexation: 0.8358236907,
      },
      {
        name: "Champaign",
        orientation: "Neutral",
        painRate: 194.73,
        indexation: 0.9736338706,
      },
      {
        name: "Clark",
        orientation: "Neutral",
        painRate: 153.2,
        indexation: 0.7660223396,
      },
      {
        name: "Clermont",
        orientation: "Neutral",
        painRate: 212.67,
        indexation: 1.063366999,
      },
      {
        name: "Clinton",
        orientation: "Neutral",
        painRate: 176.38,
        indexation: 0.8819075647,
      },
      {
        name: "Columbiana",
        orientation: "Neutral",
        painRate: 144.92,
        indexation: 0.7245936727,
      },
      {
        name: "Coshocton",
        orientation: "Defense",
        painRate: 135.73,
        indexation: 0.678630192,
      },
      {
        name: "Crawford",
        orientation: "Neutral",
        painRate: 149.59,
        indexation: 0.7479365929,
      },
      {
        name: "Cuyahoga",
        orientation: "Plaintiff",
        painRate: 162.69,
        indexation: 0.8134305398,
      },
      {
        name: "Darke",
        orientation: "Defense",
        painRate: 175.62,
        indexation: 0.8781084877,
      },
      {
        name: "Defiance",
        orientation: "Defense",
        painRate: 170.97,
        indexation: 0.8548458297,
      },
      {
        name: "Delaware",
        orientation: "Neutral",
        painRate: 325.14,
        indexation: 1.625683901,
      },
      {
        name: "Erie",
        orientation: "Defense",
        painRate: 181.73,
        indexation: 0.9086348739,
      },
      {
        name: "Fairfield",
        orientation: "Defense",
        painRate: 220.58,
        indexation: 1.102896127,
      },
      {
        name: "Fayette",
        orientation: "Neutral",
        painRate: 152.23,
        indexation: 0.7611664772,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 186.64,
        indexation: 0.933208481,
      },
      {
        name: "Fulton",
        orientation: "Neutral",
        painRate: 201.68,
        indexation: 1.008400776,
      },
      {
        name: "Gallia",
        orientation: "Defense",
        painRate: 137.65,
        indexation: 0.6882349007,
      },
      {
        name: "Geauga",
        orientation: "Neutral",
        painRate: 271.92,
        indexation: 1.359587987,
      },
      {
        name: "Greene",
        orientation: "Neutral",
        painRate: 221.44,
        indexation: 1.107203532,
      },
      {
        name: "Guernsey",
        orientation: "Plaintiff",
        painRate: 149.83,
        indexation: 0.7491672798,
      },
      {
        name: "Hamilton",
        orientation: "Defense",
        painRate: 179.34,
        indexation: 0.8967025617,
      },
      {
        name: "Hancock",
        orientation: "Defense",
        painRate: 194.39,
        indexation: 0.9719483647,
      },
      {
        name: "Hardin",
        orientation: "Defense",
        painRate: 157.43,
        indexation: 0.7871580496,
      },
      {
        name: "Harrison",
        orientation: "Defense",
        painRate: 147.49,
        indexation: 0.7374623771,
      },
      {
        name: "Henry",
        orientation: "Neutral",
        painRate: 192.96,
        indexation: 0.9647782757,
      },
      {
        name: "Highland",
        orientation: "Neutral",
        painRate: 159.09,
        indexation: 0.7954518092,
      },
      {
        name: "Hocking",
        orientation: "Defense",
        painRate: 153.44,
        indexation: 0.7672128955,
      },
      {
        name: "Holmes",
        orientation: "Defense",
        painRate: 201.26,
        indexation: 1.006300582,
      },
      {
        name: "Huron",
        orientation: "Defense",
        painRate: 164.49,
        indexation: 0.8224466591,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 146.86,
        indexation: 0.7343053976,
      },
      {
        name: "Jefferson",
        orientation: "Plaintiff",
        painRate: 145.2,
        indexation: 0.725998261,
      },
      {
        name: "Knox",
        orientation: "Defense",
        painRate: 182.82,
        indexation: 0.9141060799,
      },
      {
        name: "Lake",
        orientation: "Neutral",
        painRate: 203.77,
        indexation: 1.018834861,
      },
      {
        name: "Lawrence",
        orientation: "Neutral",
        painRate: 136.61,
        indexation: 0.6830579894,
      },
      {
        name: "Licking",
        orientation: "Defense",
        painRate: 204.93,
        indexation: 1.024627115,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 167.08,
        indexation: 0.8353822487,
      },
      {
        name: "Lorain",
        orientation: "Neutral",
        painRate: 179.58,
        indexation: 0.8979198716,
      },
      {
        name: "Lucas",
        orientation: "Plaintiff",
        painRate: 155.1,
        indexation: 0.775479901,
      },
      {
        name: "Madison",
        orientation: "Neutral",
        painRate: 210.11,
        indexation: 1.050538426,
      },
      {
        name: "Mahoning",
        orientation: "Plaintiff",
        painRate: 146.54,
        indexation: 0.7327135309,
      },
      {
        name: "Marion",
        orientation: "Defense",
        painRate: 157.88,
        indexation: 0.7894053909,
      },
      {
        name: "Medina",
        orientation: "Neutral",
        painRate: 238.4,
        indexation: 1.192000535,
      },
      {
        name: "Meigs",
        orientation: "Neutral",
        painRate: 124.12,
        indexation: 0.6206006287,
      },
      {
        name: "Mercer",
        orientation: "Defense",
        painRate: 192.84,
        indexation: 0.9641763093,
      },
      {
        name: "Miami",
        orientation: "Defense",
        painRate: 195,
        indexation: 0.9750117049,
      },
      {
        name: "Monroe",
        orientation: "Neutral",
        painRate: 150.85,
        indexation: 0.7542505518,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 168,
        indexation: 0.8399973246,
      },
      {
        name: "Morgan",
        orientation: "Neutral",
        painRate: 157.32,
        indexation: 0.7866095913,
      },
      {
        name: "Morrow",
        orientation: "Defense",
        painRate: 192.19,
        indexation: 0.9609390676,
      },
      {
        name: "Muskingum",
        orientation: "Defense",
        painRate: 157.29,
        indexation: 0.786449067,
      },
      {
        name: "Noble",
        orientation: "Neutral",
        painRate: 152.64,
        indexation: 0.763213163,
      },
      {
        name: "Ottawa",
        orientation: "Neutral",
        painRate: 194.72,
        indexation: 0.9735803625,
      },
      {
        name: "Paulding",
        orientation: "Defense",
        painRate: 177.47,
        indexation: 0.8873252625,
      },
      {
        name: "Perry",
        orientation: "Defense",
        painRate: 161.72,
        indexation: 0.8086148084,
      },
      {
        name: "Pickaway",
        orientation: "Defense",
        painRate: 203.11,
        indexation: 1.015530734,
      },
      {
        name: "Pike",
        orientation: "Neutral",
        painRate: 147.22,
        indexation: 0.7361246739,
      },
      {
        name: "Portage",
        orientation: "Defense",
        painRate: 195.21,
        indexation: 0.9760551134,
      },
      {
        name: "Preble",
        orientation: "Defense",
        painRate: 180.87,
        indexation: 0.9043542238,
      },
      {
        name: "Putnam",
        orientation: "Defense",
        painRate: 218.79,
        indexation: 1.093973647,
      },
      {
        name: "Richland",
        orientation: "Defense",
        painRate: 146.9,
        indexation: 0.734479299,
      },
      {
        name: "Ross",
        orientation: "Neutral",
        painRate: 161.47,
        indexation: 0.8073306133,
      },
      {
        name: "Sandusky",
        orientation: "Neutral",
        painRate: 167.14,
        indexation: 0.8357032974,
      },
      {
        name: "Scioto",
        orientation: "Neutral",
        painRate: 129.66,
        indexation: 0.6483178383,
      },
      {
        name: "Seneca",
        orientation: "Defense",
        painRate: 169.14,
        indexation: 0.8457093171,
      },
      {
        name: "Shelby",
        orientation: "Defense",
        painRate: 193.96,
        indexation: 0.9698080396,
      },
      {
        name: "Stark",
        orientation: "Plaintiff",
        painRate: 172.72,
        indexation: 0.8635810314,
      },
      {
        name: "Summit",
        orientation: "Plaintiff",
        painRate: 176.67,
        indexation: 0.8833389071,
      },
      {
        name: "Trumbull",
        orientation: "Plaintiff",
        painRate: 145.56,
        indexation: 0.7277907832,
      },
      {
        name: "Tuscarawas",
        orientation: "Neutral",
        painRate: 159.52,
        indexation: 0.7976188884,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 279.61,
        indexation: 1.39806033,
      },
      {
        name: "Van Wert",
        orientation: "Defense",
        painRate: 158.86,
        indexation: 0.7942880075,
      },
      {
        name: "Vinton",
        orientation: "Defense",
        painRate: 136.49,
        indexation: 0.6824292689,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 279.64,
        indexation: 1.398207478,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 158.37,
        indexation: 0.7918667648,
      },
      {
        name: "Wayne",
        orientation: "Neutral",
        painRate: 187.6,
        indexation: 0.9380108354,
      },
      {
        name: "Williams",
        orientation: "Defense",
        painRate: 163.15,
        indexation: 0.8157715203,
      },
      {
        name: "Wood",
        orientation: "Neutral",
        painRate: 179.93,
        indexation: 0.8996588857,
      },
      {
        name: "Wyandot",
        orientation: "Defense",
        painRate: 175.59,
        indexation: 0.8779479633,
      },
    ],
  },
  OK: {
    name: "OK",
    counties: [
      {
        name: "Oklahoma",
        orientation: "Orientation",
        painRate: 159.54,
        indexation: 0.7976991506,
      },
      {
        name: "Adair",
        orientation: "Neutral",
        painRate: 127.88,
        indexation: 0.6394087352,
      },
      {
        name: "Alfalfa",
        orientation: "Defense",
        painRate: 155.29,
        indexation: 0.7764698014,
      },
      {
        name: "Atoka",
        orientation: "Plaintiff",
        painRate: 123.5,
        indexation: 0.6174837803,
      },
      {
        name: "Beaver",
        orientation: "Defense",
        painRate: 172.38,
        indexation: 0.8618955254,
      },
      {
        name: "Beckham",
        orientation: "Neutral",
        painRate: 142.11,
        indexation: 0.7105745435,
      },
      {
        name: "Blaine",
        orientation: "Defense",
        painRate: 146.12,
        indexation: 0.7305999599,
      },
      {
        name: "Bryan",
        orientation: "Plaintiff",
        painRate: 143.89,
        indexation: 0.7194435155,
      },
      {
        name: "Caddo",
        orientation: "Neutral",
        painRate: 136.64,
        indexation: 0.6831783827,
      },
      {
        name: "Canadian",
        orientation: "Defense",
        painRate: 218.74,
        indexation: 1.093719484,
      },
      {
        name: "Carter",
        orientation: "Neutral",
        painRate: 149.96,
        indexation: 0.7498093773,
      },
      {
        name: "Cherokee",
        orientation: "Neutral",
        painRate: 132.17,
        indexation: 0.6608654939,
      },
      {
        name: "Choctaw",
        orientation: "Plaintiff",
        painRate: 112.36,
        indexation: 0.5618219517,
      },
      {
        name: "Cimarron",
        orientation: "Defense",
        painRate: 136.18,
        indexation: 0.6808909103,
      },
      {
        name: "Cleveland",
        orientation: "Plaintiff",
        painRate: 189.75,
        indexation: 0.9487392148,
      },
      {
        name: "Coal",
        orientation: "Neutral",
        painRate: 117.82,
        indexation: 0.5891244733,
      },
      {
        name: "Comanche",
        orientation: "Plaintiff",
        painRate: 148.75,
        indexation: 0.743762959,
      },
      {
        name: "Cotton",
        orientation: "Neutral",
        painRate: 136.54,
        indexation: 0.6827101866,
      },
      {
        name: "Craig",
        orientation: "Neutral",
        painRate: 130.89,
        indexation: 0.6544311417,
      },
      {
        name: "Creek",
        orientation: "Plaintiff",
        painRate: 153.96,
        indexation: 0.7697812855,
      },
      {
        name: "Custer",
        orientation: "Defense",
        painRate: 144.62,
        indexation: 0.7231088222,
      },
      {
        name: "Delaware",
        orientation: "Neutral",
        painRate: 146.61,
        indexation: 0.7330479567,
      },
      {
        name: "Dewey",
        orientation: "Defense",
        painRate: 148.34,
        indexation: 0.7416761421,
      },
      {
        name: "Ellis",
        orientation: "Defense",
        painRate: 144.7,
        indexation: 0.7235235101,
      },
      {
        name: "Garfield",
        orientation: "Defense",
        painRate: 148.53,
        indexation: 0.7426259113,
      },
      {
        name: "Garvin",
        orientation: "Neutral",
        painRate: 131.75,
        indexation: 0.6587519229,
      },
      {
        name: "Grady",
        orientation: "Neutral",
        painRate: 179.06,
        indexation: 0.8953247274,
      },
      {
        name: "Grant",
        orientation: "Defense",
        painRate: 148.91,
        indexation: 0.7445254498,
      },
      {
        name: "Greer",
        orientation: "Neutral",
        painRate: 108.47,
        indexation: 0.5423717477,
      },
      {
        name: "Harmon",
        orientation: "Neutral",
        painRate: 110.15,
        indexation: 0.5507323925,
      },
      {
        name: "Harper",
        orientation: "Defense",
        painRate: 174.51,
        indexation: 0.8725570196,
      },
      {
        name: "Haskell",
        orientation: "Neutral",
        painRate: 119.15,
        indexation: 0.595759481,
      },
      {
        name: "Hughes",
        orientation: "Neutral",
        painRate: 117.11,
        indexation: 0.5855528058,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 143.17,
        indexation: 0.715845094,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 118.22,
        indexation: 0.591104274,
      },
      {
        name: "Johnston",
        orientation: "Neutral",
        painRate: 129.63,
        indexation: 0.6481439369,
      },
      {
        name: "Kay",
        orientation: "Defense",
        painRate: 141.97,
        indexation: 0.7098655608,
      },
      {
        name: "Kingfisher",
        orientation: "Defense",
        painRate: 195.37,
        indexation: 0.9768309812,
      },
      {
        name: "Kiowa",
        orientation: "Neutral",
        painRate: 120.28,
        indexation: 0.6014179654,
      },
      {
        name: "Latimer",
        orientation: "Plaintiff",
        painRate: 113.46,
        indexation: 0.5673065347,
      },
      {
        name: "Le Flore",
        orientation: "Plaintiff",
        painRate: 125.81,
        indexation: 0.6290682897,
      },
      {
        name: "Lincoln",
        orientation: "Defense",
        painRate: 146.94,
        indexation: 0.7346933315,
      },
      {
        name: "Logan",
        orientation: "Defense",
        painRate: 204.22,
        indexation: 1.021082202,
      },
      {
        name: "Love",
        orientation: "Neutral",
        painRate: 173.16,
        indexation: 0.8658016186,
      },
      {
        name: "McClain",
        orientation: "Neutral",
        painRate: 201.92,
        indexation: 1.009618086,
      },
      {
        name: "McCurtain",
        orientation: "Plaintiff",
        painRate: 118.96,
        indexation: 0.5948097117,
      },
      {
        name: "McIntosh",
        orientation: "Neutral",
        painRate: 116.92,
        indexation: 0.5845762825,
      },
      {
        name: "Major",
        orientation: "Defense",
        painRate: 170.8,
        indexation: 0.8539896997,
      },
      {
        name: "Marshall",
        orientation: "Plaintiff",
        painRate: 137.36,
        indexation: 0.6867901813,
      },
      {
        name: "Mayes",
        orientation: "Neutral",
        painRate: 134.24,
        indexation: 0.6711791853,
      },
      {
        name: "Murray",
        orientation: "Neutral",
        painRate: 161.35,
        indexation: 0.8067286469,
      },
      {
        name: "Muskogee",
        orientation: "Neutral",
        painRate: 141.89,
        indexation: 0.7094508729,
      },
      {
        name: "Noble",
        orientation: "Defense",
        painRate: 164.31,
        indexation: 0.821550398,
      },
      {
        name: "Nowata",
        orientation: "Defense",
        painRate: 145.38,
        indexation: 0.7268945221,
      },
      {
        name: "Okfuskee",
        orientation: "Neutral",
        painRate: 116.5,
        indexation: 0.5824894656,
      },
      {
        name: "Oklahoma",
        orientation: "Plaintiff",
        painRate: 164.75,
        indexation: 0.8237576082,
      },
      {
        name: "Okmulgee",
        orientation: "Plaintiff",
        painRate: 122.41,
        indexation: 0.6120527055,
      },
      {
        name: "Osage",
        orientation: "Neutral",
        painRate: 156.98,
        indexation: 0.7849240853,
      },
      {
        name: "Ottawa",
        orientation: "Defense",
        painRate: 128.03,
        indexation: 0.640157849,
      },
      {
        name: "Pawnee",
        orientation: "Defense",
        painRate: 146.32,
        indexation: 0.7315898602,
      },
      {
        name: "Payne",
        orientation: "Neutral",
        painRate: 137.08,
        indexation: 0.6853855929,
      },
      {
        name: "Pittsburg",
        orientation: "Plaintiff",
        painRate: 128.23,
        indexation: 0.6411611263,
      },
      {
        name: "Pontotoc",
        orientation: "Plaintiff",
        painRate: 150.03,
        indexation: 0.7501438031,
      },
      {
        name: "Pottawatomie",
        orientation: "Neutral",
        painRate: 140.98,
        indexation: 0.7049026821,
      },
      {
        name: "Pushmataha",
        orientation: "Plaintiff",
        painRate: 105.06,
        indexation: 0.5252759013,
      },
      {
        name: "Roger Mills",
        orientation: "Defense",
        painRate: 147.64,
        indexation: 0.7381847368,
      },
      {
        name: "Rogers",
        orientation: "Defense",
        painRate: 195.18,
        indexation: 0.975881212,
      },
      {
        name: "Seminole",
        orientation: "Neutral",
        painRate: 118.02,
        indexation: 0.5901143736,
      },
      {
        name: "Sequoyah",
        orientation: "Neutral",
        painRate: 126.95,
        indexation: 0.6347401512,
      },
      {
        name: "Stephens",
        orientation: "Neutral",
        painRate: 147.97,
        indexation: 0.7398702428,
      },
      {
        name: "Texas",
        orientation: "Defense",
        painRate: 162.45,
        indexation: 0.8122399839,
      },
      {
        name: "Tillman",
        orientation: "Neutral",
        painRate: 118.61,
        indexation: 0.5930439435,
      },
      {
        name: "Tulsa",
        orientation: "Neutral",
        painRate: 169.73,
        indexation: 0.8486656411,
      },
      {
        name: "Wagoner",
        orientation: "Defense",
        painRate: 197.58,
        indexation: 0.9879071634,
      },
      {
        name: "Washington",
        orientation: "Defense",
        painRate: 165.08,
        indexation: 0.8254163601,
      },
      {
        name: "Washita",
        orientation: "Neutral",
        painRate: 146.58,
        indexation: 0.7329141863,
      },
      {
        name: "Woods",
        orientation: "Defense",
        painRate: 144.53,
        indexation: 0.722627249,
      },
      {
        name: "Woodward",
        orientation: "Neutral",
        painRate: 163.86,
        indexation: 0.8193164337,
      },
    ],
  },
  OR: {
    name: "OR",
    counties: [
      {
        name: "Oregon ",
        orientation: "Orientation",
        painRate: 202.36,
        indexation: 1.011811919,
      },
      {
        name: "Baker",
        orientation: "Defense",
        painRate: 145.49,
        indexation: 0.7274697345,
      },
      {
        name: "Benton",
        orientation: "Defense",
        painRate: 187.59,
        indexation: 0.9379573273,
      },
      {
        name: "Clackamas",
        orientation: "Neutral",
        painRate: 259.29,
        indexation: 1.296435021,
      },
      {
        name: "Clatsop",
        orientation: "Neutral",
        painRate: 170.34,
        indexation: 0.8516754732,
      },
      {
        name: "Columbia",
        orientation: "Neutral",
        painRate: 207.54,
        indexation: 1.037683098,
      },
      {
        name: "Coos",
        orientation: "Neutral",
        painRate: 149.78,
        indexation: 0.7488863621,
      },
      {
        name: "Crook",
        orientation: "Defense",
        painRate: 186.11,
        indexation: 0.9305464517,
      },
      {
        name: "Curry",
        orientation: "Defense",
        painRate: 146.06,
        indexation: 0.7303190422,
      },
      {
        name: "Deschutes",
        orientation: "Neutral",
        painRate: 225.49,
        indexation: 1.127429603,
      },
      {
        name: "Douglas",
        orientation: "Neutral",
        painRate: 149.39,
        indexation: 0.7469333155,
      },
      {
        name: "Gilliam",
        orientation: "Defense",
        painRate: 184.7,
        indexation: 0.9235235101,
      },
      {
        name: "Grant",
        orientation: "Defense",
        painRate: 156.1,
        indexation: 0.7805230419,
      },
      {
        name: "Harney",
        orientation: "Defense",
        painRate: 149.83,
        indexation: 0.7491271487,
      },
      {
        name: "Hood River",
        orientation: "Defense",
        painRate: 202.84,
        indexation: 1.014219785,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 182.57,
        indexation: 0.9128352619,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 175.51,
        indexation: 0.8775332754,
      },
      {
        name: "Josephine",
        orientation: "Defense",
        painRate: 147.1,
        indexation: 0.7355093305,
      },
      {
        name: "Klamath",
        orientation: "Defense",
        painRate: 147.04,
        indexation: 0.7352150358,
      },
      {
        name: "Lake",
        orientation: "Defense",
        painRate: 146.36,
        indexation: 0.7318172697,
      },
      {
        name: "Lane",
        orientation: "Plaintiff",
        painRate: 171.74,
        indexation: 0.8586984148,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 153.08,
        indexation: 0.7653802421,
      },
      {
        name: "Linn",
        orientation: "Defense",
        painRate: 179.96,
        indexation: 0.899806033,
      },
      {
        name: "Malheur",
        orientation: "Defense",
        painRate: 132.11,
        indexation: 0.6605578222,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 189.58,
        indexation: 0.9479098388,
      },
      {
        name: "Morrow",
        orientation: "Defense",
        painRate: 180.65,
        indexation: 0.9032706842,
      },
      {
        name: "Multnomah",
        orientation: "Plaintiff",
        painRate: 212.51,
        indexation: 1.062564377,
      },
      {
        name: "Polk",
        orientation: "Defense",
        painRate: 206.42,
        indexation: 1.032118253,
      },
      {
        name: "Sherman",
        orientation: "Defense",
        painRate: 191.18,
        indexation: 0.9559226808,
      },
      {
        name: "Tillamook",
        orientation: "Neutral",
        painRate: 173.57,
        indexation: 0.8678616815,
      },
      {
        name: "Umatilla",
        orientation: "Defense",
        painRate: 189.99,
        indexation: 0.9499297706,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 152.74,
        indexation: 0.7637081132,
      },
      {
        name: "Wallowa",
        orientation: "Defense",
        painRate: 164.93,
        indexation: 0.8246672463,
      },
      {
        name: "Wasco",
        orientation: "Defense",
        painRate: 161.88,
        indexation: 0.8093906762,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 264.08,
        indexation: 1.320393285,
      },
      {
        name: "Wheeler",
        orientation: "Defense",
        painRate: 133.01,
        indexation: 0.6650525048,
      },
      {
        name: "Yamhill",
        orientation: "Defense",
        painRate: 208.6,
        indexation: 1.04299378,
      },
    ],
  },
  PA: {
    name: "PA",
    counties: [
      {
        name: "Pennsylvania ",
        orientation: "Orientation",
        painRate: 192.06,
        indexation: 0.9603237242,
      },
      {
        name: "Adams",
        orientation: "Defense",
        painRate: 204.81,
        indexation: 1.02406528,
      },
      {
        name: "Allegheny",
        orientation: "Neutral",
        painRate: 192.56,
        indexation: 0.962785098,
      },
      {
        name: "Armstrong",
        orientation: "Defense",
        painRate: 154.13,
        indexation: 0.7706374156,
      },
      {
        name: "Beaver",
        orientation: "Defense",
        painRate: 179.61,
        indexation: 0.8980670189,
      },
      {
        name: "Bedford",
        orientation: "Defense",
        painRate: 148.53,
        indexation: 0.7426526654,
      },
      {
        name: "Berks",
        orientation: "Neutral",
        painRate: 193.05,
        indexation: 0.9652464718,
      },
      {
        name: "Blair",
        orientation: "Neutral",
        painRate: 154.86,
        indexation: 0.7743160993,
      },
      {
        name: "Bradford",
        orientation: "Defense",
        painRate: 169.97,
        indexation: 0.8498294428,
      },
      {
        name: "Bucks",
        orientation: "Neutral",
        painRate: 281.46,
        indexation: 1.407290482,
      },
      {
        name: "Butler",
        orientation: "Defense",
        painRate: 218.06,
        indexation: 1.090321718,
      },
      {
        name: "Cambria",
        orientation: "Neutral",
        painRate: 146.11,
        indexation: 0.7305464517,
      },
      {
        name: "Cameron",
        orientation: "Defense",
        painRate: 134.67,
        indexation: 0.6733730185,
      },
      {
        name: "Carbon",
        orientation: "Defense",
        painRate: 173.28,
        indexation: 0.866376831,
      },
      {
        name: "Centre",
        orientation: "Plaintiff",
        painRate: 181,
        indexation: 0.9050096983,
      },
      {
        name: "Chester",
        orientation: "Neutral",
        painRate: 313.89,
        indexation: 1.569473614,
      },
      {
        name: "Clarion",
        orientation: "Defense",
        painRate: 154.76,
        indexation: 0.773781018,
      },
      {
        name: "Clearfield",
        orientation: "Defense",
        painRate: 158.7,
        indexation: 0.7934987626,
      },
      {
        name: "Clinton",
        orientation: "Defense",
        painRate: 157.56,
        indexation: 0.7878001471,
      },
      {
        name: "Columbia",
        orientation: "Defense",
        painRate: 158.19,
        indexation: 0.7909437496,
      },
      {
        name: "Crawford",
        orientation: "Defense",
        painRate: 146.54,
        indexation: 0.7327135309,
      },
      {
        name: "Cumberland",
        orientation: "Defense",
        painRate: 217.5,
        indexation: 1.087485787,
      },
      {
        name: "Dauphin",
        orientation: "Neutral",
        painRate: 181.43,
        indexation: 0.9071366464,
      },
      {
        name: "Delaware",
        orientation: "Plaintiff",
        painRate: 224.35,
        indexation: 1.121744365,
      },
      {
        name: "Elk",
        orientation: "Defense",
        painRate: 154.02,
        indexation: 0.7701157113,
      },
      {
        name: "Erie",
        orientation: "Neutral",
        painRate: 151.13,
        indexation: 0.7556551401,
      },
      {
        name: "Fayette",
        orientation: "Defense",
        painRate: 137.77,
        indexation: 0.6888368671,
      },
      {
        name: "Forest",
        orientation: "Defense",
        painRate: 116.01,
        indexation: 0.5800548458,
      },
      {
        name: "Franklin",
        orientation: "Defense",
        painRate: 197.2,
        indexation: 0.9860076249,
      },
      {
        name: "Fulton",
        orientation: "Defense",
        painRate: 172.31,
        indexation: 0.8615477226,
      },
      {
        name: "Greene",
        orientation: "Defense",
        painRate: 168.77,
        indexation: 0.8438499097,
      },
      {
        name: "Huntingdon",
        orientation: "Defense",
        painRate: 155.63,
        indexation: 0.7781419303,
      },
      {
        name: "Indiana",
        orientation: "Defense",
        painRate: 141.9,
        indexation: 0.709517758,
      },
      {
        name: "Jefferson",
        orientation: "Defense",
        painRate: 145.14,
        indexation: 0.7256772122,
      },
      {
        name: "Juniata",
        orientation: "Defense",
        painRate: 167.64,
        indexation: 0.8382181794,
      },
      {
        name: "Lackawanna",
        orientation: "Neutral",
        painRate: 163.65,
        indexation: 0.8182462712,
      },
      {
        name: "Lancaster",
        orientation: "Defense",
        painRate: 220.54,
        indexation: 1.102722226,
      },
      {
        name: "Lawrence",
        orientation: "Neutral",
        painRate: 157.13,
        indexation: 0.785633068,
      },
      {
        name: "Lebanon",
        orientation: "Defense",
        painRate: 185.91,
        indexation: 0.9295699284,
      },
      {
        name: "Lehigh",
        orientation: "Neutral",
        painRate: 194.21,
        indexation: 0.9710387265,
      },
      {
        name: "Luzerne",
        orientation: "Neutral",
        painRate: 158.81,
        indexation: 0.7940338439,
      },
      {
        name: "Lycoming",
        orientation: "Defense",
        painRate: 165.15,
        indexation: 0.8257507859,
      },
      {
        name: "McKean",
        orientation: "Defense",
        painRate: 155.47,
        indexation: 0.7773660625,
      },
      {
        name: "Mercer",
        orientation: "Defense",
        painRate: 154.38,
        indexation: 0.7718814795,
      },
      {
        name: "Mifflin",
        orientation: "Defense",
        painRate: 152.75,
        indexation: 0.7637749983,
      },
      {
        name: "Monroe",
        orientation: "Defense",
        painRate: 213.56,
        indexation: 1.067808173,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 285.58,
        indexation: 1.427904488,
      },
      {
        name: "Montour",
        orientation: "Defense",
        painRate: 203.94,
        indexation: 1.019717745,
      },
      {
        name: "Northampton",
        orientation: "Defense",
        painRate: 201.91,
        indexation: 1.009537824,
      },
      {
        name: "Northumberland",
        orientation: "Defense",
        painRate: 154.7,
        indexation: 0.7734867233,
      },
      {
        name: "Perry",
        orientation: "Defense",
        painRate: 181.48,
        indexation: 0.907417564,
      },
      {
        name: "Philadelphia",
        orientation: "Plaintiff",
        painRate: 150.85,
        indexation: 0.7542639288,
      },
      {
        name: "Pike",
        orientation: "Defense",
        painRate: 219.08,
        indexation: 1.095391613,
      },
      {
        name: "Potter",
        orientation: "Defense",
        painRate: 144.3,
        indexation: 0.7214902013,
      },
      {
        name: "Schuylkill",
        orientation: "Neutral",
        painRate: 170.37,
        indexation: 0.8518493746,
      },
      {
        name: "Snyder",
        orientation: "Defense",
        painRate: 166.9,
        indexation: 0.8344859876,
      },
      {
        name: "Somerset",
        orientation: "Defense",
        painRate: 135.15,
        indexation: 0.6757541302,
      },
      {
        name: "Sullivan",
        orientation: "Defense",
        painRate: 160.41,
        indexation: 0.8020466858,
      },
      {
        name: "Susquehanna",
        orientation: "Defense",
        painRate: 169.66,
        indexation: 0.8482777072,
      },
      {
        name: "Tioga",
        orientation: "Defense",
        painRate: 163.71,
        indexation: 0.8185405658,
      },
      {
        name: "Union",
        orientation: "Defense",
        painRate: 175.3,
        indexation: 0.8764764899,
      },
      {
        name: "Venango",
        orientation: "Defense",
        painRate: 140.32,
        indexation: 0.7016119323,
      },
      {
        name: "Warren",
        orientation: "Defense",
        painRate: 151.71,
        indexation: 0.758571333,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 189.29,
        indexation: 0.9464383653,
      },
      {
        name: "Wayne",
        orientation: "Defense",
        painRate: 152.43,
        indexation: 0.7621296234,
      },
      {
        name: "Westmoreland",
        orientation: "Plaintiff",
        painRate: 190.36,
        indexation: 0.951802555,
      },
      {
        name: "Wyoming",
        orientation: "Defense",
        painRate: 180.97,
        indexation: 0.904862551,
      },
      {
        name: "York",
        orientation: "Defense",
        painRate: 213.87,
        indexation: 1.069333155,
      },
    ],
  },
  RI: {
    name: "RI",
    counties: [
      {
        name: "Rhode Island ",
        orientation: "Orientation",
        painRate: 216.37,
        indexation: 1.08184068,
      },
      {
        name: "Bristol",
        orientation: "Neutral",
        painRate: 270.64,
        indexation: 1.353193766,
      },
      {
        name: "Kent",
        orientation: "Neutral",
        painRate: 225.35,
        indexation: 1.126747375,
      },
      {
        name: "Newport",
        orientation: "Neutral",
        painRate: 248.26,
        indexation: 1.241321651,
      },
      {
        name: "Providence",
        orientation: "Plaintiff",
        painRate: 198.49,
        indexation: 0.9924419771,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 264.91,
        indexation: 1.324526788,
      },
    ],
  },
  SC: {
    name: "SC",
    counties: [
      {
        name: "South Carolina",
        orientation: "Orientation",
        painRate: 171.83,
        indexation: 0.8591666109,
      },
      {
        name: "Abbeville",
        orientation: "Neutral",
        painRate: 134.64,
        indexation: 0.6731991171,
      },
      {
        name: "Aiken",
        orientation: "Defense",
        painRate: 181.2,
        indexation: 0.9059995987,
      },
      {
        name: "Allendale",
        orientation: "Plaintiff",
        painRate: 94.31,
        indexation: 0.4715269882,
      },
      {
        name: "Anderson",
        orientation: "Neutral",
        painRate: 167.77,
        indexation: 0.8388468999,
      },
      {
        name: "Bamberg",
        orientation: "Neutral",
        painRate: 101.54,
        indexation: 0.5076851047,
      },
      {
        name: "Barnwell",
        orientation: "Plaintiff",
        painRate: 120.61,
        indexation: 0.6030365862,
      },
      {
        name: "Beaufort",
        orientation: "Neutral",
        painRate: 223.9,
        indexation: 1.119483647,
      },
      {
        name: "Berkeley",
        orientation: "Neutral",
        painRate: 212.05,
        indexation: 1.06025015,
      },
      {
        name: "Calhoun",
        orientation: "Plaintiff",
        painRate: 148.86,
        indexation: 0.7443247943,
      },
      {
        name: "Charleston",
        orientation: "Neutral",
        painRate: 213.95,
        indexation: 1.069747843,
      },
      {
        name: "Cherokee",
        orientation: "Neutral",
        painRate: 134.85,
        indexation: 0.6742425256,
      },
      {
        name: "Chester",
        orientation: "Neutral",
        painRate: 143.1,
        indexation: 0.7155106682,
      },
      {
        name: "Chesterfield",
        orientation: "Neutral",
        painRate: 119.25,
        indexation: 0.5962678082,
      },
      {
        name: "Clarendon",
        orientation: "Plaintiff",
        painRate: 123.61,
        indexation: 0.6180456157,
      },
      {
        name: "Colleton",
        orientation: "Plaintiff",
        painRate: 128.24,
        indexation: 0.6412012574,
      },
      {
        name: "Darlington",
        orientation: "Plaintiff",
        painRate: 134.91,
        indexation: 0.6745635743,
      },
      {
        name: "Dillon",
        orientation: "Plaintiff",
        painRate: 101,
        indexation: 0.5050096983,
      },
      {
        name: "Dorchester",
        orientation: "Neutral",
        painRate: 199.93,
        indexation: 0.9996655742,
      },
      {
        name: "Edgefield",
        orientation: "Neutral",
        painRate: 164.05,
        indexation: 0.8202528259,
      },
      {
        name: "Fairfield",
        orientation: "Plaintiff",
        painRate: 135.76,
        indexation: 0.6787907163,
      },
      {
        name: "Florence",
        orientation: "Neutral",
        painRate: 140.86,
        indexation: 0.7043007157,
      },
      {
        name: "Georgetown",
        orientation: "Neutral",
        painRate: 159.07,
        indexation: 0.79535817,
      },
      {
        name: "Greenville",
        orientation: "Defense",
        painRate: 192.18,
        indexation: 0.9609123136,
      },
      {
        name: "Greenwood",
        orientation: "Defense",
        painRate: 133.41,
        indexation: 0.6670724366,
      },
      {
        name: "Hampton",
        orientation: "Plaintiff",
        painRate: 117.68,
        indexation: 0.5884154906,
      },
      {
        name: "Horry",
        orientation: "Neutral",
        painRate: 164.2,
        indexation: 0.8210153167,
      },
      {
        name: "Jasper",
        orientation: "Plaintiff",
        painRate: 165.44,
        indexation: 0.8272088823,
      },
      {
        name: "Kershaw",
        orientation: "Neutral",
        painRate: 163.24,
        indexation: 0.8161995853,
      },
      {
        name: "Lancaster",
        orientation: "Neutral",
        painRate: 187.58,
        indexation: 0.9379038191,
      },
      {
        name: "Laurens",
        orientation: "Defense",
        painRate: 144.06,
        indexation: 0.7203130225,
      },
      {
        name: "Lee",
        orientation: "Plaintiff",
        painRate: 111.25,
        indexation: 0.5562571065,
      },
      {
        name: "Lexington",
        orientation: "Defense",
        painRate: 195.36,
        indexation: 0.9768176042,
      },
      {
        name: "McCormick",
        orientation: "Neutral",
        painRate: 151.52,
        indexation: 0.7576215638,
      },
      {
        name: "Marion",
        orientation: "Plaintiff",
        painRate: 99.64,
        indexation: 0.4982141663,
      },
      {
        name: "Marlboro",
        orientation: "Plaintiff",
        painRate: 104.77,
        indexation: 0.5238445589,
      },
      {
        name: "Newberry",
        orientation: "Defense",
        painRate: 147.28,
        indexation: 0.7364055916,
      },
      {
        name: "Oconee",
        orientation: "Neutral",
        painRate: 165.12,
        indexation: 0.8255768845,
      },
      {
        name: "Orangeburg",
        orientation: "Plaintiff",
        painRate: 112.93,
        indexation: 0.5646311284,
      },
      {
        name: "Pickens",
        orientation: "Neutral",
        painRate: 148.04,
        indexation: 0.7401912916,
      },
      {
        name: "Richland",
        orientation: "Plaintiff",
        painRate: 163.23,
        indexation: 0.8161327002,
      },
      {
        name: "Saluda",
        orientation: "Neutral",
        painRate: 153.28,
        indexation: 0.7663835195,
      },
      {
        name: "Spartanburg",
        orientation: "Defense",
        painRate: 156.6,
        indexation: 0.7830245469,
      },
      {
        name: "Sumter",
        orientation: "Plaintiff",
        painRate: 152.33,
        indexation: 0.7616480503,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 124.3,
        indexation: 0.6215102669,
      },
      {
        name: "Williamsburg",
        orientation: "Plaintiff",
        painRate: 106.37,
        indexation: 0.5318306468,
      },
      {
        name: "York",
        orientation: "Neutral",
        painRate: 223.91,
        indexation: 1.119550532,
      },
    ],
  },
  SD: {
    name: "SD",
    counties: [
      {
        name: "South Dakota ",
        orientation: "Orientation",
        painRate: 188.88,
        indexation: 0.9443783025,
      },
      {
        name: "Aurora",
        orientation: "Neutral",
        painRate: 163.23,
        indexation: 0.8161728312,
      },
      {
        name: "Beadle",
        orientation: "Neutral",
        painRate: 159.21,
        indexation: 0.7960403986,
      },
      {
        name: "Bennett",
        orientation: "Plaintiff",
        painRate: 131.5,
        indexation: 0.657507859,
      },
      {
        name: "Bon Homme",
        orientation: "Plaintiff",
        painRate: 168.45,
        indexation: 0.8422580429,
      },
      {
        name: "Brookings",
        orientation: "Neutral",
        painRate: 190.4,
        indexation: 0.9519898335,
      },
      {
        name: "Brown",
        orientation: "Plaintiff",
        painRate: 191.91,
        indexation: 0.9595478563,
      },
      {
        name: "Brule",
        orientation: "Neutral",
        painRate: 160.88,
        indexation: 0.8044010434,
      },
      {
        name: "Buffalo",
        orientation: "Neutral",
        painRate: 82.03,
        indexation: 0.4101264129,
      },
      {
        name: "Butte",
        orientation: "Neutral",
        painRate: 172.64,
        indexation: 0.8631930975,
      },
      {
        name: "Campbell",
        orientation: "Neutral",
        painRate: 175.48,
        indexation: 0.8774128821,
      },
      {
        name: "Charles Mix",
        orientation: "Plaintiff",
        painRate: 145.45,
        indexation: 0.727269079,
      },
      {
        name: "Clark",
        orientation: "Neutral",
        painRate: 167.37,
        indexation: 0.8368537222,
      },
      {
        name: "Clay",
        orientation: "Plaintiff",
        painRate: 147.85,
        indexation: 0.7392548993,
      },
      {
        name: "Codington",
        orientation: "Neutral",
        painRate: 173.93,
        indexation: 0.8696274497,
      },
      {
        name: "Corson",
        orientation: "Neutral",
        painRate: 105.22,
        indexation: 0.5261052772,
      },
      {
        name: "Custer",
        orientation: "Neutral",
        painRate: 201.55,
        indexation: 1.007772055,
      },
      {
        name: "Davison",
        orientation: "Neutral",
        painRate: 170.57,
        indexation: 0.8528258979,
      },
      {
        name: "Day",
        orientation: "Neutral",
        painRate: 167.98,
        indexation: 0.8398903083,
      },
      {
        name: "Deuel",
        orientation: "Neutral",
        painRate: 207.86,
        indexation: 1.039315096,
      },
      {
        name: "Dewey",
        orientation: "Neutral",
        painRate: 133.9,
        indexation: 0.6694936794,
      },
      {
        name: "Douglas",
        orientation: "Neutral",
        painRate: 197.8,
        indexation: 0.989017457,
      },
      {
        name: "Edmunds",
        orientation: "Neutral",
        painRate: 192.64,
        indexation: 0.963199786,
      },
      {
        name: "Fall River",
        orientation: "Plaintiff",
        painRate: 152.43,
        indexation: 0.7621697545,
      },
      {
        name: "Faulk",
        orientation: "Neutral",
        painRate: 162.81,
        indexation: 0.8140325062,
      },
      {
        name: "Grant",
        orientation: "Neutral",
        painRate: 188.37,
        indexation: 0.9418634205,
      },
      {
        name: "Gregory",
        orientation: "Neutral",
        painRate: 131.73,
        indexation: 0.6586716608,
      },
      {
        name: "Haakon",
        orientation: "Neutral",
        painRate: 157.31,
        indexation: 0.7865560832,
      },
      {
        name: "Hamlin",
        orientation: "Neutral",
        painRate: 197.97,
        indexation: 0.989833456,
      },
      {
        name: "Hand",
        orientation: "Neutral",
        painRate: 172.66,
        indexation: 0.8633001137,
      },
      {
        name: "Hanson",
        orientation: "Neutral",
        painRate: 212.66,
        indexation: 1.063313491,
      },
      {
        name: "Harding",
        orientation: "Neutral",
        painRate: 174.35,
        indexation: 0.8717543977,
      },
      {
        name: "Hughes",
        orientation: "Neutral",
        painRate: 212.16,
        indexation: 1.060811986,
      },
      {
        name: "Hutchinson",
        orientation: "Neutral",
        painRate: 172.36,
        indexation: 0.8617751321,
      },
      {
        name: "Hyde",
        orientation: "Neutral",
        painRate: 172.34,
        indexation: 0.8616948699,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 120.9,
        indexation: 0.6045080597,
      },
      {
        name: "Jerauld",
        orientation: "Neutral",
        painRate: 154,
        indexation: 0.769981941,
      },
      {
        name: "Jones",
        orientation: "Neutral",
        painRate: 162.84,
        indexation: 0.8141796535,
      },
      {
        name: "Kingsbury",
        orientation: "Neutral",
        painRate: 171.8,
        indexation: 0.8590060865,
      },
      {
        name: "Lake",
        orientation: "Neutral",
        painRate: 199.77,
        indexation: 0.9988361982,
      },
      {
        name: "Lawrence",
        orientation: "Neutral",
        painRate: 180.87,
        indexation: 0.9043676008,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 263.23,
        indexation: 1.316126012,
      },
      {
        name: "Lyman",
        orientation: "Neutral",
        painRate: 145,
        indexation: 0.7250217377,
      },
      {
        name: "McCook",
        orientation: "Neutral",
        painRate: 188.13,
        indexation: 0.9406728647,
      },
      {
        name: "McPherson",
        orientation: "Neutral",
        painRate: 141.4,
        indexation: 0.706976122,
      },
      {
        name: "Marshall",
        orientation: "Neutral",
        painRate: 175.62,
        indexation: 0.8781084877,
      },
      {
        name: "Meade",
        orientation: "Neutral",
        painRate: 190.24,
        indexation: 0.9511872116,
      },
      {
        name: "Mellette",
        orientation: "Neutral",
        painRate: 119.6,
        indexation: 0.5979800682,
      },
      {
        name: "Miner",
        orientation: "Neutral",
        painRate: 171.49,
        indexation: 0.8574543509,
      },
      {
        name: "Minnehaha",
        orientation: "Neutral",
        painRate: 202.32,
        indexation: 1.011611263,
      },
      {
        name: "Moody",
        orientation: "Neutral",
        painRate: 188.89,
        indexation: 0.9444585646,
      },
      {
        name: "Pennington",
        orientation: "Neutral",
        painRate: 108.84,
        indexation: 0.5442177781,
      },
      {
        name: "Perkins",
        orientation: "Neutral",
        painRate: 180.79,
        indexation: 0.9039261588,
      },
      {
        name: "Potter",
        orientation: "Neutral",
        painRate: 152.89,
        indexation: 0.764470604,
      },
      {
        name: "Roberts",
        orientation: "Neutral",
        painRate: 193.63,
        indexation: 0.9681492877,
      },
      {
        name: "Sanborn",
        orientation: "Neutral",
        painRate: 154.61,
        indexation: 0.7730319042,
      },
      {
        name: "Shannon",
        orientation: "Plaintiff",
        painRate: 169.26,
        indexation: 0.8463112835,
      },
      {
        name: "Spink",
        orientation: "Neutral",
        painRate: 164.57,
        indexation: 0.8228613471,
      },
      {
        name: "Stanley",
        orientation: "Neutral",
        painRate: 218.82,
        indexation: 1.094080664,
      },
      {
        name: "Sully",
        orientation: "Neutral",
        painRate: 203.84,
        indexation: 1.019222794,
      },
      {
        name: "Todd",
        orientation: "Plaintiff",
        painRate: 112,
        indexation: 0.5599892984,
      },
      {
        name: "Tripp",
        orientation: "Plaintiff",
        painRate: 149.68,
        indexation: 0.7483914119,
      },
      {
        name: "Turner",
        orientation: "Neutral",
        painRate: 199.35,
        indexation: 0.9967360043,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 229.22,
        indexation: 1.14610394,
      },
      {
        name: "Walworth",
        orientation: "Neutral",
        painRate: 152.77,
        indexation: 0.7638285065,
      },
      {
        name: "Yankton",
        orientation: "Plaintiff",
        painRate: 168.42,
        indexation: 0.8420975186,
      },
      {
        name: "Ziebach",
        orientation: "Neutral",
        painRate: 107.03,
        indexation: 0.5351347736,
      },
    ],
  },
  TN: {
    name: "TN",
    counties: [
      {
        name: "Tennessee ",
        orientation: "Orientation",
        painRate: 174.52,
        indexation: 0.8725971507,
      },
      {
        name: "Anderson",
        orientation: "Neutral",
        painRate: 172.87,
        indexation: 0.8643568992,
      },
      {
        name: "Bedford",
        orientation: "Neutral",
        painRate: 187.77,
        indexation: 0.9388669654,
      },
      {
        name: "Benton",
        orientation: "Neutral",
        painRate: 135.47,
        indexation: 0.6773459969,
      },
      {
        name: "Bledsoe",
        orientation: "Neutral",
        painRate: 123.62,
        indexation: 0.6181125008,
      },
      {
        name: "Blount",
        orientation: "Neutral",
        painRate: 186.51,
        indexation: 0.9325530065,
      },
      {
        name: "Bradley",
        orientation: "Neutral",
        painRate: 167.66,
        indexation: 0.8382850645,
      },
      {
        name: "Campbell",
        orientation: "Defense Oriented",
        painRate: 118.43,
        indexation: 0.5921744365,
      },
      {
        name: "Cannon",
        orientation: "Neutral",
        painRate: 168.97,
        indexation: 0.844826433,
      },
      {
        name: "Carroll",
        orientation: "Neutral",
        painRate: 140.94,
        indexation: 0.7047020266,
      },
      {
        name: "Carter",
        orientation: "Neutral",
        painRate: 130.86,
        indexation: 0.6542973714,
      },
      {
        name: "Cheatham",
        orientation: "Neutral",
        painRate: 209.85,
        indexation: 1.04925423,
      },
      {
        name: "Chester",
        orientation: "Neutral",
        painRate: 154.46,
        indexation: 0.7722827904,
      },
      {
        name: "Claiborne",
        orientation: "Defense",
        painRate: 109.92,
        indexation: 0.5496220989,
      },
      {
        name: "Clay",
        orientation: "Defense",
        painRate: 116.22,
        indexation: 0.5811116313,
      },
      {
        name: "Cocke",
        orientation: "Defense",
        painRate: 121.36,
        indexation: 0.6067955321,
      },
      {
        name: "Coffee",
        orientation: "Defense",
        painRate: 151.21,
        indexation: 0.7560564511,
      },
      {
        name: "Crockett",
        orientation: "Defense",
        painRate: 143.4,
        indexation: 0.7169821417,
      },
      {
        name: "Cumberland",
        orientation: "Defense",
        painRate: 142.83,
        indexation: 0.714172965,
      },
      {
        name: "Davidson",
        orientation: "Plaintiff Oriented ",
        painRate: 194.13,
        indexation: 0.9706507926,
      },
      {
        name: "Decatur",
        orientation: "Neutral",
        painRate: 139.91,
        indexation: 0.6995384924,
      },
      {
        name: "DeKalb",
        orientation: "Neutral",
        painRate: 148.2,
        indexation: 0.7409805364,
      },
      {
        name: "Dickson",
        orientation: "Neutral",
        painRate: 187.79,
        indexation: 0.9389606046,
      },
      {
        name: "Dyer",
        orientation: "Defense",
        painRate: 143.71,
        indexation: 0.7185472544,
      },
      {
        name: "Fayette",
        orientation: "Neutral",
        painRate: 225.61,
        indexation: 1.128058324,
      },
      {
        name: "Fentress",
        orientation: "Neutral",
        painRate: 110.08,
        indexation: 0.5504247208,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 164.32,
        indexation: 0.821577152,
      },
      {
        name: "Gibson",
        orientation: "Defense",
        painRate: 148.77,
        indexation: 0.7438699753,
      },
      {
        name: "Giles",
        orientation: "Neutral",
        painRate: 146.3,
        indexation: 0.731509598,
      },
      {
        name: "Grainger",
        orientation: "Neutral",
        painRate: 138.99,
        indexation: 0.6949501706,
      },
      {
        name: "Greene",
        orientation: "Neutral",
        painRate: 134.87,
        indexation: 0.6743361648,
      },
      {
        name: "Grundy",
        orientation: "Defense",
        painRate: 118.3,
        indexation: 0.5915189619,
      },
      {
        name: "Hamblen",
        orientation: "Neutral",
        painRate: 151.79,
        indexation: 0.7589325129,
      },
      {
        name: "Hamilton",
        orientation: "Neutral",
        painRate: 201,
        indexation: 1.004976256,
      },
      {
        name: "Hancock",
        orientation: "Defense",
        painRate: 101.73,
        indexation: 0.508648251,
      },
      {
        name: "Hardeman",
        orientation: "Plaintiff Oriented ",
        painRate: 118.93,
        indexation: 0.5946625644,
      },
      {
        name: "Hardin",
        orientation: "Neutral",
        painRate: 113.9,
        indexation: 0.5695003679,
      },
      {
        name: "Hawkins",
        orientation: "Defense",
        painRate: 141.31,
        indexation: 0.706561434,
      },
      {
        name: "Haywood",
        orientation: "Plaintiff Oriented ",
        painRate: 124.01,
        indexation: 0.6200387934,
      },
      {
        name: "Henderson",
        orientation: "Neutral",
        painRate: 144,
        indexation: 0.7199785967,
      },
      {
        name: "Henry",
        orientation: "Defense",
        painRate: 136.96,
        indexation: 0.6848237576,
      },
      {
        name: "Hickman",
        orientation: "Defense",
        painRate: 152.28,
        indexation: 0.7613805097,
      },
      {
        name: "Houston",
        orientation: "Neutral",
        painRate: 162.08,
        indexation: 0.8103939536,
      },
      {
        name: "Humphreys",
        orientation: "Neutral",
        painRate: 157.63,
        indexation: 0.7881345729,
      },
      {
        name: "Jackson",
        orientation: "Neutral",
        painRate: 115.86,
        indexation: 0.5793057321,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 162.62,
        indexation: 0.813122868,
      },
      {
        name: "Johnson",
        orientation: "Neutral",
        painRate: 121.72,
        indexation: 0.6085880543,
      },
      {
        name: "Knox",
        orientation: "Defense",
        painRate: 187.31,
        indexation: 0.936566116,
      },
      {
        name: "Lake",
        orientation: "Defense",
        painRate: 101.9,
        indexation: 0.509504381,
      },
      {
        name: "Lauderdale",
        orientation: "Neutral",
        painRate: 127.28,
        indexation: 0.6364122801,
      },
      {
        name: "Lawrence",
        orientation: "Neutral",
        painRate: 148.65,
        indexation: 0.7432546318,
      },
      {
        name: "Lewis",
        orientation: "Neutral",
        painRate: 142.3,
        indexation: 0.7115109357,
      },
      {
        name: "Lincoln",
        orientation: "Neutral",
        painRate: 164.78,
        indexation: 0.8239047555,
      },
      {
        name: "Loudon",
        orientation: "Neutral",
        painRate: 213.18,
        indexation: 1.065922012,
      },
      {
        name: "McMinn",
        orientation: "Neutral",
        painRate: 163.07,
        indexation: 0.8153300783,
      },
      {
        name: "McNairy",
        orientation: "Neutral",
        painRate: 132.98,
        indexation: 0.6649187345,
      },
      {
        name: "Macon",
        orientation: "Defense",
        painRate: 152.01,
        indexation: 0.7600428065,
      },
      {
        name: "Madison",
        orientation: "Plaintiff Oriented ",
        painRate: 152.69,
        indexation: 0.7634271955,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 157.01,
        indexation: 0.7850444786,
      },
      {
        name: "Marshall",
        orientation: "Neutral",
        painRate: 171.26,
        indexation: 0.8563039262,
      },
      {
        name: "Maury",
        orientation: "Defense Oriented",
        painRate: 187.88,
        indexation: 0.9394020467,
      },
      {
        name: "Meigs",
        orientation: "Neutral",
        painRate: 145.16,
        indexation: 0.7257976055,
      },
      {
        name: "Monroe",
        orientation: "Defense Oriented",
        painRate: 149.95,
        indexation: 0.7497424921,
      },
      {
        name: "Montgomery",
        orientation: "Defense Oriented",
        painRate: 180.53,
        indexation: 0.9026553408,
      },
      {
        name: "Moore",
        orientation: "Neutral",
        painRate: 182.16,
        indexation: 0.910788576,
      },
      {
        name: "Morgan",
        orientation: "Neutral",
        painRate: 160.44,
        indexation: 0.8021804562,
      },
      {
        name: "Obion",
        orientation: "Defense Oriented",
        painRate: 134.2,
        indexation: 0.6709785299,
      },
      {
        name: "Overton",
        orientation: "Neutral",
        painRate: 139.41,
        indexation: 0.6970503645,
      },
      {
        name: "Perry",
        orientation: "Neutral",
        painRate: 124.27,
        indexation: 0.6213363655,
      },
      {
        name: "Pickett",
        orientation: "Defense Oriented",
        painRate: 128.24,
        indexation: 0.6411878804,
      },
      {
        name: "Polk",
        orientation: "Defense Oriented",
        painRate: 147.54,
        indexation: 0.7376897866,
      },
      {
        name: "Putnam",
        orientation: "Neutral",
        painRate: 147.14,
        indexation: 0.7356832319,
      },
      {
        name: "Rhea",
        orientation: "Neutral",
        painRate: 158.4,
        indexation: 0.7920139121,
      },
      {
        name: "Roane",
        orientation: "Neutral",
        painRate: 169.7,
        indexation: 0.8485051167,
      },
      {
        name: "Robertson",
        orientation: "Defense Oriented",
        painRate: 206.88,
        indexation: 1.034392348,
      },
      {
        name: "Rutherford",
        orientation: "Neutral",
        painRate: 206.86,
        indexation: 1.034312086,
      },
      {
        name: "Scott",
        orientation: "Defense Oriented",
        painRate: 119.32,
        indexation: 0.596602234,
      },
      {
        name: "Sequatchie",
        orientation: "Neutral",
        painRate: 150.53,
        indexation: 0.752645308,
      },
      {
        name: "Sevier",
        orientation: "Defense Oriented",
        painRate: 159.27,
        indexation: 0.7963346933,
      },
      {
        name: "Shelby",
        orientation: "Plaintiff Oriented ",
        painRate: 164.41,
        indexation: 0.8220453481,
      },
      {
        name: "Smith",
        orientation: "Neutral",
        painRate: 171.12,
        indexation: 0.8555949435,
      },
      {
        name: "Stewart",
        orientation: "Defense Oriented",
        painRate: 161.17,
        indexation: 0.8058591399,
      },
      {
        name: "Sullivan",
        orientation: "Neutral",
        painRate: 145.44,
        indexation: 0.7271888168,
      },
      {
        name: "Sumner",
        orientation: "Defense Oriented",
        painRate: 233.97,
        indexation: 1.169861548,
      },
      {
        name: "Tipton",
        orientation: "Neutral",
        painRate: 184.01,
        indexation: 0.9200454819,
      },
      {
        name: "Trousdale",
        orientation: "Neutral",
        painRate: 156.74,
        indexation: 0.7836800214,
      },
      {
        name: "Unicoi",
        orientation: "Neutral",
        painRate: 139.82,
        indexation: 0.6990836733,
      },
      {
        name: "Union",
        orientation: "Neutral",
        painRate: 136.9,
        indexation: 0.6844893318,
      },
      {
        name: "Van Buren",
        orientation: "Neutral",
        painRate: 134.47,
        indexation: 0.6723296101,
      },
      {
        name: "Warren",
        orientation: "Neutral",
        painRate: 140.51,
        indexation: 0.7025617016,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 171.95,
        indexation: 0.8597418233,
      },
      {
        name: "Wayne",
        orientation: "Neutral",
        painRate: 129.28,
        indexation: 0.6463915457,
      },
      {
        name: "Weakley",
        orientation: "Defense Oriented",
        painRate: 133.28,
        indexation: 0.666376831,
      },
      {
        name: "White",
        orientation: "Neutral",
        painRate: 132.19,
        indexation: 0.6609725102,
      },
      {
        name: "Williamson",
        orientation: "Defense Oriented",
        painRate: 357.13,
        indexation: 1.785646445,
      },
      {
        name: "Wilson",
        orientation: "Neutral",
        painRate: 236.67,
        indexation: 1.183358973,
      },
    ],
  },
  TX: {
    name: "TX",
    counties: [
      {
        name: "Texas ",
        orientation: "Orientation",
        painRate: 193.38,
        indexation: 0.9668784697,
      },
      {
        name: "Anderson",
        orientation: "Defense ",
        painRate: 162.66,
        indexation: 0.8133235235,
      },
      {
        name: "Andrews",
        orientation: "Defense ",
        painRate: 184.6,
        indexation: 0.9229884289,
      },
      {
        name: "Angelina",
        orientation: "Neutral",
        painRate: 152.03,
        indexation: 0.7601498228,
      },
      {
        name: "Aransas",
        orientation: "Defense ",
        painRate: 161.92,
        indexation: 0.8096180857,
      },
      {
        name: "Archer",
        orientation: "Defense ",
        painRate: 192.8,
        indexation: 0.9639756538,
      },
      {
        name: "Armstrong",
        orientation: "Defense ",
        painRate: 180.72,
        indexation: 0.903591733,
      },
      {
        name: "Atascosa",
        orientation: "Neutral",
        painRate: 160.03,
        indexation: 0.8001739014,
      },
      {
        name: "Austin",
        orientation: "Defense ",
        painRate: 183.61,
        indexation: 0.9180656812,
      },
      {
        name: "Bailey",
        orientation: "Defense ",
        painRate: 141.13,
        indexation: 0.7056517959,
      },
      {
        name: "Bandera",
        orientation: "Neutral",
        painRate: 185.36,
        indexation: 0.9268142599,
      },
      {
        name: "Bastrop",
        orientation: "Defense ",
        painRate: 196.38,
        indexation: 0.9819142532,
      },
      {
        name: "Baylor",
        orientation: "Defense",
        painRate: 131.25,
        indexation: 0.6562637951,
      },
      {
        name: "Bee",
        orientation: "Neutral",
        painRate: 128.89,
        indexation: 0.6444518761,
      },
      {
        name: "Bell",
        orientation: "Defense ",
        painRate: 166.98,
        indexation: 0.8348872985,
      },
      {
        name: "Bexar",
        orientation: "Plaintiff",
        painRate: 176.15,
        indexation: 0.8807303859,
      },
      {
        name: "Blanco",
        orientation: "Defense ",
        painRate: 220.01,
        indexation: 1.100073574,
      },
      {
        name: "Borden",
        orientation: "Defense ",
        painRate: 201.15,
        indexation: 1.005765501,
      },
      {
        name: "Bosque",
        orientation: "Defense ",
        painRate: 163.33,
        indexation: 0.8166410274,
      },
      {
        name: "Bowie",
        orientation: "Defense ",
        painRate: 140.35,
        indexation: 0.7017590797,
      },
      {
        name: "Brazoria",
        orientation: "Plaintiff",
        painRate: 230.61,
        indexation: 1.153059996,
      },
      {
        name: "Brazos",
        orientation: "Defense ",
        painRate: 161.47,
        indexation: 0.8073707444,
      },
      {
        name: "Brewster",
        orientation: "Neutral",
        painRate: 140.16,
        indexation: 0.7007959334,
      },
      {
        name: "Briscoe",
        orientation: "Defense ",
        painRate: 142.35,
        indexation: 0.7117650993,
      },
      {
        name: "Brooks",
        orientation: "Neutral",
        painRate: 94.6,
        indexation: 0.4729984616,
      },
      {
        name: "Brown",
        orientation: "Neutral",
        painRate: 143.01,
        indexation: 0.7150692261,
      },
      {
        name: "Burleson",
        orientation: "Defense ",
        painRate: 166.13,
        indexation: 0.8306467795,
      },
      {
        name: "Burnet",
        orientation: "Defense ",
        painRate: 200.38,
        indexation: 1.001899538,
      },
      {
        name: "Caldwell",
        orientation: "Defense ",
        painRate: 162.75,
        indexation: 0.8137649656,
      },
      {
        name: "Calhoun",
        orientation: "Plaintiff",
        painRate: 167.86,
        indexation: 0.8393017189,
      },
      {
        name: "Callahan",
        orientation: "Defense ",
        painRate: 170.79,
        indexation: 0.8539361916,
      },
      {
        name: "Cameron",
        orientation: "Plaintiff",
        painRate: 132.65,
        indexation: 0.6632733596,
      },
      {
        name: "Camp",
        orientation: "Neutral",
        painRate: 141.58,
        indexation: 0.7078991372,
      },
      {
        name: "Carson",
        orientation: "Defense ",
        painRate: 192.37,
        indexation: 0.9618353287,
      },
      {
        name: "Cass",
        orientation: "Defense ",
        painRate: 145.21,
        indexation: 0.7260517691,
      },
      {
        name: "Castro",
        orientation: "Defense ",
        painRate: 160.41,
        indexation: 0.8020600629,
      },
      {
        name: "Chambers",
        orientation: "Plaintiff ",
        painRate: 273.28,
        indexation: 1.366410274,
      },
      {
        name: "Cherokee",
        orientation: "Plaintiff ",
        painRate: 134.71,
        indexation: 0.6735469199,
      },
      {
        name: "Childress",
        orientation: "Defense ",
        painRate: 131.63,
        indexation: 0.6581499565,
      },
      {
        name: "Clay",
        orientation: "Defense ",
        painRate: 176.8,
        indexation: 0.8840211357,
      },
      {
        name: "Cochran",
        orientation: "Defense",
        painRate: 122.44,
        indexation: 0.6122132299,
      },
      {
        name: "Coke",
        orientation: "Defense ",
        painRate: 117.11,
        indexation: 0.5855661829,
      },
      {
        name: "Coleman",
        orientation: "Defense ",
        painRate: 122.62,
        indexation: 0.613122868,
      },
      {
        name: "Collin",
        orientation: "Neutral",
        painRate: 304.84,
        indexation: 1.524219116,
      },
      {
        name: "Collingsworth",
        orientation: "Defense ",
        painRate: 139.37,
        indexation: 0.696849709,
      },
      {
        name: "Colorado",
        orientation: "Defense ",
        painRate: 159.75,
        indexation: 0.7987559361,
      },
      {
        name: "Comal",
        orientation: "Neutral",
        painRate: 248.76,
        indexation: 1.243783025,
      },
      {
        name: "Comanche",
        orientation: "Neutral",
        painRate: 137.94,
        indexation: 0.6897063742,
      },
      {
        name: "Concho",
        orientation: "Neutral",
        painRate: 136.96,
        indexation: 0.6847836265,
      },
      {
        name: "Cooke",
        orientation: "Defense ",
        painRate: 177.82,
        indexation: 0.8891177848,
      },
      {
        name: "Coryell",
        orientation: "Defense ",
        painRate: 169.7,
        indexation: 0.8484783627,
      },
      {
        name: "Cottle",
        orientation: "Defense ",
        painRate: 121.33,
        indexation: 0.6066617618,
      },
      {
        name: "Crane",
        orientation: "Neutral",
        painRate: 181.63,
        indexation: 0.9081533008,
      },
      {
        name: "Crockett",
        orientation: "Defense ",
        painRate: 192.23,
        indexation: 0.9611531001,
      },
      {
        name: "Crosby",
        orientation: "Defense ",
        painRate: 124.29,
        indexation: 0.6214567587,
      },
      {
        name: "Culberson",
        orientation: "Defense ",
        painRate: 139.55,
        indexation: 0.6977459702,
      },
      {
        name: "Dallam",
        orientation: "Defense ",
        painRate: 164.9,
        indexation: 0.824506722,
      },
      {
        name: "Dallas",
        orientation: "Plaintiff",
        painRate: 189.61,
        indexation: 0.9480436091,
      },
      {
        name: "Dawson",
        orientation: "Plaintiff ",
        painRate: 130.56,
        indexation: 0.6528125209,
      },
      {
        name: "Deaf Smith",
        orientation: "Defense ",
        painRate: 148.66,
        indexation: 0.7432947629,
      },
      {
        name: "Delta",
        orientation: "Defense ",
        painRate: 146.04,
        indexation: 0.730212026,
      },
      {
        name: "Denton",
        orientation: "Neutral",
        painRate: 274.79,
        indexation: 1.373968296,
      },
      {
        name: "DeWitt",
        orientation: "Plaintiff ",
        painRate: 148.55,
        indexation: 0.7427596816,
      },
      {
        name: "Dickens",
        orientation: "Defense ",
        painRate: 125.94,
        indexation: 0.6297103873,
      },
      {
        name: "Dimmit",
        orientation: "Plaintiff",
        painRate: 118.55,
        indexation: 0.5927630259,
      },
      {
        name: "Donley",
        orientation: "Defense ",
        painRate: 120.03,
        indexation: 0.6001739014,
      },
      {
        name: "Duval",
        orientation: "Plaintiff ",
        painRate: 108.46,
        indexation: 0.5422914855,
      },
      {
        name: "Eastland",
        orientation: "Defense ",
        painRate: 142.74,
        indexation: 0.7136780148,
      },
      {
        name: "Ector",
        orientation: "Plaintiff ",
        painRate: 173.55,
        indexation: 0.8677412882,
      },
      {
        name: "Edwards",
        orientation: "Defense ",
        painRate: 135.78,
        indexation: 0.6789111096,
      },
      {
        name: "Ellis",
        orientation: "Neutral",
        painRate: 238.95,
        indexation: 1.194756204,
      },
      {
        name: "El Paso",
        orientation: "Plaintiff ",
        painRate: 142.98,
        indexation: 0.7148819477,
      },
      {
        name: "Erath",
        orientation: "Neutral",
        painRate: 160.77,
        indexation: 0.8038659621,
      },
      {
        name: "Falls",
        orientation: "Plaintiff ",
        painRate: 127.17,
        indexation: 0.6358638218,
      },
      {
        name: "Fannin",
        orientation: "Defense ",
        painRate: 163.09,
        indexation: 0.8154504715,
      },
      {
        name: "Fayette",
        orientation: "Defense ",
        painRate: 188.55,
        indexation: 0.9427596816,
      },
      {
        name: "Fisher",
        orientation: "Neutral",
        painRate: 154.82,
        indexation: 0.7740886897,
      },
      {
        name: "Floyd",
        orientation: "Defense ",
        painRate: 122.49,
        indexation: 0.6124540165,
      },
      {
        name: "Foard",
        orientation: "Defense ",
        painRate: 127.5,
        indexation: 0.6375091967,
      },
      {
        name: "Fort Bend",
        orientation: "Plaintiff",
        painRate: 282.48,
        indexation: 1.412387131,
      },
      {
        name: "Franklin",
        orientation: "Defense ",
        painRate: 151.49,
        indexation: 0.7574476624,
      },
      {
        name: "Freestone",
        orientation: "Neutral",
        painRate: 150.82,
        indexation: 0.7540900274,
      },
      {
        name: "Frio",
        orientation: "Neutral",
        painRate: 125.13,
        indexation: 0.6256303926,
      },
      {
        name: "Gaines",
        orientation: "Plaintiff ",
        painRate: 167.12,
        indexation: 0.8355962812,
      },
      {
        name: "Galveston",
        orientation: "Plantiff ",
        painRate: 203.19,
        indexation: 1.015945422,
      },
      {
        name: "Garza",
        orientation: "Defense ",
        painRate: 134.45,
        indexation: 0.6722627249,
      },
      {
        name: "Gillespie",
        orientation: "Defense",
        painRate: 206.04,
        indexation: 1.030205337,
      },
      {
        name: "Glasscock",
        orientation: "Plaintiff ",
        painRate: 258.26,
        indexation: 1.291324995,
      },
      {
        name: "Goliad",
        orientation: "Plaintiff",
        painRate: 168.13,
        indexation: 0.8406260451,
      },
      {
        name: "Gonzales",
        orientation: "Plaintiff ",
        painRate: 153.9,
        indexation: 0.7694869908,
      },
      {
        name: "Gray",
        orientation: "Defense ",
        painRate: 135.5,
        indexation: 0.6775198983,
      },
      {
        name: "Grayson",
        orientation: "Defense ",
        painRate: 176.95,
        indexation: 0.8847702495,
      },
      {
        name: "Gregg",
        orientation: "Plaintiff ",
        painRate: 163.02,
        indexation: 0.8150892917,
      },
      {
        name: "Grimes",
        orientation: "Plaintiff",
        painRate: 163.7,
        indexation: 0.8185138118,
      },
      {
        name: "Guadalupe",
        orientation: "Plaintiff ",
        painRate: 230.2,
        indexation: 1.150999933,
      },
      {
        name: "Hale",
        orientation: "Defense ",
        painRate: 132.62,
        indexation: 0.6631128353,
      },
      {
        name: "Hall",
        orientation: "Defense ",
        painRate: 108.83,
        indexation: 0.5441375159,
      },
      {
        name: "Hamilton",
        orientation: "Defense ",
        painRate: 159.86,
        indexation: 0.7993177714,
      },
      {
        name: "Hansford",
        orientation: "Defense ",
        painRate: 184.77,
        indexation: 0.923871313,
      },
      {
        name: "Hardeman",
        orientation: "Defense ",
        painRate: 132.54,
        indexation: 0.6626847702,
      },
      {
        name: "Hardin",
        orientation: "Plaintiff",
        painRate: 188.43,
        indexation: 0.9421710922,
      },
      {
        name: "Harris",
        orientation: "Plaintiff",
        painRate: 183.93,
        indexation: 0.919644171,
      },
      {
        name: "Harrison",
        orientation: "Plaintiff",
        painRate: 172.93,
        indexation: 0.8646378169,
      },
      {
        name: "Hartley",
        orientation: "Defense ",
        painRate: 225.18,
        indexation: 1.125877868,
      },
      {
        name: "Haskell",
        orientation: "Defense ",
        painRate: 129.99,
        indexation: 0.6499498361,
      },
      {
        name: "Hays",
        orientation: "Defense ",
        painRate: 238.31,
        indexation: 1.191545716,
      },
      {
        name: "Hemphill",
        orientation: "Defense ",
        painRate: 195.87,
        indexation: 0.9793592402,
      },
      {
        name: "Henderson",
        orientation: "Neutral",
        painRate: 168.13,
        indexation: 0.8406527991,
      },
      {
        name: "Hidalgo",
        orientation: "Plaintiff ",
        painRate: 130.63,
        indexation: 0.6531335697,
      },
      {
        name: "Hill",
        orientation: "defense ",
        painRate: 154.01,
        indexation: 0.7700622032,
      },
      {
        name: "Hockley",
        orientation: "Neutral",
        painRate: 152.29,
        indexation: 0.7614607719,
      },
      {
        name: "Hood",
        orientation: "Neutral",
        painRate: 202.11,
        indexation: 1.010567855,
      },
      {
        name: "Hopkins",
        orientation: "Neutral",
        painRate: 171.89,
        indexation: 0.8594609056,
      },
      {
        name: "Houston",
        orientation: "Neutral",
        painRate: 133.08,
        indexation: 0.6653869306,
      },
      {
        name: "Howard",
        orientation: "Defense ",
        painRate: 159.45,
        indexation: 0.7972443315,
      },
      {
        name: "Hudspeth",
        orientation: "Defense ",
        painRate: 105.9,
        indexation: 0.5294896662,
      },
      {
        name: "Hunt",
        orientation: "Defense ",
        painRate: 181.98,
        indexation: 0.9098923149,
      },
      {
        name: "Hutchinson",
        orientation: "Defense ",
        painRate: 173.95,
        indexation: 0.86976122,
      },
      {
        name: "Irion",
        orientation: "Neutral",
        painRate: 208.06,
        indexation: 1.040304996,
      },
      {
        name: "Jack",
        orientation: "Neutral",
        painRate: 144.83,
        indexation: 0.7241254766,
      },
      {
        name: "Jackson",
        orientation: "Plaintiff",
        painRate: 165.75,
        indexation: 0.828747241,
      },
      {
        name: "Jasper",
        orientation: "Plaintiff",
        painRate: 135.81,
        indexation: 0.679058257,
      },
      {
        name: "Jeff Davis",
        orientation: "Defense ",
        painRate: 155.94,
        indexation: 0.7796802889,
      },
      {
        name: "Jefferson",
        orientation: "Plaintiff ",
        painRate: 147.32,
        indexation: 0.7366062471,
      },
      {
        name: "Jim Hogg",
        orientation: "Plaintiff ",
        painRate: 117.13,
        indexation: 0.5856731991,
      },
      {
        name: "Jim Wells",
        orientation: "Plaintiff ",
        painRate: 129.09,
        indexation: 0.6454685305,
      },
      {
        name: "Johnson",
        orientation: "Neutral",
        painRate: 205.33,
        indexation: 1.026647047,
      },
      {
        name: "Jones",
        orientation: "Plaintiff ",
        painRate: 138.84,
        indexation: 0.6941876798,
      },
      {
        name: "Karnes",
        orientation: "Neutral",
        painRate: 165.5,
        indexation: 0.827503177,
      },
      {
        name: "Kaufman",
        orientation: "Neutral",
        painRate: 248.32,
        indexation: 1.241575814,
      },
      {
        name: "Kendall",
        orientation: "Neutral",
        painRate: 321.69,
        indexation: 1.60842753,
      },
      {
        name: "Kenedy",
        orientation: "Plaintiff",
        painRate: 115.78,
        indexation: 0.578877667,
      },
      {
        name: "Kent",
        orientation: "Defense",
        painRate: 155.49,
        indexation: 0.7774463247,
      },
      {
        name: "Kerr",
        orientation: "Neutral",
        painRate: 159.51,
        indexation: 0.7975252491,
      },
      {
        name: "Kimble",
        orientation: "Defense",
        painRate: 138.72,
        indexation: 0.6936124674,
      },
      {
        name: "King",
        orientation: "Defense",
        painRate: 235.05,
        indexation: 1.175252491,
      },
      {
        name: "Kinney",
        orientation: "Defense ",
        painRate: 140.75,
        indexation: 0.7037522574,
      },
      {
        name: "Kleberg",
        orientation: "Plaintiff",
        painRate: 137.36,
        indexation: 0.6868035583,
      },
      {
        name: "Knox",
        orientation: "Neutral",
        painRate: 126.3,
        indexation: 0.6314895325,
      },
      {
        name: "Lamar",
        orientation: "Defense ",
        painRate: 167.53,
        indexation: 0.8376697211,
      },
      {
        name: "Lamb",
        orientation: "Defense ",
        painRate: 141.82,
        indexation: 0.709089693,
      },
      {
        name: "Lampasas",
        orientation: "Defense ",
        painRate: 181.71,
        indexation: 0.9085278577,
      },
      {
        name: "La Salle",
        orientation: "Neutral",
        painRate: 108.5,
        indexation: 0.542505518,
      },
      {
        name: "Lavaca",
        orientation: "Neutral",
        painRate: 156.24,
        indexation: 0.7812186476,
      },
      {
        name: "Lee",
        orientation: "Defense ",
        painRate: 176.34,
        indexation: 0.8816935322,
      },
      {
        name: "Leon",
        orientation: "Plaintiff ",
        painRate: 151.83,
        indexation: 0.7591331683,
      },
      {
        name: "Liberty",
        orientation: "Neutral",
        painRate: 165.55,
        indexation: 0.8277707177,
      },
      {
        name: "Limestone",
        orientation: "Plaintiff ",
        painRate: 137.4,
        indexation: 0.6870042138,
      },
      {
        name: "Lipscomb",
        orientation: "Defense ",
        painRate: 187.91,
        indexation: 0.939549194,
      },
      {
        name: "Live Oak",
        orientation: "Neutral",
        painRate: 156.71,
        indexation: 0.7835730052,
      },
      {
        name: "Llano",
        orientation: "Defense ",
        painRate: 159.26,
        indexation: 0.7963079393,
      },
      {
        name: "Loving",
        orientation: "Neutral",
        painRate: 253.4,
        indexation: 1.267018928,
      },
      {
        name: "Lubbock",
        orientation: "Defense ",
        painRate: 158.22,
        indexation: 0.7910908969,
      },
      {
        name: "Lynn",
        orientation: "Defense ",
        painRate: 151.84,
        indexation: 0.7592000535,
      },
      {
        name: "McCulloch",
        orientation: "Defense ",
        painRate: 122.84,
        indexation: 0.6142064076,
      },
      {
        name: "McLennan",
        orientation: "Neutral",
        painRate: 168.98,
        indexation: 0.8448933182,
      },
      {
        name: "McMullen",
        orientation: "Defense ",
        painRate: 180.66,
        indexation: 0.9032840613,
      },
      {
        name: "Madison",
        orientation: "Plaintiff ",
        painRate: 144.22,
        indexation: 0.7211022674,
      },
      {
        name: "Marion",
        orientation: "Plaintiff",
        painRate: 127.67,
        indexation: 0.6383519497,
      },
      {
        name: "Martin",
        orientation: "Neutral",
        painRate: 196.15,
        indexation: 0.9807370744,
      },
      {
        name: "Mason",
        orientation: "Defense ",
        painRate: 158.19,
        indexation: 0.7909705036,
      },
      {
        name: "Matagorda",
        orientation: "Plaintiff ",
        painRate: 156.19,
        indexation: 0.7809511069,
      },
      {
        name: "Maverick",
        orientation: "Plaintiff ",
        painRate: 116.13,
        indexation: 0.5806568123,
      },
      {
        name: "Medina",
        orientation: "Plaintiff ",
        painRate: 188.12,
        indexation: 0.9405792255,
      },
      {
        name: "Menard",
        orientation: "Defense ",
        painRate: 118.11,
        indexation: 0.5905290616,
      },
      {
        name: "Midland",
        orientation: "Defense ",
        painRate: 217.21,
        indexation: 1.086041067,
      },
      {
        name: "Milam",
        orientation: "Defense ",
        painRate: 146.26,
        indexation: 0.7312821885,
      },
      {
        name: "Mills",
        orientation: "Defense ",
        painRate: 155.36,
        indexation: 0.7767774731,
      },
      {
        name: "Mitchell",
        orientation: "Neutral",
        painRate: 129.43,
        indexation: 0.6471540365,
      },
      {
        name: "Montague",
        orientation: "Defense ",
        painRate: 152.7,
        indexation: 0.7635074577,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 254.81,
        indexation: 1.27404187,
      },
      {
        name: "Moore",
        orientation: "Defense ",
        painRate: 178.14,
        indexation: 0.8906828975,
      },
      {
        name: "Morris",
        orientation: "Plaintiff ",
        painRate: 133.36,
        indexation: 0.666804896,
      },
      {
        name: "Motley",
        orientation: "Defense ",
        painRate: 123.92,
        indexation: 0.6196241054,
      },
      {
        name: "Nacogdoches",
        orientation: "Defense ",
        painRate: 138.34,
        indexation: 0.6916995519,
      },
      {
        name: "Navarro",
        orientation: "Neutral",
        painRate: 135.97,
        indexation: 0.6798475018,
      },
      {
        name: "Newton",
        orientation: "Neutral",
        painRate: 127.9,
        indexation: 0.6394756204,
      },
      {
        name: "Nolan",
        orientation: "Plaintiff ",
        painRate: 135.32,
        indexation: 0.6765968832,
      },
      {
        name: "Nueces",
        orientation: "Plaintiff ",
        painRate: 161.19,
        indexation: 0.8059661561,
      },
      {
        name: "Ochiltree",
        orientation: "Defense ",
        painRate: 185.63,
        indexation: 0.928138586,
      },
      {
        name: "Oldham",
        orientation: "Defense ",
        painRate: 177.68,
        indexation: 0.888382048,
      },
      {
        name: "Orange",
        orientation: "Plaintiff",
        painRate: 170.96,
        indexation: 0.8548056986,
      },
      {
        name: "Palo Pinto",
        orientation: "Neutral",
        painRate: 151.99,
        indexation: 0.7599357902,
      },
      {
        name: "Panola",
        orientation: "Defense ",
        painRate: 159.91,
        indexation: 0.799571935,
      },
      {
        name: "Parker",
        orientation: "Neutral",
        painRate: 254.9,
        indexation: 1.274523443,
      },
      {
        name: "Parmer",
        orientation: "defense ",
        painRate: 154.6,
        indexation: 0.7730051502,
      },
      {
        name: "Pecos",
        orientation: "Defense ",
        painRate: 144.48,
        indexation: 0.7223998395,
      },
      {
        name: "Polk",
        orientation: "Defense ",
        painRate: 157.57,
        indexation: 0.7878269012,
      },
      {
        name: "Potter",
        orientation: "Plaintiff ",
        painRate: 136.52,
        indexation: 0.6826031704,
      },
      {
        name: "Presidio",
        orientation: "Neutral",
        painRate: 116.82,
        indexation: 0.5841214634,
      },
      {
        name: "Rains",
        orientation: "Defense ",
        painRate: 121.51,
        indexation: 0.6075713999,
      },
      {
        name: "Randall",
        orientation: "Defense ",
        painRate: 206.18,
        indexation: 1.03091432,
      },
      {
        name: "Reagan",
        orientation: "Neutral",
        painRate: 222.58,
        indexation: 1.112902147,
      },
      {
        name: "Real",
        orientation: "Defense ",
        painRate: 132.34,
        indexation: 0.661721624,
      },
      {
        name: "Red River",
        orientation: "Defense ",
        painRate: 125.96,
        indexation: 0.6298040265,
      },
      {
        name: "Reeves",
        orientation: "Defense ",
        painRate: 144.39,
        indexation: 0.7219450204,
      },
      {
        name: "Refugio",
        orientation: "Plaintiff",
        painRate: 136.27,
        indexation: 0.6813724834,
      },
      {
        name: "Roberts",
        orientation: "Defense ",
        painRate: 189.46,
        indexation: 0.9472811183,
      },
      {
        name: "Robertson",
        orientation: "Plaintiff ",
        painRate: 154.63,
        indexation: 0.7731389205,
      },
      {
        name: "Rockwall",
        orientation: "Defense ",
        painRate: 332.53,
        indexation: 1.662644639,
      },
      {
        name: "Runnels",
        orientation: "Defense ",
        painRate: 141.07,
        indexation: 0.7053575012,
      },
      {
        name: "Rusk",
        orientation: "Plaintiff ",
        painRate: 147.37,
        indexation: 0.7368737877,
      },
      {
        name: "Sabine",
        orientation: "Defense ",
        painRate: 135.28,
        indexation: 0.6763962277,
      },
      {
        name: "San Augustine",
        orientation: "Defense ",
        painRate: 132.68,
        indexation: 0.6633803759,
      },
      {
        name: "San Jacinto",
        orientation: "Neutral",
        painRate: 154.79,
        indexation: 0.7739281653,
      },
      {
        name: "San Patricio",
        orientation: "Plaintiff",
        painRate: 168.33,
        indexation: 0.8416694535,
      },
      {
        name: "San Saba",
        orientation: "Defense",
        painRate: 137.38,
        indexation: 0.6869105745,
      },
      {
        name: "Schleicher",
        orientation: "Neutral",
        painRate: 144.92,
        indexation: 0.7246070497,
      },
      {
        name: "Scurry",
        orientation: "Defense ",
        painRate: 161.56,
        indexation: 0.8077988094,
      },
      {
        name: "Shackelford",
        orientation: "Plaintiff ",
        painRate: 173.07,
        indexation: 0.8653601766,
      },
      {
        name: "Shelby",
        orientation: "Neutral",
        painRate: 137.92,
        indexation: 0.6895993579,
      },
      {
        name: "Sherman",
        orientation: "Defense ",
        painRate: 196.36,
        indexation: 0.981807237,
      },
      {
        name: "Smith",
        orientation: "Neutral",
        painRate: 181.87,
        indexation: 0.9093438566,
      },
      {
        name: "Somervell",
        orientation: "Neutral",
        painRate: 202.18,
        indexation: 1.010875527,
      },
      {
        name: "Starr",
        orientation: "Plaintiff ",
        painRate: 95.67,
        indexation: 0.4783358973,
      },
      {
        name: "Stephens",
        orientation: "Defense",
        painRate: 135.67,
        indexation: 0.6783492743,
      },
      {
        name: "Sterling",
        orientation: "Defense",
        painRate: 203.05,
        indexation: 1.015249816,
      },
      {
        name: "Stonewall",
        orientation: "Neutral",
        painRate: 142.21,
        indexation: 0.7110293626,
      },
      {
        name: "Sutton",
        orientation: "Defense ",
        painRate: 184.17,
        indexation: 0.9208481038,
      },
      {
        name: "Swisher",
        orientation: "Defense ",
        painRate: 139.52,
        indexation: 0.6975854458,
      },
      {
        name: "Tarrant",
        orientation: "Neutral",
        painRate: 204.09,
        indexation: 1.020466858,
      },
      {
        name: "Taylor",
        orientation: "Defense ",
        painRate: 165.33,
        indexation: 0.8266738011,
      },
      {
        name: "Terrell",
        orientation: "Neutral",
        painRate: 128.34,
        indexation: 0.6416828306,
      },
      {
        name: "Terry",
        orientation: "Defense ",
        painRate: 125.74,
        indexation: 0.6286937329,
      },
      {
        name: "Throckmorton",
        orientation: "Defense ",
        painRate: 136.44,
        indexation: 0.6821884824,
      },
      {
        name: "Titus",
        orientation: "Defense ",
        painRate: 156.27,
        indexation: 0.7813657949,
      },
      {
        name: "Tom Green",
        orientation: "Neutral",
        painRate: 161.46,
        indexation: 0.8072771052,
      },
      {
        name: "Travis",
        orientation: "Plaintiff",
        painRate: 254.57,
        indexation: 1.272837937,
      },
      {
        name: "Trinity",
        orientation: "Defense ",
        painRate: 132.78,
        indexation: 0.6638887031,
      },
      {
        name: "Tyler",
        orientation: "Plaintiff",
        painRate: 135.7,
        indexation: 0.6785097987,
      },
      {
        name: "Upshur",
        orientation: "Neutral",
        painRate: 159.7,
        indexation: 0.7984750184,
      },
      {
        name: "Upton",
        orientation: "Neutral",
        painRate: 164.82,
        indexation: 0.824118788,
      },
      {
        name: "Uvalde",
        orientation: "Neutral",
        painRate: 129.56,
        indexation: 0.6478095111,
      },
      {
        name: "Val Verde",
        orientation: "Defense ",
        painRate: 140.71,
        indexation: 0.7035382249,
      },
      {
        name: "Van Zandt",
        orientation: "Defense ",
        painRate: 154.39,
        indexation: 0.7719349876,
      },
      {
        name: "Victoria",
        orientation: "Plaintiff",
        painRate: 181.67,
        indexation: 0.9083673333,
      },
      {
        name: "Walker",
        orientation: "Defense ",
        painRate: 142.6,
        indexation: 0.7130091633,
      },
      {
        name: "Waller",
        orientation: "Defense ",
        painRate: 201.25,
        indexation: 1.006260451,
      },
      {
        name: "Ward",
        orientation: "Defense ",
        painRate: 162.6,
        indexation: 0.8129890977,
      },
      {
        name: "Washington",
        orientation: "Defense ",
        painRate: 179.01,
        indexation: 0.8950705638,
      },
      {
        name: "Webb",
        orientation: "Plaintiff ",
        painRate: 150.6,
        indexation: 0.7529797338,
      },
      {
        name: "Wharton",
        orientation: "Neutral",
        painRate: 153.8,
        indexation: 0.7690054177,
      },
      {
        name: "Wheeler",
        orientation: "Defense ",
        painRate: 149.05,
        indexation: 0.7452745636,
      },
      {
        name: "Wichita",
        orientation: "Defense ",
        painRate: 151.52,
        indexation: 0.7575948097,
      },
      {
        name: "Wilbarger",
        orientation: "Neutral",
        painRate: 141.75,
        indexation: 0.7087552672,
      },
      {
        name: "Willacy",
        orientation: "Plaintiff",
        painRate: 111.43,
        indexation: 0.5571266136,
      },
      {
        name: "Williamson",
        orientation: "Neutral",
        painRate: 272.6,
        indexation: 1.36299913,
      },
      {
        name: "Wilson",
        orientation: "Neutral",
        painRate: 212.51,
        indexation: 1.062564377,
      },
      {
        name: "Winkler",
        orientation: "Defense ",
        painRate: 186.68,
        indexation: 0.9333823824,
      },
      {
        name: "Wise",
        orientation: "Plaintiff ",
        painRate: 214.29,
        indexation: 1.071446726,
      },
      {
        name: "Wood",
        orientation: "Neutral",
        painRate: 153.85,
        indexation: 0.7692328272,
      },
      {
        name: "Yoakum",
        orientation: "Neutral",
        painRate: 167.88,
        indexation: 0.8393819811,
      },
      {
        name: "Young",
        orientation: "Neutral",
        painRate: 155.97,
        indexation: 0.7798408133,
      },
      {
        name: "Zapata",
        orientation: "Plaintiff ",
        painRate: 105.42,
        indexation: 0.5270951776,
      },
      {
        name: "Zavala",
        orientation: "Plaintiff ",
        painRate: 96.95,
        indexation: 0.4847434954,
      },
    ],
  },
  UT: {
    name: "UT",
    counties: [
      {
        name: "Utah",
        orientation: "Orientation",
        painRate: 236.86,
        indexation: 1.184281988,
      },
      {
        name: "Beaver",
        orientation: "Defense ",
        painRate: 177.63,
        indexation: 0.8881546385,
      },
      {
        name: "Box Elder",
        orientation: "Defense ",
        painRate: 212.56,
        indexation: 1.062805164,
      },
      {
        name: "Cache",
        orientation: "Defense ",
        painRate: 205.02,
        indexation: 1.025095311,
      },
      {
        name: "Carbon",
        orientation: "Neutral",
        painRate: 158.5,
        indexation: 0.7924954853,
      },
      {
        name: "Daggett",
        orientation: "Defense ",
        painRate: 177.72,
        indexation: 0.8885960805,
      },
      {
        name: "Davis",
        orientation: "Defense ",
        painRate: 275.95,
        indexation: 1.379747174,
      },
      {
        name: "Duchesne",
        orientation: "Defense",
        painRate: 179.94,
        indexation: 0.8996856398,
      },
      {
        name: "Emery",
        orientation: "Defense ",
        painRate: 172.97,
        indexation: 0.8648384723,
      },
      {
        name: "Garfield",
        orientation: "Defense ",
        painRate: 178.68,
        indexation: 0.8933984349,
      },
      {
        name: "Grand",
        orientation: "Defense ",
        painRate: 179.84,
        indexation: 0.8991773126,
      },
      {
        name: "Iron",
        orientation: "Defense ",
        painRate: 177.91,
        indexation: 0.8895592268,
      },
      {
        name: "Juab",
        orientation: "Defense ",
        painRate: 251.97,
        indexation: 1.259862217,
      },
      {
        name: "Kane",
        orientation: "Defense ",
        painRate: 178.21,
        indexation: 0.8910307003,
      },
      {
        name: "Millard",
        orientation: "Defense ",
        painRate: 180.1,
        indexation: 0.9005150157,
      },
      {
        name: "Morgan",
        orientation: "Defense",
        painRate: 319.66,
        indexation: 1.598314494,
      },
      {
        name: "Piute",
        orientation: "Defense ",
        painRate: 145.8,
        indexation: 0.728981339,
      },
      {
        name: "Rich",
        orientation: "Defense ",
        painRate: 192.09,
        indexation: 0.9604708715,
      },
      {
        name: "Salt Lake",
        orientation: "Neutral",
        painRate: 245.37,
        indexation: 1.226847702,
      },
      {
        name: "San Juan",
        orientation: "Defense ",
        painRate: 134.98,
        indexation: 0.6748980001,
      },
      {
        name: "Sanpete",
        orientation: "Defense",
        painRate: 174.02,
        indexation: 0.8700956458,
      },
      {
        name: "Sevier",
        orientation: "Defense ",
        painRate: 195.34,
        indexation: 0.9767105879,
      },
      {
        name: "Summit",
        orientation: "Neutral",
        painRate: 354.11,
        indexation: 1.770557153,
      },
      {
        name: "Tooele",
        orientation: "Neutral",
        painRate: 244.41,
        indexation: 1.222031971,
      },
      {
        name: "Uintah",
        orientation: "Neutral",
        painRate: 180.73,
        indexation: 0.9036719952,
      },
      {
        name: "Utah",
        orientation: "Defense ",
        painRate: 254.39,
        indexation: 1.271955053,
      },
      {
        name: "Wasatch",
        orientation: "Defense ",
        painRate: 309.79,
        indexation: 1.548966624,
      },
      {
        name: "Washington",
        orientation: "Defense ",
        painRate: 202.19,
        indexation: 1.010929035,
      },
      {
        name: "Wayne",
        orientation: "Defense ",
        painRate: 170.44,
        indexation: 0.8521838004,
      },
      {
        name: "Weber",
        orientation: "Neutral",
        painRate: 223.3,
        indexation: 1.116513946,
      },
    ],
  },
  VT: {
    name: "VT",
    counties: [
      {
        name: "Vermont ",
        orientation: "Orientation",
        painRate: 199.46,
        indexation: 0.9973245937,
      },
      {
        name: "Addison",
        orientation: "Neutral",
        painRate: 212.76,
        indexation: 1.063821818,
      },
      {
        name: "Bennington",
        orientation: "Plaintiff ",
        painRate: 185.27,
        indexation: 0.9263460638,
      },
      {
        name: "Caledonia",
        orientation: "Defense ",
        painRate: 164.68,
        indexation: 0.8234098054,
      },
      {
        name: "Chittenden",
        orientation: "Neutral",
        painRate: 231.63,
        indexation: 1.158170022,
      },
      {
        name: "Essex",
        orientation: "Defense ",
        painRate: 146,
        indexation: 0.7299846164,
      },
      {
        name: "Franklin",
        orientation: "Defense ",
        painRate: 204.39,
        indexation: 1.021938332,
      },
      {
        name: "Grand Isle",
        orientation: "Defense ",
        painRate: 204.03,
        indexation: 1.020132433,
      },
      {
        name: "Lamoille",
        orientation: "Neutral",
        painRate: 174.41,
        indexation: 0.8720486924,
      },
      {
        name: "Orange",
        orientation: "Neutral",
        painRate: 195.46,
        indexation: 0.9772858003,
      },
      {
        name: "Orleans",
        orientation: "Defense ",
        painRate: 152.01,
        indexation: 0.7600561835,
      },
      {
        name: "Rutland",
        orientation: "Neutral",
        painRate: 163.98,
        indexation: 0.819878269,
      },
      {
        name: "Washington",
        orientation: "Neutral",
        painRate: 207.16,
        indexation: 1.035810314,
      },
      {
        name: "Windham",
        orientation: "Plaintiff ",
        painRate: 165.81,
        indexation: 0.8290549127,
      },
      {
        name: "Windsor",
        orientation: "Plaintiff ",
        painRate: 198.63,
        indexation: 0.9931643368,
      },
    ],
  },
  VA: {
    name: "VA",
    counties: [
      {
        name: "Virginia ",
        orientation: "Orientation",
        painRate: 229.65,
        indexation: 1.148257642,
      },
      {
        name: "Accomack",
        orientation: "Neutral",
        painRate: 150.78,
        indexation: 0.7538893719,
      },
      {
        name: "Albemarle",
        orientation: "Neutral",
        painRate: 253.43,
        indexation: 1.267166076,
      },
      {
        name: "Alleghany",
        orientation: "Neutral",
        painRate: 140.22,
        indexation: 0.7011169821,
      },
      {
        name: "Amelia",
        orientation: "Neutral",
        painRate: 176.14,
        indexation: 0.8806768778,
      },
      {
        name: "Amherst",
        orientation: "Defense ",
        painRate: 163.92,
        indexation: 0.8196241054,
      },
      {
        name: "Appomattox",
        orientation: "Neutral",
        painRate: 166.88,
        indexation: 0.8343923483,
      },
      {
        name: "Arlington",
        orientation: "Defense",
        painRate: 350.53,
        indexation: 1.752658685,
      },
      {
        name: "Augusta",
        orientation: "Defense ",
        painRate: 207.31,
        indexation: 1.03654605,
      },
      {
        name: "Bath",
        orientation: "Neutral",
        painRate: 159.89,
        indexation: 0.7994515417,
      },
      {
        name: "Bedford",
        orientation: "Defense ",
        painRate: 190.32,
        indexation: 0.9515751455,
      },
      {
        name: "Bland",
        orientation: "Defense ",
        painRate: 149.08,
        indexation: 0.7453949569,
      },
      {
        name: "Botetourt",
        orientation: "Defense ",
        painRate: 217.03,
        indexation: 1.08517156,
      },
      {
        name: "Brunswick",
        orientation: "Neutral",
        painRate: 131.31,
        indexation: 0.6565447127,
      },
      {
        name: "Buchanan",
        orientation: "Neutral",
        painRate: 106.09,
        indexation: 0.5304260585,
      },
      {
        name: "Buckingham",
        orientation: "Neutral",
        painRate: 149.73,
        indexation: 0.7486455755,
      },
      {
        name: "Campbell",
        orientation: "Neutral",
        painRate: 169.93,
        indexation: 0.8496555414,
      },
      {
        name: "Caroline",
        orientation: "Defense",
        painRate: 193.19,
        indexation: 0.9659554545,
      },
      {
        name: "Carroll",
        orientation: "Neutral",
        painRate: 136.74,
        indexation: 0.683713464,
      },
      {
        name: "Charles City",
        orientation: "Plaintiff ",
        painRate: 180.85,
        indexation: 0.9042338305,
      },
      {
        name: "Charlotte",
        orientation: "Defense ",
        painRate: 134.17,
        indexation: 0.6708715136,
      },
      {
        name: "Chesapeake",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Chesterfield",
        orientation: "Defense ",
        painRate: 267.94,
        indexation: 1.339696341,
      },
      {
        name: "Clarke",
        orientation: "Neutral",
        painRate: 286.04,
        indexation: 1.43019196,
      },
      {
        name: "Craig",
        orientation: "Neutral",
        painRate: 167.67,
        indexation: 0.8383653267,
      },
      {
        name: "Culpeper",
        orientation: "Neutral",
        painRate: 252.26,
        indexation: 1.261280182,
      },
      {
        name: "Cumberland",
        orientation: "Neutral",
        painRate: 147.83,
        indexation: 0.7391746371,
      },
      {
        name: "Dickenson",
        orientation: "Neutral",
        painRate: 115.77,
        indexation: 0.578837536,
      },
      {
        name: "Dinwiddie",
        orientation: "Neutral",
        painRate: 194.49,
        indexation: 0.9724433148,
      },
      {
        name: "Essex",
        orientation: "Neutral",
        painRate: 155.75,
        indexation: 0.7787305197,
      },
      {
        name: "Fairfax",
        orientation: "Neutral",
        painRate: 386.95,
        indexation: 1.93474684,
      },
      {
        name: "Fauquier",
        orientation: "Defense ",
        painRate: 321.85,
        indexation: 1.609270283,
      },
      {
        name: "Floyd",
        orientation: "Neutral",
        painRate: 167.38,
        indexation: 0.8368804762,
      },
      {
        name: "Fluvanna",
        orientation: "Neutral",
        painRate: 235.87,
        indexation: 1.179345863,
      },
      {
        name: "Franklin",
        orientation: "Defense ",
        painRate: 182.17,
        indexation: 0.9108287071,
      },
      {
        name: "Frederick",
        orientation: "Defense ",
        painRate: 259.76,
        indexation: 1.298789379,
      },
      {
        name: "Giles",
        orientation: "Neutral",
        painRate: 167.79,
        indexation: 0.8389539161,
      },
      {
        name: "Gloucester",
        orientation: "Neutral",
        painRate: 205.9,
        indexation: 1.029496355,
      },
      {
        name: "Goochland",
        orientation: "Defense ",
        painRate: 303.97,
        indexation: 1.519858203,
      },
      {
        name: "Grayson",
        orientation: "Neutral",
        painRate: 121.5,
        indexation: 0.6075045147,
      },
      {
        name: "Greene",
        orientation: "Defense ",
        painRate: 221.45,
        indexation: 1.107243663,
      },
      {
        name: "Greensville",
        orientation: "Neutral",
        painRate: 127.34,
        indexation: 0.6366931978,
      },
      {
        name: "Halifax",
        orientation: "Neutral",
        painRate: 135.32,
        indexation: 0.6765835061,
      },
      {
        name: "Hanover",
        orientation: "Defense ",
        painRate: 277.28,
        indexation: 1.386382182,
      },
      {
        name: "Henrico",
        orientation: "Defense",
        painRate: 219.88,
        indexation: 1.099418099,
      },
      {
        name: "Henry",
        orientation: "Neutral",
        painRate: 132.08,
        indexation: 0.6604240519,
      },
      {
        name: "Highland",
        orientation: "Neutral",
        painRate: 145.73,
        indexation: 0.7286469132,
      },
      {
        name: "Isle Of Wight",
        orientation: "Neutral",
        painRate: 228.02,
        indexation: 1.140084275,
      },
      {
        name: "James City",
        orientation: "Neutral",
        painRate: 275.75,
        indexation: 1.378770651,
      },
      {
        name: "King And Queen",
        orientation: "Neutral",
        painRate: 166.36,
        indexation: 0.8317838272,
      },
      {
        name: "King George",
        orientation: "Neutral",
        painRate: 267.79,
        indexation: 1.338933851,
      },
      {
        name: "King William",
        orientation: "Neutral",
        painRate: 221.3,
        indexation: 1.106481172,
      },
      {
        name: "Lancaster",
        orientation: "Neutral",
        painRate: 162.82,
        indexation: 0.8140860143,
      },
      {
        name: "Lee",
        orientation: "Neutral",
        painRate: 111.22,
        indexation: 0.5561233362,
      },
      {
        name: "Loudoun",
        orientation: "Defense ",
        painRate: 448.41,
        indexation: 2.242057387,
      },
      {
        name: "Louisa",
        orientation: "Neutral",
        painRate: 194.15,
        indexation: 0.9707578088,
      },
      {
        name: "Lunenburg",
        orientation: "Defense ",
        painRate: 127.51,
        indexation: 0.6375493278,
      },
      {
        name: "Madison",
        orientation: "Defense ",
        painRate: 191.82,
        indexation: 0.9590930373,
      },
      {
        name: "Mathews",
        orientation: "Neutral",
        painRate: 197.52,
        indexation: 0.9875861146,
      },
      {
        name: "Mecklenburg",
        orientation: "Neutral",
        painRate: 139.37,
        indexation: 0.696836332,
      },
      {
        name: "Middlesex",
        orientation: "Neutral",
        painRate: 184.28,
        indexation: 0.9214233162,
      },
      {
        name: "Montgomery",
        orientation: "Neutral",
        painRate: 186.39,
        indexation: 0.9319510401,
      },
      {
        name: "Nelson",
        orientation: "Defense ",
        painRate: 178.21,
        indexation: 0.8910708314,
      },
      {
        name: "News Hampton",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "New Kent",
        orientation: "Neutral",
        painRate: 309.35,
        indexation: 1.546746037,
      },
      {
        name: "Newport",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Northampton",
        orientation: "Neutral",
        painRate: 141.22,
        indexation: 0.7060798609,
      },
      {
        name: "Northumberland",
        orientation: "Neutral",
        painRate: 179.46,
        indexation: 0.8972911511,
      },
      {
        name: "Nottoway",
        orientation: "Neutral",
        painRate: 135.79,
        indexation: 0.6789646178,
      },
      {
        name: "Orange",
        orientation: "Defense ",
        painRate: 217.75,
        indexation: 1.088756605,
      },
      {
        name: "Page",
        orientation: "Neutral",
        painRate: 160.69,
        indexation: 0.8034378971,
      },
      {
        name: "Patrick",
        orientation: "Neutral",
        painRate: 131.95,
        indexation: 0.6597418233,
      },
      {
        name: "Pittsylvania",
        orientation: "Neutral",
        painRate: 132.86,
        indexation: 0.664290014,
      },
      {
        name: "Powhatan",
        orientation: "Defense ",
        painRate: 281.54,
        indexation: 1.407678416,
      },
      {
        name: "Prince Edward",
        orientation: "Neutral",
        painRate: 153.57,
        indexation: 0.7678416159,
      },
      {
        name: "Prince George",
        orientation: "Neutral",
        painRate: 204.86,
        indexation: 1.024292689,
      },
      {
        name: "Prince William",
        orientation: "Neutral",
        painRate: 318.51,
        indexation: 1.592548993,
      },
      {
        name: "Pulaski",
        orientation: "Neutral",
        painRate: 154.45,
        indexation: 0.7722694134,
      },
      {
        name: "Rappahannock",
        orientation: "Neutral",
        painRate: 228.95,
        indexation: 1.144739482,
      },
      {
        name: "Richmond",
        orientation: "Neutral",
        painRate: 161.01,
        indexation: 0.8050297639,
      },
      {
        name: "Roanoke",
        orientation: "Neutral",
        painRate: 206.03,
        indexation: 1.030165206,
      },
      {
        name: "Rockbridge",
        orientation: "Defense ",
        painRate: 172.28,
        indexation: 0.8613871982,
      },
      {
        name: "Rockingham",
        orientation: "Defense",
        painRate: 195.09,
        indexation: 0.975466524,
      },
      {
        name: "Russell",
        orientation: "Neutral",
        painRate: 132.53,
        indexation: 0.6626580162,
      },
      {
        name: "Scott",
        orientation: "Defense ",
        painRate: 117.03,
        indexation: 0.5851514949,
      },
      {
        name: "Shenandoah",
        orientation: "Defense ",
        painRate: 174.67,
        indexation: 0.8733730185,
      },
      {
        name: "Smyth",
        orientation: "Defense ",
        painRate: 121.69,
        indexation: 0.608440907,
      },
      {
        name: "Southampton",
        orientation: "Neutral",
        painRate: 163.86,
        indexation: 0.8192896796,
      },
      {
        name: "Spotsylvania",
        orientation: "Neutral",
        painRate: 257.7,
        indexation: 1.288502441,
      },
      {
        name: "Stafford",
        orientation: "Neutral",
        painRate: 359.72,
        indexation: 1.798622166,
      },
      {
        name: "Suffolk",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Surry",
        orientation: "Neutral",
        painRate: 174.81,
        indexation: 0.8740418701,
      },
      {
        name: "Sussex",
        orientation: "Neutral",
        painRate: 132,
        indexation: 0.660022741,
      },
      {
        name: "Tazewell",
        orientation: "Neutral",
        painRate: 129.38,
        indexation: 0.6469132499,
      },
      {
        name: "Virginia Beach",
        orientation: "Neutral",
        painRate: "#N/A",
        indexation: "#N/A",
      },
      {
        name: "Warren",
        orientation: "Neutral",
        painRate: 213.9,
        indexation: 1.069480302,
      },
      {
        name: "Washington",
        orientation: "Defense ",
        painRate: 165.04,
        indexation: 0.8251755735,
      },
      {
        name: "Westmoreland",
        orientation: "Neutral",
        painRate: 164.66,
        indexation: 0.8232894121,
      },
      {
        name: "Wise",
        orientation: "Neutral",
        painRate: 120.98,
        indexation: 0.6049093706,
      },
      {
        name: "Wythe",
        orientation: "Neutral",
        painRate: 126.43,
        indexation: 0.6321583841,
      },
      {
        name: "York",
        orientation: "Neutral",
        painRate: 297.9,
        indexation: 1.489478965,
      },
      {
        name: "Alexandria city",
        painRate: 294.6,
        indexation: 1.473011839,
      },
      {
        name: "Bristol city",
        painRate: 115.37,
        indexation: 0.5768577353,
      },
      {
        name: "Buena Vista city",
        painRate: 136.53,
        indexation: 0.6826299244,
      },
      {
        name: "Charlottesville city",
        painRate: 181.67,
        indexation: 0.9083405792,
      },
      {
        name: "Chesapeake city",
        painRate: 233.1,
        indexation: 1.165500635,
      },
      {
        name: "Colonial Heights city",
        painRate: 183.15,
        indexation: 0.9157380777,
      },
      {
        name: "Covington city",
        painRate: 124.65,
        indexation: 0.623262658,
      },
      {
        name: "Danville city",
        painRate: 109.15,
        indexation: 0.5457695137,
      },
      {
        name: "Emporia city",
        painRate: 116.37,
        indexation: 0.5818473681,
      },
      {
        name: "Fairfax city",
        painRate: 328.51,
        indexation: 1.642565715,
      },
      {
        name: "Falls Church city",
        painRate: 381.28,
        indexation: 1.90640091,
      },
      {
        name: "Franklin city",
        painRate: 146.06,
        indexation: 0.7303056652,
      },
      {
        name: "Fredericksburg city",
        painRate: 190.53,
        indexation: 0.9526720621,
      },
      {
        name: "Galax city",
        painRate: 119.61,
        indexation: 0.5980335763,
      },
      {
        name: "Hampton city",
        painRate: 171.46,
        indexation: 0.8572804495,
      },
      {
        name: "Harrisonburg city",
        painRate: 149.98,
        indexation: 0.7498762625,
      },
      {
        name: "Hopewell city",
        painRate: 142.06,
        indexation: 0.7102936258,
      },
      {
        name: "Lexington city",
        painRate: 166.9,
        indexation: 0.8344859876,
      },
      {
        name: "Lynchburg city",
        painRate: 135.09,
        indexation: 0.6754598355,
      },
      {
        name: "Manassas city",
        painRate: 261.45,
        indexation: 1.307230286,
      },
      {
        name: "Manassas Park city",
        painRate: 242.97,
        indexation: 1.214848505,
      },
      {
        name: "Martinsville city",
        painRate: 111.03,
        indexation: 0.5551468129,
      },
      {
        name: "Newport News city",
        painRate: 169.49,
        indexation: 0.8474349542,
      },
      {
        name: "Norfolk city",
        painRate: 160.6,
        indexation: 0.8030232091,
      },
      {
        name: "Norton city",
        painRate: 110.49,
        indexation: 0.5524446525,
      },
      {
        name: "Petersburg city",
        painRate: 113.4,
        indexation: 0.5669854859,
      },
      {
        name: "Poquoson city",
        painRate: 293.09,
        indexation: 1.465440439,
      },
      {
        name: "Portsmouth city",
        painRate: 146.73,
        indexation: 0.7336365461,
      },
      {
        name: "Radford city",
        painRate: 128.13,
        indexation: 0.6406527991,
      },
      {
        name: "Richmond city",
        painRate: 157.1,
        indexation: 0.7854859207,
      },
      {
        name: "Roanoke city",
        painRate: 134.91,
        indexation: 0.6745368203,
      },
      {
        name: "Salem city",
        painRate: 170.36,
        indexation: 0.8517958665,
      },
      {
        name: "Staunton city",
        painRate: 165.65,
        indexation: 0.8282656678,
      },
      {
        name: "Suffolk city",
        painRate: 219,
        indexation: 1.095017056,
      },
      {
        name: "Virginia Beach city",
        painRate: 222.24,
        indexation: 1.11117651,
      },
      {
        name: "Waynesboro city",
        painRate: 156.58,
        indexation: 0.7829175306,
      },
      {
        name: "Williamsburg city",
        painRate: 180.7,
        indexation: 0.9035248478,
      },
      {
        name: "Winchester city",
        painRate: 162.01,
        indexation: 0.8100729048,
      },
    ],
  },
  WA: {
    name: "WA",
    counties: [
      {
        name: "Washington ",
        orientation: "Orientation",
        painRate: 244.14,
        indexation: 1.220721022,
      },
      {
        name: "Adams",
        orientation: "Defense ",
        painRate: 166.97,
        indexation: 0.8348605444,
      },
      {
        name: "Asotin",
        orientation: "Defense ",
        painRate: 175.57,
        indexation: 0.8778677012,
      },
      {
        name: "Benton",
        orientation: "Neutral",
        painRate: 227.79,
        indexation: 1.138973982,
      },
      {
        name: "Chelan",
        orientation: "Neutral",
        painRate: 218.57,
        indexation: 1.092863354,
      },
      {
        name: "Clallam",
        orientation: "Neutral",
        painRate: 178.43,
        indexation: 0.8921543709,
      },
      {
        name: "Clark",
        orientation: "Neutral",
        painRate: 243.86,
        indexation: 1.219303057,
      },
      {
        name: "Columbia",
        orientation: "Defense ",
        painRate: 168.38,
        indexation: 0.8418834861,
      },
      {
        name: "Cowlitz",
        orientation: "Defense ",
        painRate: 192.22,
        indexation: 0.961099592,
      },
      {
        name: "Douglas",
        orientation: "Neutral",
        painRate: 224.39,
        indexation: 1.121931643,
      },
      {
        name: "Ferry",
        orientation: "Defense ",
        painRate: 152.67,
        indexation: 0.7633469333,
      },
      {
        name: "Franklin",
        orientation: "Neutral",
        painRate: 208.2,
        indexation: 1.041000602,
      },
      {
        name: "Garfield",
        orientation: "Defense ",
        painRate: 172.33,
        indexation: 0.8616547388,
      },
      {
        name: "Grant",
        orientation: "Neutral",
        painRate: 170.72,
        indexation: 0.8535750117,
      },
      {
        name: "Grays Harbor",
        orientation: "Plaintiff ",
        painRate: 166.57,
        indexation: 0.8328272356,
      },
      {
        name: "Island",
        orientation: "Defense ",
        painRate: 221.3,
        indexation: 1.106507926,
      },
      {
        name: "Jefferson",
        orientation: "Plaintiff ",
        painRate: 185.07,
        indexation: 0.9253695405,
      },
      {
        name: "King",
        orientation: "Plaintiff ",
        painRate: 310.46,
        indexation: 1.552324259,
      },
      {
        name: "Kitsap",
        orientation: "Neutral",
        painRate: 250.94,
        indexation: 1.254685305,
      },
      {
        name: "Kittitas",
        orientation: "Defense",
        painRate: 191.93,
        indexation: 0.9596281185,
      },
      {
        name: "Klickitat",
        orientation: "Defense ",
        painRate: 188.27,
        indexation: 0.9413550933,
      },
      {
        name: "Lewis",
        orientation: "Plaintiff ",
        painRate: 181.8,
        indexation: 0.9090228078,
      },
      {
        name: "Lincoln",
        orientation: "Defense ",
        painRate: 182.78,
        indexation: 0.9138920474,
      },
      {
        name: "Mason",
        orientation: "Plaintiff ",
        painRate: 208.76,
        indexation: 1.043796402,
      },
      {
        name: "Okanogan",
        orientation: "Defense ",
        painRate: 156.94,
        indexation: 0.7847100528,
      },
      {
        name: "Pacific",
        orientation: "Plaintiff ",
        painRate: 148.79,
        indexation: 0.7439636145,
      },
      {
        name: "Pend Oreille",
        orientation: "Defense",
        painRate: 158.74,
        indexation: 0.7936860411,
      },
      {
        name: "Pierce",
        orientation: "Plaintiff ",
        painRate: 248.26,
        indexation: 1.241294897,
      },
      {
        name: "San Juan",
        orientation: "Defense ",
        painRate: 224.8,
        indexation: 1.123991706,
      },
      {
        name: "Skagit",
        orientation: "Neutral",
        painRate: 211.67,
        indexation: 1.058363989,
      },
      {
        name: "Skamania",
        orientation: "Defense ",
        painRate: 219.74,
        indexation: 1.098682362,
      },
      {
        name: "Snohomish",
        orientation: "Plaintiff ",
        painRate: 271.39,
        indexation: 1.356966089,
      },
      {
        name: "Spokane",
        orientation: "Neutral",
        painRate: 184.81,
        indexation: 0.9240719684,
      },
      {
        name: "Stevens",
        orientation: "Defense ",
        painRate: 175.9,
        indexation: 0.879513076,
      },
      {
        name: "Thurston",
        orientation: "Plaintiff ",
        painRate: 236.93,
        indexation: 1.184669922,
      },
      {
        name: "Wahkiakum",
        orientation: "Defense ",
        painRate: 187.54,
        indexation: 0.9377165407,
      },
      {
        name: "Walla Walla",
        orientation: "Defense ",
        painRate: 178.03,
        indexation: 0.8901344392,
      },
      {
        name: "Whatcom",
        orientation: "Neutral",
        painRate: 210.81,
        indexation: 1.054056585,
      },
      {
        name: "Whitman",
        orientation: "Defense",
        painRate: 145.57,
        indexation: 0.7278710454,
      },
      {
        name: "Yakima",
        orientation: "Neutral",
        painRate: 168.71,
        indexation: 0.8435288609,
      },
    ],
  },
  WV: {
    name: "WV",
    counties: [
      {
        name: "West Virginia ",
        orientation: "Orientation",
        painRate: 144.73,
        indexation: 0.7236572804,
      },
      {
        name: "Barbour",
        orientation: "Defense",
        painRate: 123.88,
        indexation: 0.6193833188,
      },
      {
        name: "Berkeley",
        orientation: "Neutral",
        painRate: 189.5,
        indexation: 0.9474817738,
      },
      {
        name: "Boone",
        orientation: "Plaintiff",
        painRate: 124.43,
        indexation: 0.6221389874,
      },
      {
        name: "Braxton",
        orientation: "Defense",
        painRate: 115.92,
        indexation: 0.5796000268,
      },
      {
        name: "Brooke",
        orientation: "Plaintiff",
        painRate: 164.67,
        indexation: 0.8233696743,
      },
      {
        name: "Cabell",
        orientation: "Neutral",
        painRate: 136.49,
        indexation: 0.682456023,
      },
      {
        name: "Calhoun",
        orientation: "Defense ",
        painRate: 116.62,
        indexation: 0.583091432,
      },
      {
        name: "Clay",
        orientation: "Defense ",
        painRate: 113.98,
        indexation: 0.5699150558,
      },
      {
        name: "Doddridge",
        orientation: "Defense ",
        painRate: 127.76,
        indexation: 0.6388201458,
      },
      {
        name: "Fayette",
        orientation: "Neutral",
        painRate: 130.32,
        indexation: 0.651595211,
      },
      {
        name: "Gilmer",
        orientation: "Defense",
        painRate: 120.23,
        indexation: 0.6011370477,
      },
      {
        name: "Grant",
        orientation: "Defense ",
        painRate: 155.57,
        indexation: 0.7778743897,
      },
      {
        name: "Greenbrier",
        orientation: "Neutral",
        painRate: 120.76,
        indexation: 0.603799077,
      },
      {
        name: "Hampshire",
        orientation: "Defense ",
        painRate: 155.89,
        indexation: 0.7794261253,
      },
      {
        name: "Hancock",
        orientation: "Plaintiff ",
        painRate: 155.38,
        indexation: 0.7768978664,
      },
      {
        name: "Hardy",
        orientation: "Defense ",
        painRate: 144.14,
        indexation: 0.7207009565,
      },
      {
        name: "Harrison",
        orientation: "Neutral",
        painRate: 154.54,
        indexation: 0.7727108555,
      },
      {
        name: "Jackson",
        orientation: "Defense ",
        painRate: 146.44,
        indexation: 0.7321918266,
      },
      {
        name: "Jefferson",
        orientation: "Neutral",
        painRate: 233.45,
        indexation: 1.167266404,
      },
      {
        name: "Kanawha",
        orientation: "Plaintiff",
        painRate: 147.58,
        indexation: 0.7378770651,
      },
      {
        name: "Lewis",
        orientation: "Defense ",
        painRate: 142.55,
        indexation: 0.7127683767,
      },
      {
        name: "Lincoln",
        orientation: "Plaintiff ",
        painRate: 116.99,
        indexation: 0.5849374624,
      },
      {
        name: "Logan",
        orientation: "Plaintiff ",
        painRate: 114.01,
        indexation: 0.5700622032,
      },
      {
        name: "McDowell",
        orientation: "Plaintiff ",
        painRate: 77.51,
        indexation: 0.3875593606,
      },
      {
        name: "Marion",
        orientation: "Neutral",
        painRate: 157.46,
        indexation: 0.787305197,
      },
      {
        name: "Marshall",
        orientation: "Plaintiff ",
        painRate: 138.94,
        indexation: 0.694722761,
      },
      {
        name: "Mason",
        orientation: "Defense ",
        painRate: 128.63,
        indexation: 0.64312755,
      },
      {
        name: "Mercer",
        orientation: "Plaintiff ",
        painRate: 117.5,
        indexation: 0.5874790984,
      },
      {
        name: "Mineral",
        orientation: "Defense ",
        painRate: 159.64,
        indexation: 0.7981941007,
      },
      {
        name: "Mingo",
        orientation: "Plaintiff",
        painRate: 95.68,
        indexation: 0.4783760284,
      },
      {
        name: "Monongalia",
        orientation: "Plaintiff ",
        painRate: 158.1,
        indexation: 0.7905023075,
      },
      {
        name: "Monroe",
        orientation: "Defense ",
        painRate: 142.21,
        indexation: 0.7110427396,
      },
      {
        name: "Morgan",
        orientation: "Defense ",
        painRate: 150.26,
        indexation: 0.7512808508,
      },
      {
        name: "Nicholas",
        orientation: "Defense ",
        painRate: 125.09,
        indexation: 0.6254698682,
      },
      {
        name: "Ohio",
        orientation: "Plaintiff ",
        painRate: 161.17,
        indexation: 0.8058457628,
      },
      {
        name: "Pendleton",
        orientation: "Defense ",
        painRate: 140.6,
        indexation: 0.7030031436,
      },
      {
        name: "Pleasants",
        orientation: "Neutral",
        painRate: 150.87,
        indexation: 0.754344191,
      },
      {
        name: "Pocahontas",
        orientation: "Defense ",
        painRate: 119.2,
        indexation: 0.5959868905,
      },
      {
        name: "Preston",
        orientation: "Neutral",
        painRate: 149.73,
        indexation: 0.7486455755,
      },
      {
        name: "Putnam",
        orientation: "Neutral",
        painRate: 214.12,
        indexation: 1.07061735,
      },
      {
        name: "Raleigh",
        orientation: "Neutral",
        painRate: 137.29,
        indexation: 0.6864691325,
      },
      {
        name: "Randolph",
        orientation: "Neutral",
        painRate: 132.06,
        indexation: 0.6603170356,
      },
      {
        name: "Ritchie",
        orientation: "Defense",
        painRate: 139.03,
        indexation: 0.695137449,
      },
      {
        name: "Roane",
        orientation: "Defense ",
        painRate: 114.23,
        indexation: 0.5711591198,
      },
      {
        name: "Summers",
        orientation: "Defense",
        painRate: 108.54,
        indexation: 0.5426927965,
      },
      {
        name: "Taylor",
        orientation: "Defense ",
        painRate: 144.4,
        indexation: 0.7219851515,
      },
      {
        name: "Tucker",
        orientation: "Defense ",
        painRate: 134.96,
        indexation: 0.6747909839,
      },
      {
        name: "Tyler",
        orientation: "Defense ",
        painRate: 154,
        indexation: 0.769981941,
      },
      {
        name: "Upshur",
        orientation: "Defense ",
        painRate: 125.84,
        indexation: 0.629188683,
      },
      {
        name: "Wayne",
        orientation: "Neutral",
        painRate: 140.57,
        indexation: 0.7028292422,
      },
      {
        name: "Webster",
        orientation: "Defense",
        painRate: 108.62,
        indexation: 0.5430807304,
      },
      {
        name: "Wetzel",
        orientation: "Neutral",
        painRate: 129.93,
        indexation: 0.6496287874,
      },
      {
        name: "Wirt",
        orientation: "Defense ",
        painRate: 116.22,
        indexation: 0.5811116313,
      },
      {
        name: "Wood",
        orientation: "Neutral",
        painRate: 154.1,
        indexation: 0.7705036452,
      },
      {
        name: "Wyoming",
        orientation: "Plaintiff ",
        painRate: 106.73,
        indexation: 0.5336365461,
      },
    ],
  },
  WI: {
    name: "WI",
    counties: [
      {
        name: "Wisconsin ",
        orientation: "Orientation",
        painRate: 190.09,
        indexation: 0.9504380978,
      },
      {
        name: "Adams",
        orientation: "Defense ",
        painRate: 146.3,
        indexation: 0.731482844,
      },
      {
        name: "Ashland",
        orientation: "Defense ",
        painRate: 152.09,
        indexation: 0.7604574945,
      },
      {
        name: "Barron",
        orientation: "Defense ",
        painRate: 166.8,
        indexation: 0.8339776604,
      },
      {
        name: "Bayfield",
        orientation: "Defense ",
        painRate: 170.37,
        indexation: 0.8518493746,
      },
      {
        name: "Brown",
        orientation: "Neutral",
        painRate: 193.69,
        indexation: 0.9684703364,
      },
      {
        name: "Buffalo",
        orientation: "Defense ",
        painRate: 171.14,
        indexation: 0.8557153368,
      },
      {
        name: "Burnett",
        orientation: "Neutral",
        painRate: 153.67,
        indexation: 0.7683499431,
      },
      {
        name: "Calumet",
        orientation: "Defense ",
        painRate: 228.99,
        indexation: 1.144926761,
      },
      {
        name: "Chippewa",
        orientation: "Neutral",
        painRate: 199.12,
        indexation: 0.9955855796,
      },
      {
        name: "Clark",
        orientation: "Defense ",
        painRate: 170.27,
        indexation: 0.8513276704,
      },
      {
        name: "Columbia",
        orientation: "Defense ",
        painRate: 210.73,
        indexation: 1.053668651,
      },
      {
        name: "Crawford",
        orientation: "Defense ",
        painRate: 170.86,
        indexation: 0.8542839944,
      },
      {
        name: "Dane",
        orientation: "Plaintiff ",
        painRate: 227.92,
        indexation: 1.139575948,
      },
      {
        name: "Dodge",
        orientation: "Defense",
        painRate: 186.42,
        indexation: 0.9321249415,
      },
      {
        name: "Door",
        orientation: "Defense ",
        painRate: 193.42,
        indexation: 0.9671058792,
      },
      {
        name: "Douglas",
        orientation: "Neutral",
        painRate: 177.06,
        indexation: 0.8852919537,
      },
      {
        name: "Dunn",
        orientation: "Defense ",
        painRate: 191.11,
        indexation: 0.9555748779,
      },
      {
        name: "Eau Claire",
        orientation: "Defense ",
        painRate: 188.79,
        indexation: 0.9439368604,
      },
      {
        name: "Florence",
        orientation: "Defense ",
        painRate: 172.6,
        indexation: 0.8629790649,
      },
      {
        name: "Fond Du Lac",
        orientation: "Neutral",
        painRate: 190.5,
        indexation: 0.9525249147,
      },
      {
        name: "Forest",
        orientation: "Defense ",
        painRate: 150.14,
        indexation: 0.7506922614,
      },
      {
        name: "Grant",
        orientation: "Defense ",
        painRate: 169.21,
        indexation: 0.8460571199,
      },
      {
        name: "Green",
        orientation: "Defense ",
        painRate: 212.26,
        indexation: 1.061293559,
      },
      {
        name: "Green Lake",
        orientation: "Defense ",
        painRate: 176.76,
        indexation: 0.8838204802,
      },
      {
        name: "Iowa",
        orientation: "Defense ",
        painRate: 204.41,
        indexation: 1.022058725,
      },
      {
        name: "Iron",
        orientation: "Defense ",
        painRate: 162.38,
        indexation: 0.8119055582,
      },
      {
        name: "Jackson",
        orientation: "Defense ",
        painRate: 172.15,
        indexation: 0.8607317236,
      },
      {
        name: "Jefferson",
        orientation: "Defense ",
        painRate: 210.63,
        indexation: 1.053146947,
      },
      {
        name: "Juneau",
        orientation: "Defense ",
        painRate: 165.62,
        indexation: 0.8280917664,
      },
      {
        name: "Kenosha",
        orientation: "Neutral",
        painRate: 200.83,
        indexation: 1.004173634,
      },
      {
        name: "Kewaunee",
        orientation: "Defense ",
        painRate: 197.22,
        indexation: 0.9861146412,
      },
      {
        name: "La Crosse",
        orientation: "Neutral",
        painRate: 187.48,
        indexation: 0.9373954919,
      },
      {
        name: "Lafayette",
        orientation: "Defense ",
        painRate: 180.08,
        indexation: 0.9004213765,
      },
      {
        name: "Langlade",
        orientation: "Defense ",
        painRate: 154.49,
        indexation: 0.7724566919,
      },
      {
        name: "Lincoln",
        orientation: "Defense ",
        painRate: 159.55,
        indexation: 0.7977660357,
      },
      {
        name: "Manitowoc",
        orientation: "Neutral",
        painRate: 168.31,
        indexation: 0.8415490603,
      },
      {
        name: "Marathon",
        orientation: "Neutral",
        painRate: 189.83,
        indexation: 0.9491672798,
      },
      {
        name: "Marinette",
        orientation: "Defense ",
        painRate: 156.66,
        indexation: 0.7832787105,
      },
      {
        name: "Marquette",
        orientation: "Defense ",
        painRate: 155.7,
        indexation: 0.7785031102,
      },
      {
        name: "Menominee",
        orientation: "Neutral",
        painRate: 138.11,
        indexation: 0.6905625042,
      },
      {
        name: "Milwaukee",
        orientation: "Plaintiff ",
        painRate: 156.18,
        indexation: 0.7808842218,
      },
      {
        name: "Monroe",
        orientation: "Defense ",
        painRate: 174.52,
        indexation: 0.8726105277,
      },
      {
        name: "Oconto",
        orientation: "Defense ",
        painRate: 182.27,
        indexation: 0.9113370343,
      },
      {
        name: "Oneida",
        orientation: "Defense ",
        painRate: 166.52,
        indexation: 0.8326132031,
      },
      {
        name: "Outagamie",
        orientation: "Defense ",
        painRate: 210.84,
        indexation: 1.054217109,
      },
      {
        name: "Ozaukee",
        orientation: "Defense ",
        painRate: 261.6,
        indexation: 1.307979399,
      },
      {
        name: "Pepin",
        orientation: "Defense ",
        painRate: 186.03,
        indexation: 0.9301718949,
      },
      {
        name: "Pierce",
        orientation: "Defense ",
        painRate: 228.77,
        indexation: 1.143843221,
      },
      {
        name: "Polk",
        orientation: "Neutral",
        painRate: 197.93,
        indexation: 0.9896595545,
      },
      {
        name: "Portage",
        orientation: "Defense ",
        painRate: 190.92,
        indexation: 0.9545849776,
      },
      {
        name: "Price",
        orientation: "Defense",
        painRate: 144.16,
        indexation: 0.7207945957,
      },
      {
        name: "Racine",
        orientation: "Neutral",
        painRate: 193.51,
        indexation: 0.9675740753,
      },
      {
        name: "Richland",
        orientation: "Defense",
        painRate: 179.48,
        indexation: 0.8974115444,
      },
      {
        name: "Rock",
        orientation: "Neutral",
        painRate: 180.4,
        indexation: 0.9020132433,
      },
      {
        name: "Rusk",
        orientation: "Defense ",
        painRate: 150.92,
        indexation: 0.7545983546,
      },
      {
        name: "St. Croix",
        orientation: "Neutral",
        painRate: 261.09,
        indexation: 1.30545114,
      },
      {
        name: "Sauk",
        orientation: "Defense ",
        painRate: 188.57,
        indexation: 0.9428666979,
      },
      {
        name: "Sawyer",
        orientation: "Defense ",
        painRate: 160.62,
        indexation: 0.8030767173,
      },
      {
        name: "Shawano",
        orientation: "Defense ",
        painRate: 178.54,
        indexation: 0.8926894522,
      },
      {
        name: "Sheboygan",
        orientation: "Defense ",
        painRate: 173.92,
        indexation: 0.8696140726,
      },
      {
        name: "Taylor",
        orientation: "Defense ",
        painRate: 180.71,
        indexation: 0.9035382249,
      },
      {
        name: "Trempealeau",
        orientation: "Defense ",
        painRate: 182.42,
        indexation: 0.9120995251,
      },
      {
        name: "Vernon",
        orientation: "Defense ",
        painRate: 181.42,
        indexation: 0.9071232693,
      },
      {
        name: "Vilas",
        orientation: "Defense ",
        painRate: 169.5,
        indexation: 0.8475152164,
      },
      {
        name: "Walworth",
        orientation: "Neutral",
        painRate: 197.24,
        indexation: 0.9861949034,
      },
      {
        name: "Washburn",
        orientation: "Defense",
        painRate: 158.44,
        indexation: 0.7922011906,
      },
      {
        name: "Washington",
        orientation: "Defense ",
        painRate: 241.62,
        indexation: 1.208119858,
      },
      {
        name: "Waukesha",
        orientation: "Defense ",
        painRate: 266.27,
        indexation: 1.331349074,
      },
      {
        name: "Waupaca",
        orientation: "Defense ",
        painRate: 176.47,
        indexation: 0.8823490068,
      },
      {
        name: "Waushara",
        orientation: "Defense ",
        painRate: 149.77,
        indexation: 0.7488729851,
      },
      {
        name: "Winnebago",
        orientation: "Defense ",
        painRate: 186.98,
        indexation: 0.93488061,
      },
      {
        name: "Wood",
        orientation: "Defense ",
        painRate: 172.32,
        indexation: 0.8615878537,
      },
    ],
  },
  WY: {
    name: "WY",
    counties: [
      {
        name: "Wyoming ",
        orientation: "Orientation",
        painRate: 190.69,
        indexation: 0.9534479299,
      },
      {
        name: "Albany",
        orientation: "Neutral",
        painRate: 143.23,
        indexation: 0.7161661427,
      },
      {
        name: "Big Horn",
        orientation: "Defense",
        painRate: 159.73,
        indexation: 0.7986355428,
      },
      {
        name: "Campbell",
        orientation: "Neutral",
        painRate: 240.44,
        indexation: 1.202193833,
      },
      {
        name: "Carbon",
        orientation: "Defense ",
        painRate: 177.18,
        indexation: 0.8858939201,
      },
      {
        name: "Converse",
        orientation: "Defense ",
        painRate: 208.72,
        indexation: 1.043609123,
      },
      {
        name: "Crook",
        orientation: "Defense ",
        painRate: 187.8,
        indexation: 0.9390141128,
      },
      {
        name: "Fremont",
        orientation: "Neutral",
        painRate: 147.48,
        indexation: 0.737408869,
      },
      {
        name: "Goshen",
        orientation: "Defense ",
        painRate: 157.1,
        indexation: 0.7854992977,
      },
      {
        name: "Hot Springs",
        orientation: "Defense ",
        painRate: 173.19,
        indexation: 0.865948766,
      },
      {
        name: "Johnson",
        orientation: "Defense ",
        painRate: 187.46,
        indexation: 0.9372884757,
      },
      {
        name: "Laramie",
        orientation: "Neutral",
        painRate: 192.51,
        indexation: 0.9625576884,
      },
      {
        name: "Lincoln",
        orientation: "Defense ",
        painRate: 248.93,
        indexation: 1.244652532,
      },
      {
        name: "Natrona",
        orientation: "Defense ",
        painRate: 187.66,
        indexation: 0.9382917531,
      },
      {
        name: "Niobrara",
        orientation: "Defense ",
        painRate: 146,
        indexation: 0.7300247475,
      },
      {
        name: "Park",
        orientation: "Defense ",
        painRate: 186.75,
        indexation: 0.9337435623,
      },
      {
        name: "Platte",
        orientation: "Defense ",
        painRate: 163.69,
        indexation: 0.8184736807,
      },
      {
        name: "Sheridan",
        orientation: "Defense ",
        painRate: 187.88,
        indexation: 0.9393886697,
      },
      {
        name: "Sublette",
        orientation: "Defense ",
        painRate: 229.15,
        indexation: 1.145729383,
      },
      {
        name: "Sweetwater",
        orientation: "Neutral",
        painRate: 202.74,
        indexation: 1.01369808,
      },
      {
        name: "Teton",
        orientation: "Neutral",
        painRate: 341.59,
        indexation: 1.707939268,
      },
      {
        name: "Uinta",
        orientation: "Defense ",
        painRate: 195.5,
        indexation: 0.9774864558,
      },
      {
        name: "Washakie",
        orientation: "Defense ",
        painRate: 162.39,
        indexation: 0.8119724433,
      },
      {
        name: "Weston",
        orientation: "Defense",
        painRate: 181.06,
        indexation: 0.9053173701,
      },
    ],
  },
};
const getAllStates = () => {
  //return an array {label: 'state name', value: 'state name'}

  return Object.keys(stateCountyData).map((key) => {
    return {
      label: stateCountyData[key].name,
      value: stateCountyData[key].name,
    };
  });
};
const getAllCountiesByState = (state) => {
  //return an array {label: 'county name', value: 'county name'}
  return Object.keys(stateCountyData[state].counties)?.map((key) => {
    return {
      label: stateCountyData[state].counties[key].name,
      value: stateCountyData[state].counties[key].name,
    };
  });
};

const getCountyOrientation = (state, county) => {
  // console.log("state", state, "county", county);

  return stateCountyData[state]?.counties?.find(
    (c) => c.name.trim().toLowerCase() === county.trim().toLowerCase()
  )?.orientation || "Neutral";
};

// You would need to dynamically generate the County list based on the state searched.

module.exports = {
  Types,
  sortOptions,
  nuclearVerdicts,
  CountyOrientation,
  Resolution,
  Winner,
  states,
  stateCountyData,
  getAllStates,
  getAllCountiesByState,
  getCountyOrientation
}